import { useEffect, useState } from "react";

interface useSearchDebounceInterface {
  delay: number;
  dtext?: string;
}

export const useSearchDebounce = ({
  delay = 350,
  dtext = "",
}: useSearchDebounceInterface) => {
  const [search, setSearch] = useState<string>(dtext);
  const [searchQuery, setSearchQuery] = useState<string>(dtext);

  useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchQuery), delay);
    return () => clearTimeout(delayFn);
  }, [searchQuery, delay]);

  return [search, setSearchQuery] as const;
};
