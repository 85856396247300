import React from "react";
import { useMediaQuery } from "../../hooks";

import Desktop from "./Desktop";
import Mobile from "./Mobile";

type Props = {};

const Auth = (props: Props) => {
  const isMobile = useMediaQuery("(max-width: 1023px)");

  if (isMobile) {
    return <Mobile />;
  } else {
    return <Desktop />;
  }
};

export default Auth;
