import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import * as Yup from "yup";
import { useFormik } from "formik";

import { IFormValues } from "./types";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "src/api";
import { Step1, Step2 } from "./partials";
import { useAuthStore } from "src/store";
import { ToastMsg } from "src/shared";

type Props = {};

const initialValues: IFormValues = {
  email: undefined,
  password: undefined,
  phone_number: undefined,
  country_code: undefined,
  otp: "",
};

const RegisterForm = (props: Props) => {
  const { t } = useTranslation("auth/ts");
  const { t: validationTranslations } = useTranslation("validation/ts");

  const { loginUser } = useAuthStore();

  const [step, setStep] = useState<number>(1);

  const validationSchema1 = Yup.object({
    email: Yup.string()
      .email(validationTranslations("email"))
      .required(validationTranslations("field_required")),
    password: Yup.string()
      .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?!.* ).{8,128}$/)
      .required(validationTranslations("field_required")),
    phone_number: Yup.string()
      .min(10, validationTranslations("phone"))
      .max(10, validationTranslations("phone"))
      .required(validationTranslations("field_required")),
  });

  const validationSchema2 = Yup.object({
    otp: Yup.string()
      .length(5)
      .required(validationTranslations("field_required")),
  });

  const signupMutuate = useMutation(AuthApi.register, {
    onSuccess: (res: AxiosResponse) => {
      setStep(2);
    },
    onError: (error: AxiosError) => {
      if (error.response.status == 400) {
        toast(<ToastMsg type="error" message={t("alert_user_exist_error")} />);
      }
    },
    retry: false
  });

  const checkOtpMutuate = useMutation(AuthApi.checkOtp, {
    onSuccess: (res) => {
      toast(<ToastMsg type="success" message={t("welcome")} />);
      loginUser(res.access_token, res.refresh_token);
    },
    onError: (error: AxiosError) => {
      formik.setFieldValue("otp_error", true);
    },
    retry: false
  });

  const resnedOtp = async () => {
    try {
      await signupMutuate.mutateAsync({
        body: {
          ...formik.values,
          phone_number: formik.values.country_code + formik.values.phone_number,
        },
      });
      toast(<ToastMsg type="success" message={t("alert_otp_sent_success")} />);
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues,
    validationSchema: step == 1 ? validationSchema1 : validationSchema2,
    onSubmit: (values) =>
      step == 1
        ? signupMutuate.mutate({
            body: {
              ...values,
              phone_number: values.country_code + values.phone_number,
            },
          })
        : checkOtpMutuate.mutate({
            body: {
              ...values,
              phone_number: values.country_code + values.phone_number,
            },
          }),
    validateOnChange: true,
    validateOnMount: true,
  });

  useEffect(() => {
    formik.validateForm()
  }, [formik.values.phone_number]);

  if (step == 1) {
    return (
      <Step1
        onFinish={() => setStep(2)}
        formik={formik}
        loading={signupMutuate.isLoading}
      />
    );
  } else if (step == 2) {
    return (
      <Step2
        checkOtpLoading={checkOtpMutuate.isLoading}
        resendLoading={signupMutuate.isLoading}
        backStep={() => setStep(1)}
        formik={formik}
        resendOtp={resnedOtp}
        step={step}
      />
    );
  } else {
    return <></>;
  }
};

export default RegisterForm;
