import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Buy, Plus } from "react-iconly";
import { Button, CheckBox, Input, Modal } from "src/components";
import { Step1, Step2 } from "./partials";

import * as Yup from "yup";
import { useFormik } from "formik";

import { IFormValues } from "./types";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "src/api";
import { useAuthStore } from "src/store";
import { ToastMsg } from "src/shared";

const initialValues: IFormValues = {
  phone_number: undefined,
  otp: "",
  country_code: undefined,
};

type Props = {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
};

const ChangePhoneModal = (props: Props) => {
  const { t: validationTranslations } = useTranslation("validation/ts");
  const { t, i18n } = useTranslation("profile/ts");

  const { user, setUser } = useAuthStore();

  const [step, setStep] = useState<number>(1);

  const validationSchema1 = Yup.object({
    phone_number: Yup.string()
      .min(10, validationTranslations("phone"))
      .max(10, validationTranslations("phone"))
      .required(validationTranslations("field_required")),
  });

  const validationSchema2 = Yup.object({
    otp: Yup.string()
      .length(5)
      .required(validationTranslations("field_required")),
  });

  const updatePhoneMutuate = useMutation(AuthApi.updatePhoneNumber, {
    onSuccess: (res: AxiosResponse) => {
      setStep(2);
    },
    onError: (error: AxiosError) => {
      if (error.response.status == 400) {
        toast(
          <ToastMsg type="error" message={t("alert_phone_number_exists")} />
        );
      }
    },
  });

  const checkOtpMutuate = useMutation(AuthApi.checkOtp, {
    onSuccess: (res) => {
      toast(
        <ToastMsg
          type="success"
          message={validationTranslations("data_store_success")}
        />
      );
      setUser({ ...user, phone_number: formik.values.phone_number });
      props.setIsOpen(false);
    },
    onError: (error: AxiosError) => {
      formik.setFieldValue("otp_error", true);
    },
  });

  const resnedOtp = async () => {
    try {
      await updatePhoneMutuate.mutateAsync({
        body: {
          ...formik.values,
          new_phone_number:
            formik.values.country_code + formik.values.phone_number,
        },
      });
      toast(
        <ToastMsg
          type="success"
          message={validationTranslations("alert_otp_sent_success")}
        />
      );
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues,
    validationSchema: step == 1 ? validationSchema1 : validationSchema2,
    onSubmit: (values) =>
      step == 1
        ? updatePhoneMutuate.mutate({
            body: {
              ...values,
              new_phone_number: values.country_code + values.phone_number,
            },
          })
        : checkOtpMutuate.mutate({
            body: {
              ...values,
              phone_number: user.phone_number,
            },
          }),
    validateOnChange: true,
    validateOnMount: step == 2,
  });

  useEffect(() => {
    formik.validateForm()
  }, [formik.values.phone_number]);

  return (
    <Modal
      title={t("edit_mobile_number")}
      isOpen={props.isOpen}
      onClose={() => props.setIsOpen(false)}
    >
      <div className="w-full h-full flex flex-col justify-between">
        {step == 1 ? (
          <Step1 formik={formik} />
        ) : (
          <Step2
            checkOtpLoading={checkOtpMutuate.isLoading}
            resendLoading={updatePhoneMutuate.isLoading}
            backStep={() => setStep(1)}
            formik={formik}
            resendOtp={resnedOtp}
            step={step}
          />
        )}
        <div className="flex items-center">
          <div className="flex-1 gap-3 flex items-center">
            <Button
              addonClasses="w-full"
              icon={<Plus size={24} />}
              onClick={() => formik.submitForm()}
              loading={
                checkOtpMutuate.isLoading || updatePhoneMutuate.isLoading
              }
            >
              {step == 1 ? t("next_step") : t("verify_account")}
            </Button>
            <a
              href="#"
              className={`flex justify-center items-center p-[8px] border border-primary-300 border-dashed rounded-[100px]`}
            >
              <span className="text-[10px] text-grey-600">
                <strong className="text-sm text-grey-800">{step}</strong>/2
              </span>
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePhoneModal;
