import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import { Root, Edit } from "./screens";

const Profile = () => {
  return (
    <Routes>
      <Route path="/" element={<Root />} />
      <Route path="/edit" element={<Edit />} />
    </Routes>
  );
};

export default Profile;
