import React from "react";
import { TimeCircle } from "react-iconly";
import { ColorPalette } from "src/utils";

type Props = {
  type: "me" | "support";
};

const MessageCard = (props: Props) => {
  return (
    <div
      className={`mb-5 p-[14px] ${
        props.type == "me" ? "bg-grey-100" : "bg-primary-50"
      }`}
      style={{
        borderRadius:
          props.type == "me" ? "12px 0px 12px 12px" : "0px 12px 12px 12px",
      }}
    >
      <p className="mb-2 text-sm text-grey-800">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </p>
      <div className="flex items-center gap-1">
        <TimeCircle size={16} primaryColor={ColorPalette.grey["600"]} />
        <span className="text-grey-600 text-xs">9:00</span>
      </div>
    </div>
  );
};

export default MessageCard;
