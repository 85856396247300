import { createColumnHelper } from "@tanstack/react-table";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Paper, Show } from "react-iconly";
import { UsaFlag } from "src/assets";
import { Table } from "src/components";

import { IPaymentsInterface } from "./types";
import { useQuery } from "@tanstack/react-query";
import { OrderApi, PaymentApi } from "src/api";
import { Link, useParams } from "react-router-dom";
import { ColorPalette, Helpers } from "src/utils";
import { DetailModal } from "src/pages/Payments/screens/Root/partials";

type Props = {};

enum PAYMENT_STATUS {
  SUCCESSFUL = "SUCCESSFUL",
  FAILED = "FAILED",
  PENDING = "PENDING",
}

const PaymentList = (props: Props) => {
  const { t, i18n } = useTranslation("payments/ts");

  const { id } = useParams();

  const [detailModal, setDetailModal] = useState<Helpers.keyable>({
    show: false,
    data: undefined,
    stamp: new Date(),
  });

  const getPaymentStatusT = (status: string) => {
    switch (status) {
      case PAYMENT_STATUS.SUCCESSFUL:
        return t("successful");
      case PAYMENT_STATUS.FAILED:
        return t("failed");
      case PAYMENT_STATUS.PENDING:
        return t("pending");

      default:
        break;
    }
  };

  const getPaymentStatusColor = (status: string) => {
    switch (status) {
      case PAYMENT_STATUS.SUCCESSFUL:
        return "success";
      case PAYMENT_STATUS.FAILED:
        return "error";
      case PAYMENT_STATUS.PENDING:
        return "primary";

      default:
        break;
    }
  };

  const columnHelper = createColumnHelper<IPaymentsInterface>();
  const columns = [
    columnHelper.accessor("index", {
      header: () => <span>#</span>,
      cell: ({ row }) => row.index + 1,
      enableSorting: false,
      size: 60,
    }),
    columnHelper.accessor("id", {
      header: () => <span>{t("payment_number")}</span>,
      cell: (row) => row.getValue(),
      enableSorting: false,
      meta: {
        mobile_header: t("payment_number"),
      },
    }),
    columnHelper.accessor("order", {
      header: () => <span>{t("order_id")}</span>,
      cell: (row) => (row.getValue() ? row.getValue().join(" | ") : ""),
      enableSorting: false,
      meta: {
        mobile_header: t("order_id"),
      },
    }),
    columnHelper.accessor("internal_tracking_code", {
      header: () => <span>{t("tracking_code")}</span>,
      cell: (row) => row.getValue(),
      enableSorting: false,
      meta: {
        mobile_header: t("tracking_code"),
      },
    }),
    columnHelper.accessor("type", {
      header: () => <span>{t("type")}</span>,
      cell: (row) => row.getValue(),
      enableSorting: false,
      meta: {
        mobile_header: t("type"),
      },
    }),
    columnHelper.accessor("amount", {
      header: () => <span>{t("total_price")}</span>,
      cell: (row) => row.getValue().toLocaleString(),
      enableSorting: true,
      sortingFn: () => 1,
      meta: {
        mobile_header: t("total_price"),
      },
    }),
    columnHelper.accessor("created_date", {
      header: () => <span>{t("date")}</span>,
      cell: (row) => row.getValue(),
      sortingFn: () => 1,
      meta: {
        mobile_header: t("created_date"),
      },
    }),
    columnHelper.accessor("status", {
      header: () => (
        <div className="w-full flex items-center justify-start">
          <span>{t("status")}</span>
        </div>
      ),
      meta: {
        mobile_header: t("status"),
      },
      cell: (row) => (
        <div className="flex items-center justify-between gap-6">
          <div
            className={`px-3 py-[5px] bg-${getPaymentStatusColor(
              row.getValue()
            )}-50 rounded-lg text-${getPaymentStatusColor(
              row.getValue()
            )}-500 text-xs text-center`}
          >
            {getPaymentStatusT(row.getValue())}
          </div>
          <div
            className="cursor-pointer"
            onClick={() =>
              setDetailModal({
                show: true,
                stamp: new Date(),
                data: row.row.original,
              })
            }
          >
            <Show primaryColor={ColorPalette.grey["700"]} size={24} />
          </div>
        </div>
      ),
      enableSorting: false,
      size: 200,
    }),
  ];

  const { data, isFetching: isQueryLoading } = useQuery({
    queryKey: ["[List] Payment"],
    queryFn: () =>
      PaymentApi.getOfflinePaymentList({
        params: {
          page: 1,
          page_size: 100,
          payment_id: id,
        },
      }).then((result) => {
        return result.results;
      }),
    cacheTime: 0,
    initialData: [],
  });

  return (
    <>
      <DetailModal
        isOpen={detailModal.show}
        setIsOpen={(state) =>
          setDetailModal({ ...detailModal, show: state, stamp: new Date() })
        }
        data={detailModal.data}
      />
      <Table
        data={data || []}
        columns={columns}
        title={t("payment_list")}
        Icon={Paper}
        headerColor={"grey-100"}
        bordered={false}
        loading={isQueryLoading}
      />
    </>
  );
};

export default PaymentList;
