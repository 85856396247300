import React, { useEffect, useState } from "react";
import { Step1, Step2 } from "./partials";

import * as Yup from "yup";
import { useFormik } from "formik";

import { IFormValues } from "./types";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "src/api";
import { useAuthStore } from "src/store";
import { ToastMsg } from "src/shared";

type Props = {};

const initialValues: IFormValues = {
  phone_number: undefined,
  otp: "",
  country_code: undefined,
};

const LoginOtpForm = (props: Props) => {
  const { t: validationTranslations } = useTranslation("validation/ts");
  const { t, i18n } = useTranslation("auth/ts");

  const { loginUser } = useAuthStore();

  const [step, setStep] = useState<number>(1);

  const validationSchema1 = Yup.object({
    phone_number: Yup.string()
      .min(10, validationTranslations("phone"))
      .max(10, validationTranslations("phone"))
      .required(validationTranslations("field_required")),
  });

  const validationSchema2 = Yup.object({
    otp: Yup.string()
      .length(5)
      .required(validationTranslations("field_required")),
  });

  const loginMututate = useMutation(AuthApi.loginOtp, {
    onSuccess: (res: AxiosResponse) => {
      setStep(2);
    },
    onError: (error: AxiosError) => {
      if (error.response.status == 400) {
        toast(
          <ToastMsg type="error" message={t("alert_phone_number_wrong")} />
        );
      }
    },
    retry: false,
  });

  const checkOtpMutuate = useMutation(AuthApi.checkOtp, {
    onSuccess: (res) => {
      toast(<ToastMsg type="success" message={t("welcome")} />);
      loginUser(res.access_token, res.refresh_token);
    },
    onError: (error: AxiosError) => {
      formik.setFieldValue("otp_error", true);
    },
    retry: false,
  });

  const resnedOtp = async () => {
    try {
      await loginMututate.mutateAsync({ body: formik.values });
      toast(<ToastMsg type="success" message={t("alert_otp_sent_success")} />);
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues,
    validationSchema: step == 1 ? validationSchema1 : validationSchema2,
    onSubmit: (values) =>
      step == 1
        ? loginMututate.mutate({
            body: {
              ...values,
              phone_number: values.country_code + values.phone_number,
            },
          })
        : checkOtpMutuate.mutate({
            body: {
              ...values,
              phone_number: values.country_code + values.phone_number,
            },
          }),
    validateOnChange: true,
    validateOnMount: true,
  });

  useEffect(() => {
    step == 2 && formik.validateField("otp");
    step == 1 && formik.validateForm();
  }, [step]);

  useEffect(() => {
    formik.validateForm();
  }, [formik.values.phone_number]);

  if (step == 1) {
    return (
      <Step1
        onFinish={() => setStep(2)}
        formik={formik}
        loading={loginMututate.isLoading}
      />
    );
  } else if (step == 2) {
    return (
      <Step2
        checkOtpLoading={checkOtpMutuate.isLoading}
        resendLoading={loginMututate.isLoading}
        backStep={() => setStep(1)}
        formik={formik}
        resendOtp={resnedOtp}
        step={step}
      />
    );
  } else {
    return <></>;
  }
};

export default LoginOtpForm;
