import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ArrowLeft,
  ArrowRight,
  ChevronLeft,
  ChevronRight,
  Edit,
  Home,
  Lock,
} from "react-iconly";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MoneySvg } from "src/assets";
import { Button, IconButton } from "src/components";
import { useAuthStore } from "src/store";
import { ColorPalette } from "src/utils";

type Props = {
  payment_id: string;
};

const Header = ({ payment_id }: Props) => {
  const { i18n, t } = useTranslation("paymentids/ts");
  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-wrap justify-between items-center mb-[30px] gap-5">
      <div className="rounded-lg border border-grey-200 p-3 flex items-center gap-2">
        <Link to="/payments" className="flex gap-2">
          <img src={MoneySvg} />
          <span className="text-sm text-grey-600">{t("financial")}</span>
        </Link>
        {i18n.dir() == "rtl" ? (
          <ChevronLeft size={24} primaryColor={ColorPalette.grey["600"]} />
        ) : (
          <ChevronRight size={24} primaryColor={ColorPalette.grey["600"]} />
        )}
        <span className="text-sm text-grey-600">{t("payment_id")}</span>
        {i18n.dir() == "rtl" ? (
          <ChevronLeft size={24} primaryColor={ColorPalette.grey["600"]} />
        ) : (
          <ChevronRight size={24} primaryColor={ColorPalette.grey["600"]} />
        )}
        <span className="text-sm text-grey-800 font-semibold">
          {payment_id}
        </span>
      </div>
      <div className="flex items-center gap-3">
        <IconButton
          bgColor="bg-primary-500"
          bgColorHovered="hover:bg-primary-700"
          ringColor="ring:bg-primary-200"
          icon={<Edit size={24} primaryColor={ColorPalette.grey["50"]} />}
          size={"Large"}
          onClick={() => navigate("/payment-ids/edit")}
        />
      </div>
    </div>
  );
};

export default Header;
