import React, { useState } from "react";
import { Header, PaymentIdInfo, Reports } from "./partials";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ColorPalette } from "src/utils";
import { Chart, InfoCircle } from "react-iconly";
import { useTranslation } from "react-i18next";
import { Minus } from "iconsax-react";
import { useQuery } from "@tanstack/react-query";
import { WalletApi } from "src/api";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

type Props = {};

const Single = (props: Props) => {
  const { t } = useTranslation("paymentids/ts");

  const [tabIndex, setTabIndex] = useState<number>(0);

  const { id } = useParams();

  const { data, isLoading, status, refetch } = useQuery({
    queryKey: [`[Single-${id}] Account`],
    queryFn: () =>
      WalletApi.getPaymentIds({
        params: { payment_id: id },
      }).then((result) => {
        return result.results[0];
      }),
  });

  return (
    <div className="w-full">
      {data ? (
        <>
          <Header {...data} />
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <TabList
              className="border-0 p-3 rounded-2xl bg-primary-50 flex items-center outline:none"
              style={{ width: "fit-content" }}
            >
              <Tab
                style={{
                  background:
                    tabIndex == 0 ? ColorPalette.primary["100"] : "transparent",
                }}
                className="after:d-none cursor-pointer py-2 px-5 flex items-center gap-2 !rounded-lg outline:none"
              >
                <InfoCircle
                  filled={tabIndex == 0}
                  size={20}
                  primaryColor={
                    tabIndex == 0
                      ? ColorPalette.primary["500"]
                      : ColorPalette.grey["700"]
                  }
                />
                <span
                  className={`text-sm ${
                    tabIndex == 0
                      ? "text-primary-500 font-bold"
                      : "text-grey-700"
                  }`}
                >
                  {t("payment_id_info")}
                </span>
              </Tab>
              <Tab
                style={{
                  background:
                    tabIndex == 1 ? ColorPalette.primary["100"] : "transparent",
                }}
                className="after:d-none cursor-pointer py-2 px-5 flex items-center gap-2 !rounded-lg outline:none"
              >
                <Chart
                  filled={tabIndex == 1}
                  size={20}
                  primaryColor={
                    tabIndex == 1
                      ? ColorPalette.primary["500"]
                      : ColorPalette.grey["700"]
                  }
                />
                <span
                  className={`text-sm ${
                    tabIndex == 1
                      ? "text-primary-500 font-bold"
                      : "text-grey-700"
                  }`}
                >
                  {t("reports")}
                </span>
              </Tab>
            </TabList>
            <TabPanel>
              <PaymentIdInfo {...data} />
            </TabPanel>
            <TabPanel>
              <Reports />
            </TabPanel>
          </Tabs>
        </>
      ) : (
        <>
          <Skeleton height={48} width={"100%"} style={{ marginBottom: 30 }} />
          <Skeleton height={64} width={308} style={{ marginBottom: 44 }} />
          <Skeleton height={400} width={"100%"} style={{ marginBottom: 30 }} />
        </>
      )}
    </div>
  );
};

export default Single;
