import { Popover, Transition } from "@headlessui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { More2, Trash } from "iconsax-react";
import React, { Fragment, useEffect, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";

import { useTranslation } from "react-i18next";
import { Edit, MoreCircle, Plus } from "react-iconly";
import Skeleton from "react-loading-skeleton";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { PaymentApi } from "src/api";
import {
  CreditBgPasargad,
  CreditBgSvg,
  MoreSvg,
  PasargadLogo,
} from "src/assets";
import { ToastMsg } from "src/shared";
import { ColorPalette, Helpers } from "src/utils";
import AddCardModal from "../AddCardModal";

type Props = {};

interface ICreditCard {
  id: number;
  card_number: string;
  iban: string;
  owner: any;
}

interface ICardModal {
  show: boolean;
  data: ICreditCard;
  stamp: Date;
}

const CreditCards = (props: Props) => {
  const { t, i18n } = useTranslation("profile/ts");

  const [cardModal, setCardModal] = useState<ICardModal>({
    show: false,
    data: undefined,
    stamp: new Date(),
  });

  const {
    data,
    isLoading: isQueryLoading,
    refetch,
  } = useQuery({
    queryKey: ["[Cards] Profile"],
    queryFn: () =>
      PaymentApi.getCreditCards().then((result) => {
        return result.results;
      }),
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [cardModal]);

  return (
    <div className="w-full">
      <AddCardModal
        isOpen={cardModal.show}
        setIsOpen={(state) => setCardModal({ ...cardModal, show: state })}
        data={cardModal?.data}
      />
      <h3 className="text-lg text-grey-800 mb-5">{t("my_cred_cards")}</h3>
      <div className="w-full grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-4">
          <div
            className="w-full h-full flex items-center justify-center p-[30px] lg:p-[37px] border border-dashed border-primary-500 rounded-[20px] cursor-pointer"
            onClick={() =>
              setCardModal({ show: true, stamp: new Date(), data: undefined })
            }
          >
            <div className="flex items-center">
              <Plus size={20} primaryColor={ColorPalette.primary["500"]} />
              <span
                className={`relative text-sm text-primary-500 ${
                  i18n.dir() == "rtl" ? "mr-2" : "ml-2"
                }`}
              >
                {t("add_new_card")}
              </span>
            </div>
          </div>
        </div>
        {data
          ? data.map((item: ICreditCard) => (
              <div key={"card" + item.id} className="col-span-12 lg:col-span-4">
                <CreditCard
                  {...item}
                  onEdit={() =>
                    setCardModal({ show: true, stamp: new Date(), data: item })
                  }
                />
              </div>
            ))
          : [1, 2, 3].map((id) => (
              <div key={"skeleton" + id} className="col-span-12 lg:col-span-4">
                <Skeleton height={174} className="w-full rounded-full" />
              </div>
            ))}
      </div>
    </div>
  );
};

type CreditCardProps = {
  card_number: string;
  iban: string;
  onEdit: () => void;
  id: number;
};

const CreditCard = ({ card_number, iban, onEdit, id }: CreditCardProps) => {
  const { t, i18n } = useTranslation("profile/ts");
  const { t: validationTranslations } = useTranslation("validation/ts");

  const bankInfo = Helpers.DetectCreditBank(card_number);

  const svgString = encodeURIComponent(
    renderToStaticMarkup(<CreditBgSvg color={bankInfo.color} />)
  );

  const queryClient = useQueryClient();

  const deleteMutuate = useMutation(PaymentApi.deleteCreditCard, {
    onSuccess: (res) => {
      toast(
        <ToastMsg
          type="success"
          message={validationTranslations("data_delete_success")}
        />
      );
      queryClient.invalidateQueries({ queryKey: ["[Cards] Profile"] });
    },
    onError: (error: AxiosError) => {
      if (error.response.status == 400) {
        toast(<ToastMsg type="error" message={t("data_store_error")} />);
      }
    },
  });

  return (
    <div
      className="w-full py-5 px-4 rounded-[20px] shadow-creditCard"
      style={{
        backgroundImage: `url("data:image/svg+xml,${svgString}")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="w-full flex justify-between items-center mb-[30px]">
        <div className="flex items-center gap-2">
          <img src={bankInfo?.bank_logo} width="40px" />
          <h4 className="text-sm lg:text-base text-grey-700">
            {bankInfo.bank_name.replace(
              bankInfo.bank_name[0],
              bankInfo.bank_name[0].toUpperCase()
            )}
          </h4>
        </div>

        <Popover className="relative">
          {({ close }) => (
            <>
              <Popover.Button>
                <img src={MoreSvg} className="cursor-pointer" />
              </Popover.Button>

              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Popover.Panel className="absolute z-10 right-4">
                  <div className="bg-white shadow-md p-3 rounded-xl">
                    <div
                      className="flex items-center gap-3 cursor-pointer"
                      onClick={() => {
                        onEdit();
                        close();
                      }}
                    >
                      <Edit size={24} primaryColor={ColorPalette.grey["800"]} />
                      <span className="text-sm text-grey-800">{t("edit")}</span>
                    </div>
                    {/* <div
                      className="flex items-center gap-3 cursor-pointer"
                      onClick={() => {
                        !deleteMutuate.isLoading && deleteMutuate.mutate(id);
                      }}
                    >
                      {deleteMutuate.isLoading ? (
                        <ClipLoader
                          color={ColorPalette.error["400"]}
                          loading={true}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      ) : (
                        <Trash size={24} color={ColorPalette.error["400"]} />
                      )}
                      <span className="text-sm text-error-400">
                        {t("delete")}
                      </span>
                    </div> */}
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
      <p className="text-base mb-5 font-bold">{iban}</p>
      <div className="flex justify-between items-center">
        <span className="text-sm font-semibold text-grey-600">
          {card_number.match(/.{1,4}/g).join("-")}
        </span>
        <span className="text-sm text-grey-600"></span>
      </div>
    </div>
  );
};

export default CreditCards;
