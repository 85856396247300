import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, OtpInput } from "src/components";

import ProgressBar from "@ramonak/react-progress-bar";

import { ColorPalette } from "src/utils";
import { EditSquare, TimeCircle } from "react-iconly";
import { CloseSvgRed, UsaFlag } from "src/assets";
import { Edit } from "iconsax-react";
import { useCountdown, useMediaQuery } from "src/hooks";

import moment from "moment";
import { Countries } from "src/constants";

type Props = {
  formik: any;
  resendOtp: () => void;
  step: number;
  backStep: () => void;
  resendLoading: boolean;
  checkOtpLoading: boolean;
};

let now = new Date();
let Min2LaterDate = moment(now).add(2, "m").toDate().getTime();

const Step2 = ({
  formik,
  resendOtp,
  step,
  backStep,
  checkOtpLoading,
  resendLoading,
}: Props) => {
  const { t, i18n } = useTranslation("auth/ts");
  const isMobile = useMediaQuery("(max-width: 1023px)");

  const [resend, setResend] = useState(false);
  const [targetDate, setTargetDate] = useState<number | null>();

  const countDown = useCountdown(targetDate, () => setResend(true), step == 2);

  useEffect(() => {
    if (step == 2) {
      let now = new Date();
      let Min2LaterDate = moment(now).add(2, "m").toDate().getTime();
      setTargetDate(Min2LaterDate);
    }
  }, [step]);

  useEffect(() => {
    if (formik.values.otp.length == 5) {
      formik.submitForm();
    }
  }, [formik.values]);

  return (
    <div className="w-full h-full flex flex-col items-center justify-between">
      <div className="w-full flex flex-col items-center">
        <h1
          className={`text-grey-800 ${
            isMobile ? "text-base" : "text-[32px]"
          } mb-2 font-semibold`}
        >
          {t("enter_otp")}
        </h1>
        <p className={`text-grey-700 ${isMobile ? "text-sm" : "text-lg"} mb-5`}>
          {t("5_digit_code")}
        </p>
        <div className="w-full mb-6 flex justify-between">
          <div className="flex items-center">
            <img
              src={
                Countries.find(
                  (item) => item.code == formik.values.country_code
                )?.flag
              }
              style={{ width: 24, height: 24 }}
              className={`${i18n.dir() == "rtl" ? "ml-2" : "mr-2"}`}
            />
            <span className={"text-lg text-grey-800 font-semibold"}>
              ({formik.values.country_code}) {formik.values.phone_number}
            </span>
          </div>
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              backStep();
              formik.setFieldValue("otp", "");
            }}
          >
            <EditSquare size={16} primaryColor={ColorPalette.primary["500"]} />
            <span
              className={`${
                i18n.dir() == "rtl" ? "mr-2" : "ml-2"
              } text-[13px] text-primary-500`}
            >
              {t("edit_number")}
            </span>
          </div>
        </div>
        <div className="w-full mb-[30px]">
          <OtpInput
            value={formik.values.otp}
            error={formik.values.otp_error}
            numInputs={5}
            onChange={(otp) => {
              formik.setFieldValue("otp", otp);
              formik.setFieldValue("otp_error", false);
            }}
          />
        </div>
        <div className="w-full flex justify-between mb-[14px]">
          <span
            className={`text-base flex items-center gap-2 ${
              formik.values.otp_error ? "text-error-700" : "text-grey-700"
            }`}
          >
            {formik.values.otp_error && <img src={CloseSvgRed} />}
            {countDown &&
            parseInt(countDown.total_seconds) > 0 &&
            formik.values.otp_error
              ? t("code_wrong_alert_error")
              : t("in_process")}
          </span>
          <div className="flex items-center gap-1">
            <TimeCircle size={16} primaryColor={ColorPalette.grey["700"]} />
            <span className="text-base text-grey-800 font-bold">
              {countDown.minutes}:{countDown.seconds}
            </span>
          </div>
        </div>
        <ProgressBar
          completed={(parseInt(countDown.total_seconds) / 120) * 100}
          className="w-full"
          height={"8px"}
          borderRadius={"100px"}
          bgColor={ColorPalette.primary["500"]}
          isLabelVisible={false}
        />
      </div>
      <div className="w-full flex flex-col">
        <div className="mb-[30px] flex items-center">
          <div className="flex-1">
            <Button
              type="Secondary"
              disabled={!resend}
              addonClasses="w-full"
              loading={resendLoading}
              onClick={async () => {
                await resendOtp();
                let now = new Date();
                let Min2LaterDate = moment(now).add(2, "m").toDate().getTime();
                setTargetDate(Min2LaterDate);
              }}
            >
              {t("resend_code")}
            </Button>
          </div>
          <div style={{ width: 12 }}></div>
          <div className="flex-1">
            <Button
              disabled={!formik.isValid}
              addonClasses="w-full"
              onClick={() => formik.submitForm()}
              loading={checkOtpLoading}
            >
              {t("signIn")}
            </Button>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <span
            className={`text-grey-700 text-[14px] ${
              i18n.dir() == "rtl" ? "ml-[30px]" : "mr-[30px]"
            }`}
          >
            {t("try_email")}
          </span>
          <Link to="/auth/login-otp" className="text-[13px] text-primary-500">
            {t("login")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Step2;
