import React from "react";
import { useTranslation } from "react-i18next";
import { EmptyOrderSvg, EmptyPaymentSvg } from "src/assets";

type Props = {};

const EmptyPayment = (props: Props) => {
  const { t } = useTranslation("payments/ts");

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <img
        className="w-full mb-5"
        style={{ maxWidth: 704 }}
        src={EmptyPaymentSvg}
      />
      <p className="text-base text-grey-700">{t("payment_empty")}</p>
    </div>
  );
};

export default EmptyPayment;
