import React from "react";
import { useTranslation } from "react-i18next";
import { Download } from "react-iconly";
import { ColorPalette } from "src/utils";

type Props = {};

const TicketInfo = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <div className="flex mb-[14px] pb-[14px] border-b border-b-grey-200 items-center justify-between">
        <h6 className="text-sm mb-0 text-grey-700">{t("type")}</h6>
        <p className="text-sm font-semibold text-grey-800">{t("others")}</p>
      </div>
      <div className="flex mb-[14px] pb-[14px] border-b border-b-grey-200 items-center justify-between">
        <h6 className="text-sm mb-0 text-grey-700">{t("id")}</h6>
        <p className="text-sm font-semibold text-grey-800">46681</p>
      </div>
      <div className="flex mb-[14px] pb-[14px] border-b border-b-grey-200 items-center justify-between">
        <h6 className="text-sm mb-0 text-grey-700">{t("date")}</h6>
        <p className="text-sm font-semibold text-grey-800">2023.4.28 7:58</p>
      </div>
      <div className="flex mb-[14px] pb-[14px] border-b border-b-grey-200 flex-col">
        <h6 className="text-sm mb-2 text-grey-700">{t("title")}</h6>
        <p className="text-sm font-semibold text-grey-800">
          {t("create_an_account")}
        </p>
      </div>
      <div className="flex mb-[14px] pb-[14px] border-b border-b-grey-200 flex-col">
        <h6 className="text-sm mb-2 text-grey-700">{t("title")}</h6>
        <p className="text-sm font-semibold text-grey-800">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate
          similique aspernatur hic animi, vel sit eaque minima, distinctio rerum
          unde voluptas placeat temporibus itaque perspiciatis pariatur
          recusandae modi. Beatae, odit.
        </p>
      </div>
      <div className="flex mb-[14px] flex-col">
        <h6 className="text-sm mb-2 text-grey-700">{t("file")}</h6>
        <div className="flex items-center justify-between">
          <h6 className="text-sm font-semibold text-grey-800">File.jpg</h6>
          <Download size={20} primaryColor={ColorPalette.grey["700"]} />
        </div>
      </div>
    </div>
  );
};

export default TicketInfo;
