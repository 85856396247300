import React from "react";
import { useTranslation } from "react-i18next";
import { Input, PhoneInput } from "src/components";
import { useMediaQuery } from "src/hooks";
import { FormField } from "src/shared";

type Props = {
  formik: any;
};

const Step1 = ({ formik }: Props) => {
  const { t, i18n } = useTranslation("profile/ts");

  const isMobile = useMediaQuery("(max-width: 1023px)");

  return (
    <div className="mb-32">
      <h4 className="text-grey-800 text-base font-semibold mb-2">
        {t("edit_number")}
      </h4>
      <p className="mb-5 text-sm text-grey-700">{t("edit_number_desc")}</p>
      <FormField
        label={t("mobile_field") + "*"}
        field={
          <PhoneInput
            placeholder={t("enter_mobile")}
            name="phone"
            type={"phone"}
            sizes={isMobile ? "Small" : "Large"}
            onChangeText={(val, code) => {
              formik.setFieldValue("phone_number", val);
              formik.setFieldValue("country_code", code);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                formik.submitForm();
              }
            }}
            selectedFlag={formik.values.country_code}
            value={formik.values.phone_number}
            onFocus={() => formik.setFieldTouched("phone_number", true)}
            autoComplete={"on"}
            onClear={() => formik.setFieldValue("phone_number", "")}
          />
        }
        message={
          formik.errors.phone_number &&
          formik.errors.phone_number && {
            type: "ERROR",
            content: formik.errors.phone_number,
          }
        }
      />
    </div>
  );
};

export default Step1;
