import React from "react";
import { useTranslation } from "react-i18next";
import { Paper } from "react-iconly";

type Props = {};

const OrderTimeLine = (props: Props) => {
  const { t } = useTranslation("orders/ts");

  return (
    <div className="mt-5 w-full">
      <h4 className="text-base text-grey-800 mb-[14px]">
        {t("order_timeline")}
      </h4>
      <p className="text-sm text-grey-700 mb-[30px]">
        {t("order_timeline_desc")}
      </p>
      <div className="flex flex-col" style={{ maxWidth: 350 }}>
        <div className="flex items-center gap-5 mb-4">
          <div className="relative">
            <div className="absolute h-4 w-1 bg-primary-400 rounded-full -top-1/3 z-0 left-0 right-0 mx-auto"></div>
            <div className="relative flex items-center justify-center w-10 h-10 rounded-xl bg-primary-500 z-10">
              <Paper filled={true} primaryColor="white" />
            </div>
            <div className="absolute h-4 w-1 bg-primary-400 rounded-full -bottom-1/3 z-0 left-0 right-0 mx-auto"></div>
          </div>
          <div className="rounded-lg px-4 py-3 bg-primary-100 text-base text-primary-500">
            {t("timeline_order_registered")}
          </div>
        </div>
        <div className="flex items-center gap-5 mb-4">
          <div className="relative">
            <div className="absolute h-4 w-1 bg-primary-400 rounded-full -top-1/3 z-0 left-0 right-0 mx-auto"></div>
            <div className="relative flex items-center justify-center w-10 h-10 rounded-xl bg-primary-500 z-10">
              <Paper filled={true} primaryColor="white" />
            </div>
            <div className="absolute h-4 w-1 bg-primary-400 rounded-full -bottom-1/3 z-0 left-0 right-0 mx-auto"></div>
          </div>
          <div className="rounded-lg px-4 py-3 bg-primary-100 text-base text-primary-500">
            {t("timeline_order_registered")}
          </div>
        </div>
        <div className="flex items-center gap-5 mb-4">
          <div className="relative">
            <div className="absolute h-4 w-1 bg-primary-400 rounded-full -top-1/3 z-0 left-0 right-0 mx-auto"></div>
            <div className="relative flex items-center justify-center w-10 h-10 rounded-xl bg-primary-500 z-10">
              <Paper filled={true} primaryColor="white" />
            </div>
            <div className="absolute h-4 w-1 bg-primary-400 rounded-full -bottom-1/3 z-0 left-0 right-0 mx-auto"></div>
          </div>
          <div className="rounded-lg px-4 py-3 bg-primary-100 text-base text-primary-500">
            {t("timeline_order_registered")}
          </div>
        </div>
        <div className="flex items-center gap-5 mb-4">
          <div className="relative">
            <div className="absolute h-4 w-1 bg-primary-400 rounded-full -top-1/3 z-0 left-0 right-0 mx-auto"></div>
            <div className="relative flex items-center justify-center w-10 h-10 rounded-xl bg-success-500 z-10">
              <Paper filled={true} primaryColor="white" />
            </div>
            <div className="absolute h-4 w-1 bg-grey-100 rounded-full -bottom-1/3 z-0 left-0 right-0 mx-auto"></div>
          </div>
          <div className="rounded-lg px-4 py-3 bg-success-100 text-base text-success-500">
            {t("timeline_order_registered")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTimeLine;
