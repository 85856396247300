import {
  QueryClient,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Trash } from "iconsax-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Edit } from "react-iconly";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { OrderApi } from "src/api";
import { ToastMsg } from "src/shared";
import { useCommonStore } from "src/store";
import { ColorPalette } from "src/utils";

type Props = {
  row: any;
};

const CartItemAction = (props: Props) => {
  const { t } = useTranslation("validation/ts");

  const { setNewOrderModal } = useCommonStore();

  const queryClient = useQueryClient();

  const deleteMutuate = useMutation(OrderApi.deleteCartItem, {
    onSuccess: (res) => {
      toast(<ToastMsg type="success" message={t("cart_item_del_success")} />);
      queryClient.invalidateQueries({
        queryKey: ["[List] Cart"],
      });
      queryClient.invalidateQueries({
        queryKey: ["[Info] Checkout"],
      });
    },
    onError: (error: AxiosError) => {
      if (error.response.status == 400) {
        toast(<ToastMsg type="error" message={t("data_store_error")} />);
      }
    },
  });

  return (
    <div className="flex items-center gap-7">
      <div onClick={() => setNewOrderModal(true, props.row.row.original)}>
        <Edit size={16} primaryColor={ColorPalette.grey["800"]} />
      </div>
      {deleteMutuate.isLoading ? (
        <ClipLoader
          color={ColorPalette.error["400"]}
          loading={true}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <Trash
          size={16}
          color={ColorPalette.error["400"]}
          onClick={() => deleteMutuate.mutate(props.row.row.original.id)}
        />
      )}
    </div>
  );
};

export default CartItemAction;
