import React from "react";
import SwitchComp, { ReactSwitchProps } from "react-switch";
import { ColorPalette } from "src/utils";

type Props = {};

const Switch = (props: ReactSwitchProps) => {
  return (
    <SwitchComp
      {...props}
      checkedIcon={false}
      height={16}
      onColor={ColorPalette.primary["500"]}
      handleDiameter={14}
    />
  );
};

export default Switch;
