import { HambergerMenu, Menu } from "iconsax-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowLeft, ArrowRight, Buy, Discount, Plus, User, Wallet } from "react-iconly";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";

import { MenuAltSvg, SampleAvatar } from "src/assets";
import { Button } from "src/components";
import { useMediaQuery } from "src/hooks";
import { useAuthStore, useCommonStore } from "src/store";

import { ColorPalette, Helpers } from "src/utils";
import Drawer from "../Drawer";
import { useQuery } from "@tanstack/react-query";
import { OrderApi } from "src/api";
import Skeleton from "react-loading-skeleton";

type Props = {};

const Header = (props: Props) => {
  const { user } = useAuthStore();
  const { setNewOrderModal } = useCommonStore();

  const navigate = useNavigate();

  const { t, i18n } = useTranslation("dashboard/ts");
  const { pathname } = useLocation();

  const isMobile = useMediaQuery("(max-width: 1023px)");

  const [isOpen, setIsOpen] = useState(false);

  const {
    data,
    isLoading: isQueryLoading,
    status,
  } = useQuery({
    queryKey: ["[List] Cart"],
    queryFn: () =>
      OrderApi.getCartItems().then((result) => {
        return result.results;
      }),
  });

  const result = React.useMemo(() => {
    if (!isQueryLoading && data && status === "success") {
      if (data.length > 0) {
        const { adwords_cart_item, ...rest } = data[0];
        return adwords_cart_item;
      }
      return [];
    } else {
      return [];
    }
  }, [data]);

  const isActive = (path: string) => {
    return pathname.includes(path);
  };

  const renderAvatar = () => {
    if (user.avatar) {
      return (
        <img
          src={user?.avatar}
          className={
            i18n.dir() == "rtl"
              ? "ml-2 rounded-full cursor-pointer w-[30px] h-[30px] object-cover"
              : "mr-2 rounded-full cursor-pointer w-[30px] h-[30px] object-cover"
          }
          onClick={() => navigate("/profile")}
        />
      );
    } else if (
      (user.first_name && user.first_name.length > 0) ||
      (user.last_name && user.last_name.length > 0)
    ) {
      return (
        <div
          className={`w-[30px] h-[30px] bg-primary-500 rounded-full cursor-pointer flex items-center justify-center text-sm text-grey-50 font-bold ${
            i18n.dir() == "rtl" ? "ml-2" : "mr-2"
          }`}
          onClick={() => navigate("/profile")}
        >
          {user.first_name ? user?.first_name.charAt(0) : ""}
          {user.last_name ? user?.last_name.charAt(0) : ""}
        </div>
      );
    } else {
      return (
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => navigate("/profile")}
        >
          <div
            className={`w-[30px] h-[30px] bg-grey-100 rounded-full flex items-center gap-2 justify-center`}
          >
            <User size={20} primaryColor={ColorPalette.grey["700"]} />
          </div>
          <span className="text-sm text-grey-700 font-bold">
            {t("your_profile")}
          </span>
        </div>
      );
    }
  };

  return (
    <>
      <Drawer isOpen={isOpen} toggleDrawer={() => setIsOpen(false)} />
      <header className="w-full px-4 lg:px-[30px] py-[9px] bg-grey-50 flex items-center justify-between">
        <div className="flex items-center">
          {user ? (
            <>
              <img
                style={{
                  display: isMobile ? "block" : "none",
                  margin: i18n.dir() == "rtl" ? "0 0 0 12px" : "0 12px 0 0",
                }}
                src={MenuAltSvg}
                onClick={() => setIsOpen(true)}
              />
              {renderAvatar()}
              <h6
                className={`${
                  i18n.dir() == "rtl" ? "ml-2" : "mr-2"
                } hidden lg:block font-bold text-base text-grey-700 cursor-pointer`}
                onClick={() => navigate("/profile")}
              >
                {user?.first_name} {user?.last_name}
              </h6>
              {i18n.language == "en" ? (
                <ArrowRight
                  size={20}
                  style={{
                    margin: "0 12px 0 0",
                    display: isMobile ? "none" : "block",
                  }}
                />
              ) : (
                <ArrowLeft
                  size={20}
                  style={{
                    margin: "0 0 0 12px",
                    display: isMobile ? "none" : "block",
                  }}
                />
              )}
            </>
          ) : (
            <Skeleton width={120} height={30} />
          )}
          <Button
            size={isMobile ? "Small" : "Medium"}
            icon={<Plus size={20} />}
            onClick={() => setNewOrderModal(true)}
          >
            {t("new_order")}
          </Button>
          {/* <ArrowRight
            size={20}
            style={{
              margin: i18n.dir() == "rtl" ? "0 0 0 12px" : "0 12px 0 0",
              display: isMobile ? "none" : "block",
            }}
          />
          {!pathname.includes("discount-level") && (
            <div
              className="hidden px-4 py-2 bg-primary-50 lg:flex items-center"
              style={{ borderRadius: 100 }}
            >
              <Discount
                size={20}
                style={{
                  margin: i18n.dir() == "rtl" ? "0 0 0 12px" : "0 12px 0 0",
                }}
                primaryColor={ColorPalette.grey["700"]}
              />
              <span
                className={`${
                  i18n.dir() == "rtl" ? "ml-3" : "mr-3"
                } text-sm text-grey-700`}
              >
                {t("discount_level")}:
              </span>
              <span className="text-primary-500 text-sm">
                <strong className="font-bold text-base">02</strong> / 10
              </span>
            </div>
          )} */}
        </div>
        <div className="flex items-center gap-3">
          <Link
            to={"/wallet"}
            className={`relative flex justify-center items-center p-[8px] border border-primary-300 border-dashed rounded-[100px] ${
              isActive("/wallet") && "bg-primary-50"
            }`}
          >
            <Wallet size={20} primaryColor={ColorPalette.grey["700"]} />
          </Link>
          <Link
            to={"/cart"}
            className={`relative flex justify-center items-center p-[8px] border border-primary-300 border-dashed rounded-[100px] ${
              isActive("/cart") && "bg-primary-50"
            }`}
          >
            <Buy size={20} primaryColor={ColorPalette.grey["700"]}></Buy>
            {result && result.length > 0 && (
              <div className="absolute right-[6px] bottom-[18px] bg-secondary-400 rounded-full flex items-center justify-center w-[10px] h-[10px] text-[6px] text-grey-50">
                {result.length}
              </div>
            )}
          </Link>
        </div>
      </header>
      {/* {!pathname.includes("discount-level") && (
        <div className="w-full bg-white px-4 pt-5 lg:hidden">
          <div
            className="px-4 py-2 bg-primary-50 flex justify-between items-center"
            style={{ borderRadius: 100 }}
          >
            <div className="flex items-center">
              <Discount
                size={20}
                style={{
                  margin: i18n.dir() == "rtl" ? "0 0 0 12px" : "0 12px 0 0",
                }}
                primaryColor={ColorPalette.grey["700"]}
              />
              <span
                className={`${
                  i18n.dir() == "rtl" ? "ml-3" : "mr-3"
                } text-sm text-grey-700`}
              >
                {t("discount_level")}:
              </span>
            </div>
            <span className="text-primary-500 text-sm">
              <strong className="font-bold text-base">02</strong> / 10
            </span>
          </div>
        </div>
      )} */}
    </>
  );
};

export default Header;
