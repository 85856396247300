import { Google, Money } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Calling,
  Category,
  Chart,
  Discount,
  Home,
  Logout,
  Notification,
  Paper,
  Setting,
} from "react-iconly";
import DrawerComp from "react-modern-drawer";
import { useLocation } from "react-router-dom";
import { AuthApi } from "src/api";
import { CloseSvg, GoogleFillSvg, GoogleSvg, LogoEn, LogoFa, MoneyFillSvg, MoneySvg } from "src/assets";
import { useAuthStore } from "src/store";
import { ColorPalette } from "src/utils";
import MenuItem from "../MenuItem";

type Props = {
  isOpen: boolean;
  toggleDrawer: () => void;
};

const Drawer = ({ isOpen, toggleDrawer }: Props) => {
  const { i18n, t } = useTranslation("sidebar/ts");

  const { logoutUser, refresh } = useAuthStore();

  const { pathname } = useLocation();

  const [loading, setLodaing] = useState<boolean>(false);

  const logout = () => {
    setLodaing(true);
    AuthApi.postLogout({ body: { refresh } })
      .then((result) => {
        setLodaing(false);
        logoutUser();
      })
      .catch((err) => {
        setLodaing(false);
      });
  };

  useEffect(() => {
    isOpen && toggleDrawer();
  }, [pathname]);

  return (
    <DrawerComp
      open={isOpen}
      onClose={toggleDrawer}
      direction={i18n.dir() == "rtl" ? "right" : "left"}
      className="!w-full !h-full"
      style={{ zIndex: 1000000000 }}
    >
      <div className="w-full h-full flex flex-col bg-white pt-[11px] pb-5">
        <div className="w-full flex items-center mb-5 px-4 gap-3">
          <img src={CloseSvg} onClick={toggleDrawer} />
          <img src={i18n.language == "en" ? LogoEn : LogoFa} width="118px" />
        </div>
        {/* <div
          className="mx-5 px-4 py-2 mb-5 bg-primary-50 flex justify-between items-center"
          style={{ borderRadius: 100 }}
        >
          <div className="flex items-center">
            <Discount
              size={20}
              style={{
                margin: i18n.dir() == "rtl" ? "0 0 0 12px" : "0 12px 0 0",
              }}
              primaryColor={ColorPalette.grey["700"]}
            />
            <span
              className={`${
                i18n.dir() == "rtl" ? "ml-3" : "mr-3"
              } text-sm text-grey-700`}
            >
              Discount Level:
            </span>
          </div>
          <span className="text-primary-500 text-sm">
            <strong className="font-bold text-base">02</strong> / 10
          </span>
        </div> */}
        <div className="w-full flex-1 flex flex-col justify-between">
          <ul className="w-full p-0 flex flex-col ">
            <MenuItem path={"dashboard"} title={t("home")} icon={Home} />
            <MenuItem
              path={"accounts"}
              title={t("account")}
              customIcon={{ simple: GoogleSvg, fill: GoogleFillSvg }}
            />
            <MenuItem path={"orders"} title={t("orders")} icon={Paper} />
            <MenuItem
              path={"payments"}
              title={t("financial")}
              customIcon={{ simple: MoneySvg, fill: MoneyFillSvg }}
              subMenu={[
                {
                  name: t("payments"),
                  path: "/payments",
                },
                {
                  name: t("wallet"),
                  path: "/wallet",
                },
                {
                  name: t("payment_ids"),
                  path: "/payment-ids",
                },
                {
                  name: t("cards"),
                  path: "/profile?tab=cards",
                },
              ]}
            />
            <MenuItem
              path={"support"}
              title={t("support")}
              icon={Calling}
              type="disabled"
            />
          </ul>
          <ul className="w-full p-0 flex flex-col ">
            <MenuItem path={"setting"} title={t("setting")} icon={Setting} />
            <MenuItem
              type="disabled"
              path={"notifications"}
              title={t("notifications")}
              icon={Notification}
            />
            <MenuItem
              type="danger"
              path={"logout"}
              title={t("logout")}
              icon={Logout}
              onClick={logout}
              loading={loading}
            />
          </ul>
        </div>
      </div>
    </DrawerComp>
  );
};

export default Drawer;
