import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PaymentApi } from "src/api";
import { Button, CheckBox, Input, Modal } from "src/components";
import { Collapse } from "react-collapse";
import { ChevronDown, ChevronUp, Download, Plus } from "react-iconly";
import { ColorPalette, Helpers } from "src/utils";
import { FormField, ToastMsg } from "src/shared";
import { useMediaQuery } from "src/hooks";

type Props = {
  data: Helpers.keyable;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
};

enum PAYMENT_STATUS {
  SUCCESSFUL = "SUCCESSFUL",
  FAILED = "FAILED",
  PENDING = "PENDING",
}

const DetailModal = (props: Props) => {
  const { t, i18n } = useTranslation("payments/ts");

  const getPaymentStatusT = (status: string) => {
    switch (status) {
      case PAYMENT_STATUS.SUCCESSFUL:
        return t("successful");
      case PAYMENT_STATUS.FAILED:
        return t("failed");
      case PAYMENT_STATUS.PENDING:
        return t("pending");

      default:
        break;
    }
  };

  const getPaymentStatusColor = (status: string) => {
    switch (status) {
      case PAYMENT_STATUS.SUCCESSFUL:
        return "success";
      case PAYMENT_STATUS.FAILED:
        return "error";
      case PAYMENT_STATUS.PENDING:
        return "primary";

      default:
        break;
    }
  };

  return (
    <Modal
      title={t("payment_details")}
      isOpen={props.isOpen}
      onClose={() => props.setIsOpen(false)}
    >
      {props.data ? (
        <>
          <div className="px-[6px]">
            <div className="w-full border-b border-b-grey-200 pb-[14px] mb-[14px] flex items-center justify-between">
              <h6 className="text-sm text-grey-700">{t("payment_number")}</h6>
              <span className="text-sm text-grey-800 font-semibold">
                {props.data.id}
              </span>
            </div>
            <div className="w-full border-b border-b-grey-200 pb-[14px] mb-[14px] flex items-center justify-between">
              <h6 className="text-sm text-grey-700">{t("order_id")}</h6>
              <span className="text-sm text-grey-800 font-semibold">
                {props.data.order.join(" | ")}
              </span>
            </div>
            <div className="w-full border-b border-b-grey-200 pb-[14px] mb-[14px] flex items-center justify-between">
              <h6 className="text-sm text-grey-700">{t("tracking_code")}</h6>
              <span className="text-sm text-grey-800 font-semibold">
                {props.data.tracking_code}
              </span>
            </div>
            <div className="w-full border-b border-b-grey-200 pb-[14px] mb-[14px] flex items-center justify-between">
              <h6 className="text-sm text-grey-700">{t("total_price")}</h6>
              <span className="text-sm text-grey-800 font-semibold">
                {props.data.amount.toLocaleString()}
              </span>
            </div>
            <div className="w-full border-b border-b-grey-200 pb-[14px] mb-[14px] flex items-center justify-between">
              <h6 className="text-sm text-grey-700">{t("date")}</h6>
              <span className="text-sm text-grey-800 font-semibold">
                {props.data.created_date}
              </span>
            </div>
            <div className="w-full border-b border-b-grey-200 pb-[14px] mb-[14px] flex items-center justify-between">
              <h6 className="text-sm text-grey-700">{t("file")}</h6>
              <span className="text-sm text-grey-800 font-semibold gap-3 flex items-center">
                {/* {props.data.created_date} */}
                <a href={props.data.attachment} target="_blank">
                  <Download size={20} primaryColor={ColorPalette.grey["700"]} />
                </a>
              </span>
            </div>
            <div className="w-full flex items-center justify-between">
              <h6 className="text-sm text-grey-700">{t("status")}</h6>
              <div
                className={`px-3 py-[5px] bg-${getPaymentStatusColor(
                  props.data.status
                )}-50 rounded-lg text-${getPaymentStatusColor(
                  props.data.status
                )}-500 text-xs text-center`}
              >
                {getPaymentStatusT(props.data.status)}
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default DetailModal;
