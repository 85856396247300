import { AddSquare } from "iconsax-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Plus } from "react-iconly";
import { Link } from "react-router-dom";
import { TicketsBgSvg } from "src/assets";
import { Button, Input, Select, TextArea } from "src/components";
import { useMediaQuery } from "src/hooks";
import { FormField } from "src/shared";
import { ColorPalette } from "src/utils";

type Props = {};

const Create = (props: Props) => {
  const { t } = useTranslation("tickets/ts");

  const isMobile = useMediaQuery("(max-width: 1023px)");

  return (
    <div className="w-full h-full grid grid-cols-12 gap-4">
      <div className="col-span-12 lg:col-span-5 my-[10px]">
        <div className="w-full mb-[14px]">
          <FormField
            label={t("type")}
            field={
              <Select options={[]} placeholder={t("choose_ticket_type")} />
            }
          />
        </div>
        <div className="w-full mb-[14px]">
          <FormField
            label={t("title")}
            field={
              <Input
                placeholder={t("enter_title")}
                sizes={isMobile ? "Small" : "Large"}
              />
            }
          />
        </div>
        <div className="w-full mb-[14px]">
          <FormField
            label={t("your_msg")}
            field={
              <TextArea
                placeholder={t("enter_your_msg")}
                sizes={isMobile ? "Small" : "Large"}
              />
            }
          />
        </div>
        <div className="flex items-center mb-5 gap-3">
          <div className="flex-1">
            <Input
              placeholder={t("upload_file")}
              sizes={isMobile ? "Small" : "Large"}
            />
          </div>
          <Link
            to="#"
            className="p-3 lg:p-[14px] bg-success-500 rounded-lg flex itemce-center justify-center"
          >
            <AddSquare size={24} color={ColorPalette.grey["50"]} />
          </Link>
        </div>
        <Button
          addonClasses="w-full"
          size={isMobile ? "Small" : "Medium"}
          icon={<Plus size={20} />}
        >
          {t("new_ticket")}
        </Button>
      </div>
      <div className="col-span-12 lg:col-span-7">
        <img src={TicketsBgSvg} />
      </div>
    </div>
  );
};

export default Create;
