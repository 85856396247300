import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ChevronDown, ChevronUp, Plus } from "react-iconly";
import { Clipboard } from "iconsax-react";
import { ClipLoader } from "react-spinners";
import { WalletApi } from "src/api";
import { CopySvg, MellatBankIcon } from "src/assets";
import { ColorPalette, Helpers } from "src/utils";
import { Collapse } from "react-collapse";
import { toast } from "react-toastify";
import { ToastMsg } from "src/shared";

type Props = {};

const PaymentIdSec = (props: Props) => {
  const { t } = useTranslation("wallet/ts");

  const [details, setDetails] = useState<Helpers.keyable>();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const createPaymentIdMutate = useMutation(WalletApi.createPaymentId, {
    onSuccess: (res: AxiosResponse) => {
      setDetails(res);
    },
    onError: (error: AxiosError) => {
      toast(<ToastMsg type="error" message={t("error_shenase")} />);
    },
  });

  const {
    data: PaymentIds,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["[List] Payment Ids"],
    queryFn: () =>
      WalletApi.getPaymentIds({}).then((result) => {
        if (result.results.length > 0) {
          setDetails(result.results[0]);
        }
        return result.results;
      }),
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [props]);

  return (
    <div className="flex-1">
      <div className="flex justify-between items-center">
        <p className="text-md text-grey-700">{t("payment_id_desc")}</p>
        {!details && (
          <div
            className="flex items-center gap-2 text-[13px] text-primary-500"
            onClick={() => createPaymentIdMutate.mutate()}
          >
            {createPaymentIdMutate.isLoading || isFetching ? (
              <ClipLoader size={18} color={ColorPalette.primary["500"]} />
            ) : (
              <Plus size={18} />
            )}
            {t("create_payment_id")}
          </div>
        )}
      </div>
      {details && (
        <>
          <div className="mt-6">
            <div className="mb-5 p-4 rounded-lg border border-grey-200 bg-grey-50">
              <div className="flex flex-row gap-3 items-start">
                <img src={MellatBankIcon} width={20} height={20} />
                <div className="flex-1 flex flex-col">
                  <h4 className="text-md text-grey-800 font-semibold">
                    {t("mellat_bank")}
                  </h4>
                  <p className="text-md text-grey-700">
                    {t("mellat_bank_desc")}
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-5 py-5 px-4 bg-primary-50 rounded-xl flex justify-between items-center">
              <h4 className="text-md text-grey-700 font-semibold">
                {t("payment_id")}
              </h4>
              <div className="flex items-center gap-3 text-md text-grey-800 font-semibold">
                {details.payment_id}
                <img
                  src={CopySvg}
                  onClick={() => {
                    navigator.clipboard.writeText(details.payment_id);
                    toast(<ToastMsg type="success" message={t("copied")} />);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="px-4 py-5 rounded-[20px] bg-grey-50 mb-5">
            <div
              className="flex justify-between items-center"
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <h4 className="text-base font-semibold text-grey-800">
                {t("payment_details")}:
              </h4>
              {isOpen ? (
                <ChevronUp size={20} primaryColor={ColorPalette.grey["700"]} />
              ) : (
                <ChevronDown
                  size={20}
                  primaryColor={ColorPalette.grey["700"]}
                />
              )}
            </div>
            <Collapse isOpened={isOpen}>
              <div className="mt-5 flex flex-col gap-[9px]">
                <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
                  <span className="text-md text-grey-700">
                    {t("sheba_number")}
                  </span>
                  <div className="flex items-center gap-3 text-md text-grey-800 font-semibold">
                    {details.destination_iban}
                    <img
                      src={CopySvg}
                      onClick={() => {
                        navigator.clipboard.writeText(details.destination_iban);
                        toast(
                          <ToastMsg type="success" message={t("copied")} />
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-md text-grey-700">
                    {t("account_owner")}
                  </span>
                  <span className="flex items-center text-sm- text-grey-600">
                    {details.destination_owner_name}
                  </span>
                </div>
              </div>
            </Collapse>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentIdSec;
