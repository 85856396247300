import { Minus } from "iconsax-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { User } from "react-iconly";
import { SampleAvatar, SampleAvatar2 } from "src/assets";
import { useAuthStore } from "src/store";
import { ColorPalette } from "src/utils";

type Props = {};

const PersonalInfo = (props: Props) => {
  const { t } = useTranslation("profile/ts");

  const { user } = useAuthStore();

  const renderAvatar = () => {
    if (user.avatar) {
      return (
        <img
          src={user?.avatar}
          className="w-60 h-60 rounded-full p-5 border border-grey-200 mb-12"
          style={{ objectFit: "cover" }}
        />
      );
    } else if (
      (user.first_name && user.first_name.length > 0) ||
      (user.last_name && user.last_name.length > 0)
    ) {
      return (
        <div className="w-60 h-60 rounded-full p-5 border border-grey-200 mb-12">
          <div className="w-full h-full bg-primary-500 rounded-full flex items-center justify-center text-8xl text-grey-50 font-bold">
            {user.first_name ? user?.first_name.charAt(0) : ""}
            {user.last_name ? user?.last_name.charAt(0) : ""}
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-60 h-60 rounded-full p-5 border border-grey-200 mb-12">
          <div className="w-full h-full bg-grey-100 rounded-full flex items-center justify-center">
            <User size={100} primaryColor={ColorPalette.grey["700"]} />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="w-full flex flex-col items-center lg:items-start">
      {renderAvatar()}
      <div className="w-full pt-10 px-[14px] pb-5 border border-grey-200 rounded-[20px] relative">
        <div className="absolute top-[-24px] p-3 gap-2 border border-grey-200 rounded-lg bg-white flex items-center">
          <Minus size={24} color={ColorPalette.grey["800"]} />
          <span className="text-grey-800 text-sm">
            {t("personal_information")}
          </span>
        </div>
        <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
          <span className="text-grey-700 text-xs lg:text-sm">{t("name")}</span>
          <span className="text-grey-800 text-sm font-semibold">
            {user?.first_name}
          </span>
        </div>
        <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
          <span className="text-grey-700 text-xs lg:text-sm">
            {t("last_name")}
          </span>
          <span className="text-grey-800 text-sm font-semibold">
            {user?.last_name}
          </span>
        </div>
        <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
          <span className="text-grey-700 text-xs lg:text-sm">{t("email")}</span>
          <span className="text-grey-800 text-sm font-semibold">
            {user?.email}
          </span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-grey-700 text-xs lg:text-sm">
            {t("mobile")}
          </span>
          <span className="text-grey-800 text-sm font-semibold">
            {user?.phone_number}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
