import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Calendar,
  Edit,
  Filter2,
  Paper,
  Plus,
  Search,
  Show,
  Upload,
} from "react-iconly";
import { Link } from "react-router-dom";
import { Button, CheckBox, Pagination, Select, Table } from "src/components";
import { useMediaQuery } from "src/hooks";
import { ColorPalette } from "src/utils";
import { ITicketsInterface } from "./types";
import { createColumnHelper } from "@tanstack/react-table";
import { UsaFlag } from "src/assets";
// import DatePicker from "react-date-picker";
import { OrderModal } from "./partials";

type Props = {};

const Tickets = (props: Props) => {
  const { t, i18n } = useTranslation("tickets/ts");

  const isMobile = useMediaQuery("(max-width: 1023px)");

  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [orderModal, setOrderModal] = useState<boolean>(false);

  const columnHelper = createColumnHelper<ITicketsInterface>();

  const mockData: ITicketsInterface[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
    () => ({
      id: 46681,
      type: "Request to create or edit a account",
      date: "2023.4.28 7:58",
      status: "completed",
    })
  );

  const columns = [
    columnHelper.accessor("index", {
      header: () => <span>#</span>,
      cell: ({ row }) => row.index,
      enableSorting: false,
      size: 60,
    }),
    columnHelper.accessor("id", {
      cell: (row) => row.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor("type", {
      cell: (row) => row.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor("date", {
      cell: (row) => row.getValue(),
      sortingFn: () => 1,
    }),
    columnHelper.accessor("status", {
      cell: (row) => (
        <div className="px-3 py-[5px] bg-success-50 rounded-lg text-success-700 text-xs text-center">
          {row.getValue()}
        </div>
      ),
      size: 100,
    }),
    columnHelper.accessor("action", {
      header: () => <span></span>,
      cell: (row) => (
        <div className="flex items-center justify-end gap-12">
          <div className="p-[2px] bg-secondary-400 rounded-full text-xs text-grey-50 w-5 h-5 flex justify-center items-center">
            12
          </div>
          <Show primaryColor={ColorPalette.grey["700"]} size={24} />
        </div>
      ),
      enableSorting: false,
      size: 200,
    }),
  ];

  return (
    <div className="w-full">
      <OrderModal isOpen={orderModal} setIsOpen={setOrderModal} />
      <div className="w-full flex items-center justify-between flex-wrap mb-[30px]">
        <div className="flex items-center gap-3">
          <Button
            size={isMobile ? "Small" : "Medium"}
            icon={<Plus size={24} />}
            onClick={() => setOrderModal(true)}
          >
            {t("new_support_ticket")}
          </Button>
        </div>
        <div className="flex items-center gap-3">
          <Link
            to="#"
            className="p-3 bg-secondary-400 rounded-lg flex itemce-center justify-center"
            onClick={() => setFilterOpen(true)}
          >
            <Filter2 size={24} primaryColor={ColorPalette.grey["50"]} />
          </Link>
          <div className="hidden py-2 px-[14px] rounded-lg border border-grey-200 gap-3 lg:flex items-center">
            <input placeholder={t("search")} className="outline-none" />
            <Link
              to="#"
              className="p-2 bg-primary-500 rounded-lg flex itemce-center justify-center"
            >
              <Search size={16} primaryColor={ColorPalette.grey["50"]} />
            </Link>
          </div>
        </div>
      </div>
      {filterOpen && (
        <div className="py-[26px] px-5 mb-[30px] flex items-center flex-wrap border border-grey-200 rounded-[20px]">
          <div className="flex items-center" style={{ minWidth: 280 }}>
            <div
              className={`p-[14px] bg-grey-100 flex justify-center items-center ${
                i18n.dir() == "rtl"
                  ? "rounded-tr-lg rounded-br-lg"
                  : "rounded-tl-lg rounded-bl-lg"
              }`}
            >
              <Calendar primaryColor={ColorPalette.grey["700"]} size={20} />
            </div>
            {/* <DatePicker
              onChange={() => {}}
              value={new Date()}
              className="w-full"

            /> */}
          </div>
          <div
            className="bg-grey-200 mx-[14px]"
            style={{ width: 1, height: 60 }}
          ></div>
          <div className="flex items-center gap-2">
            <span className="text-sm text-grey-800 font-semibold">
              {t("currency_type")}:
            </span>
            <CheckBox label="USD" checked={false} />
            <CheckBox label="AER" checked={false} />
            <CheckBox label="DRY" checked={false} />
          </div>
          <div className="flex-1 flex justify-end">
            <span className="text-xs text-error-400">
              {t("remove_filters")}
            </span>
          </div>
        </div>
      )}
      <div className="flex py-2 px-[14px] rounded-lg border border-grey-200 gap-3 lg:hidden items-center mb-[30px]">
        <input placeholder={t("search")} className="flex-1 outline-none" />
        <Link
          to="#"
          className="p-2 bg-primary-500 rounded-lg flex itemce-center justify-center"
        >
          <Search size={16} primaryColor={ColorPalette.grey["50"]} />
        </Link>
      </div>
      <div className="mb-[34px]">
        <Table
          data={mockData}
          columns={columns}
          title={t("support_ticket_list")}
          Icon={Paper}
          headerColor={"grey-100"}
          bordered={false}
        />
      </div>
      <div className="w-full flex items-center justify-between mb-6">
        <div className="flex items-center gap-[10px]">
          <span className="text-base text-grey-700">
            1-10 of 85 {t("items")}
          </span>
          <Select placeholder="10" options={[]} />
        </div>
        <Pagination />
      </div>
    </div>
  );
};

export default Tickets;
