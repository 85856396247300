import React from "react";
import { useTranslation } from "react-i18next";
import { Button, CheckBox, Input, Modal } from "src/components";
import { useMediaQuery } from "src/hooks";
import { FormField, ToastMsg } from "src/shared";

import * as Yup from "yup";
import { useFormik } from "formik";

import { IFormValues } from "./types";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useAuthStore } from "src/store";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "src/api";

const initialValues: IFormValues = {
  old_password: undefined,
  new_password: undefined,
  confirm_password: undefined,
};

type Props = {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
};

const containsUpperCase = (str: string) => str !== str.toLowerCase();
const containsLowerCase = (str: string) => str !== str.toUpperCase();
const containsNumberAndCharacter = (str: string) =>
  /\d/.test(str) && /[a-zA-Z]/.test(str);

const ChangePassModal = (props: Props) => {
  const { t } = useTranslation("profile/ts");
  const { t: validationTranslations } = useTranslation("validation/ts");

  const isMobile = useMediaQuery("(max-width: 1023px)");

  const validationSchema = Yup.object({
    old_password: Yup.string().required(
      validationTranslations("field_required")
    ),
    new_password: Yup.string()
      .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?!.* ).{8,16}$/)
      .required(validationTranslations("field_required"))
      .when(["old_password"], ([old_password], schema) => {
        return schema.notOneOf([old_password], t("old_new_pass"));
      }),
    confirm_password: Yup.string().oneOf(
      [Yup.ref("new_password")],
      validationTranslations("confirm_pass")
    ),
  });

  const changePass = useMutation(AuthApi.changePass, {
    onSuccess: (res) => {
      toast(
        <ToastMsg
          type="success"
          message={validationTranslations("pass_changed_success")}
        />
      );
      props.setIsOpen(false);
    },
    onError: (error: AxiosError) => {
      if (error.response.status == 400) {
        toast(<ToastMsg type="error" message={t("alert_login_pass_error")} />);
      }
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => changePass.mutate({ body: values }),
    validateOnChange: true,
  });

  return (
    <Modal
      title={t("change_pass")}
      isOpen={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      showClose={false}
    >
      <div className="w-full h-full flex flex-col justify-between">
        <div className="w-full">
          <div className="w-full mb-[14px]">
            <FormField
              label={t("old_password")}
              field={
                <Input
                  type={"password"}
                  placeholder={t("old_password")}
                  sizes={isMobile ? "Small" : "Large"}
                  onChange={(e) =>
                    formik.setFieldValue("old_password", e.target.value)
                  }
                  onFocus={() => formik.setFieldTouched("old_password", true)}
                  value={formik.values.old_password}
                  autoComplete={"on"}
                  onClear={() => formik.setFieldValue("old_password", "")}
                />
              }
              message={
                formik.errors.old_password &&
                formik.touched.old_password && {
                  type: "ERROR",
                  content: formik.errors.old_password,
                }
              }
            />
          </div>
          <div className="w-full mb-[14px]">
            <FormField
              label={t("new_password")}
              field={
                <Input
                  type={"password"}
                  placeholder={t("new_password")}
                  sizes={isMobile ? "Small" : "Large"}
                  onChange={(e) =>
                    formik.setFieldValue("new_password", e.target.value)
                  }
                  onFocus={() => formik.setFieldTouched("new_password", true)}
                  value={formik.values.new_password}
                  autoComplete={"on"}
                  onClear={() => formik.setFieldValue("new_password", "")}
                />
              }
              message={
                formik.errors.new_password &&
                formik.touched.new_password && {
                  type: "ERROR",
                  content: formik.errors.new_password,
                }
              }
            />
          </div>
          <div className="w-full mb-[30px]">
            <FormField
              label={t("confirm_password")}
              field={
                <Input
                  type={"password"}
                  placeholder={t("confirm_password")}
                  sizes={isMobile ? "Small" : "Large"}
                  onChange={(e) =>
                    formik.setFieldValue("confirm_password", e.target.value)
                  }
                  onFocus={() =>
                    formik.setFieldTouched("confirm_password", true)
                  }
                  value={formik.values.confirm_password}
                  autoComplete={"on"}
                  onClear={() => formik.setFieldValue("confirm_password", "")}
                />
              }
              message={
                formik.errors.confirm_password &&
                formik.touched.confirm_password && {
                  type: "ERROR",
                  content: formik.errors.confirm_password,
                }
              }
            />
          </div>
          {formik.values.new_password && (
            <div className="w-full mb-5">
              <div className="w-full mb-[10px]">
                <CheckBox
                  checked={Boolean(formik.values.new_password)}
                  type={
                    formik.values.new_password.length >= 8 ? "SUCCESS" : "ERROR"
                  }
                  label={t("pass_8_hint")}
                />
              </div>
              <div className="w-full mb-[10px]">
                <CheckBox
                  checked={Boolean(formik.values.new_password)}
                  type={
                    containsLowerCase(formik.values.new_password) &&
                    containsUpperCase(formik.values.new_password)
                      ? "SUCCESS"
                      : "ERROR"
                  }
                  label={t("pass_contain_ch_hint")}
                />
              </div>
              <div className="w-full">
                <CheckBox
                  checked={Boolean(formik.values.new_password)}
                  type={
                    containsNumberAndCharacter(formik.values.new_password)
                      ? "SUCCESS"
                      : "ERROR"
                  }
                  label={t("pass_contain_num_hint")}
                />
              </div>
            </div>
          )}
        </div>
        <div className="w-full grid grid-cols-12 gap-3">
          <div className="col-span-6">
            <Button
              addonClasses="w-full"
              onClick={() => formik.submitForm()}
              loading={changePass.isLoading}
              disabled={!formik.isValid}
            >
              {t("save")}
            </Button>
          </div>
          <div className="col-span-6">
            <Button
              addonClasses="w-full"
              type="Secondary"
              onClick={() => props.setIsOpen(false)}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePassModal;
