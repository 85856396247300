import { AxiosCustomRequestConfig, http } from "../../services/Api";

interface keyable {
  [key: string]: any;
}

export const getAdwordsAccount = async ({
  params,
}: AxiosCustomRequestConfig) => {
  const response = http.get("/service/adwords/adwords-account/", {
    params,
    headers: {
      Accept: params.format ? "text/csv" : "application/json",
    },
  });
  const data = (await response).data;
  return data;
};

export const patchAdwordsAccount = async ({
  params,
  body,
}: AxiosCustomRequestConfig) => {
  const response = http.patch(
    `/service/adwords/adwords-account/${params.id}`,
    body
  );
  const data = (await response).data;
  return data;
};

export const adwordsCalculator = async ({ body }: AxiosCustomRequestConfig) => {
  const response = http.post("/service/adwords/calculator", body);
  const data = (await response).data;
  return data;
};

export const getSingleAdwordsAccount = async ({
  params,
}: AxiosCustomRequestConfig) => {
  const response = http.get(`/service/adwords/adwords-account/${params.id}/`);
  const data = (await response).data;
  return data;
};
