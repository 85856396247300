import { CloseCircle, Trash } from "iconsax-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Danger, Edit, InfoSquare, Plus } from "react-iconly";
import { ColorPalette, Helpers } from "src/utils";

import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ICartItemInterface, IPaymentDetail } from "./types";
import { UsaFlag } from "src/assets";
import {
  AggreementAlert,
  CartItemAction,
  CryptoPayment,
  EmptyCart,
  OfflinePayment,
} from "../partials";
import {
  Button,
  CheckBox,
  IconButton,
  RadioButton,
  Switch,
} from "src/components";
import { useMutation, useQuery } from "@tanstack/react-query";
import { OrderApi, PaymentApi, WalletApi } from "src/api";
import Skeleton from "react-loading-skeleton";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { ToastMsg } from "src/shared";
import { useNavigate } from "react-router-dom";
type Props = {};

const Desktop = (props: Props) => {
  const { i18n, t } = useTranslation("cart/ts");

  const navigate = useNavigate();

  const { data: checkoutInfo, refetch } = useQuery({
    queryKey: ["[Info] Checkout"],
    queryFn: () =>
      OrderApi.getCheckoutInfo().then((result) => {
        return result;
      }),
    cacheTime: 0,
  });

  const {
    data,
    isLoading: isQueryLoading,
    status,
  } = useQuery({
    queryKey: ["[List] Cart"],
    queryFn: () =>
      OrderApi.getCartItems().then((result) => {
        return result.results;
      }),
  });

  const payMutate = useMutation(PaymentApi.payWithWallet, {
    onSuccess: (res) => {
      toast(<ToastMsg type="success" message={t("paid_success")} />);
      navigate("/orders");
    },
    onError: (error: AxiosError) => {
      toast(<ToastMsg type="error" message={t("paid_error")} />);
    },
  });

  const result = React.useMemo(() => {
    if (!isQueryLoading && data && status === "success") {
      if (data.length > 0) {
        const { adwords_cart_item, ...rest } = data[0];
        return adwords_cart_item.map(
          (item: ICartItemInterface, index: number) => ({
            ...item,
            index,
          })
        );
      }
      return [];
    } else {
      return [];
    }
  }, [data]);

  const columnHelper = createColumnHelper<ICartItemInterface>();
  const tableColumns = [
    columnHelper.accessor("index", {
      cell: (row) => (
        <div
          className="rounded-lg flex items-center justify-center bg-grey-100 text-grey-700"
          style={{ width: "28px", height: 42 }}
        >
          {row.getValue() + 1}
        </div>
      ),

      enableSorting: false,
      header: () => (
        <div className="rounded-lg px-2 py-[10px] bg-grey-100 text-grey-700">
          #
        </div>
      ),
    }),
    columnHelper.accessor("currency", {
      header: () => <span>{t("currency_type")}</span>,
      cell: (row) => (
        <div className="flex items-center">
          <img
            src={row.getValue()?.icon}
            className="w-[30px] h-[30px]"
            style={{
              margin: i18n.dir() == "rtl" ? "0 0 0 8px" : "0 8px 0 0",
            }}
          />
          <span className="text-sm text-grey-700 font-semibold">
            {t(row.getValue()?.name)}
          </span>
        </div>
      ),
    }),
    columnHelper.accessor("amount", {
      header: () => <span>{t("amount")}</span>,
      cell: (row) => (
        <span className="text-sm text-grey-700 font-semibold">
          {row.getValue().toLocaleString()}
        </span>
      ),
    }),
    columnHelper.accessor("account", {
      header: () => <span>{t("account_number")}</span>,
      cell: (row) => (
        <span className="text-sm text-grey-700 font-semibold">
          {row.row.original.account ? row.row.original.account.number : ""}
        </span>
      ),
    }),
    columnHelper.accessor("account_type", {
      header: () => <span>{t("account_type")}</span>,
      cell: (row) => (
        <span className="text-sm text-grey-700 font-semibold">
          {row.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor("price", {
      header: () => <span>{t("price")}</span>,
      cell: (row) => (
        <div className="text-sm font-semibold text-center text-grey-700">
          {row.getValue().toLocaleString()}{" "}
          <strong className="text-sm text-center font-light text-grey-600">
            {t("toman")}
          </strong>
        </div>
      ),
    }),
    columnHelper.accessor("action", {
      header: () => <span></span>,
      cell: (row) => <CartItemAction row={row} />,
    }),
  ];

  const columns = React.useMemo(() => tableColumns.map((column) => column), []);

  const table = useReactTable({
    data: result,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  console.log(checkoutInfo);

  if (result.length == 0 && !isQueryLoading) {
    return <EmptyCart />;
  } else {
    return (
      <div className="w-full grid grid-cols-12 gap-4">
        {isQueryLoading ? (
          <div className="col-span-8">
            <Skeleton height={500} className="w-full" />
          </div>
        ) : (
          <div className="col-span-8">
            <AggreementAlert />
            <div className="w-full rounded-[20px] border border-grey-200 p-5 overflow-y-scroll no-scrollbar">
              <h4 className="text-md text-grey-800 mb-[30px]">
                {t("my_cart")}
              </h4>
              <table className="w-full">
                <thead className="pb-[14px] border-b border-b-grey-200">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <th
                            key={header.id}
                            className="p-3 text-sm text-grey-800 font-bold"
                            align="center"
                          >
                            <div className="w-full flex items-center">
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row, index) => {
                    return (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              className="text-sm p-3 pb-4 text-grey-700"
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {isQueryLoading || !checkoutInfo ? (
          <div className="col-span-4">
            <Skeleton height={350} className="w-full" />
          </div>
        ) : (
          <div className="col-span-4">
            <div className="w-full rounded-[20px] bg-grey-50 p-5">
              <h4 className="text-base text-grey-800 mb-5">
                {t("payment_method")}:
              </h4>
              <div className="flex items-center justify-between mb-[18px]">
                <h6 className="text-md text-grey-600">{t("wallet")}</h6>
                <Switch checked={true} onChange={() => {}} />
              </div>
              <div className="bg-white rounded-xl px-4 py-5 mb-[14px]">
                <h4 className="mb-[21px] text-base text-grey-800">
                  {t("my_wallet_credit")}
                </h4>
                {checkoutInfo.wallet.map((item: any, index: Number) => (
                  <div
                    className={`flex items-center justify-between ${
                      checkoutInfo.wallet.length - 1 != index ? "mb-[22px]" : ""
                    }`}
                  >
                    <span className="text-md text-grey-700">
                      {item.currency.name}
                    </span>
                    <span
                      className={`text-sm flex items-center gap-2 ${
                        item.balance == 0 ? "text-error-300" : "text-grey-600"
                      }`}
                    >
                      <strong
                        className={`text-base ${
                          item.balance == 0 ? "text-error-400" : "text-grey-800"
                        }`}
                      >
                        {item.balance.toLocaleString()}
                      </strong>
                      {t(item.currency.name)}
                    </span>
                  </div>
                ))}
              </div>
              {checkoutInfo && checkoutInfo.lack_of_credit.length > 0 && (
                <div className="mb-[30px] py-[26px] px-4 rounded-xl bg-error-50">
                  <div className="flex items-center justify-between mb-[14px]">
                    <div className="gap-[10px] flex">
                      <Danger
                        size={20}
                        primaryColor={ColorPalette.error["400"]}
                      />
                      <h4 className="text-md font-semibold text-error-400">
                        {t("insufficient_credit")}
                      </h4>
                    </div>
                    <IconButton
                      bgColor="bg-success-400"
                      bgColorHovered="hover:bg-success-700"
                      ringColor="focus:ring focus:ring-success-200"
                      icon={<Plus size={20} />}
                      size="Medium"
                      onClick={() => {
                        navigate("/wallet?increase=true");
                      }}
                    />
                  </div>
                  <p className="mb-5 text-grey-700 text-sm">
                    {t("if_you_want_pay")}
                  </p>
                  {checkoutInfo.lack_of_credit.map(
                    (item: any, index: number) => (
                      <div
                        className={`flex items-center justify-between ${
                          checkoutInfo.lack_of_credit.length - 1 != index
                            ? "mb-[22px]"
                            : ""
                        }`}
                      >
                        <span className="text-md text-grey-700">
                          {item.currency}
                        </span>
                        <span
                          className={`text-sm flex items-center gap-2 text-grey-600`}
                        >
                          <strong className={`text-base text-grey-800`}>
                            {item.amount.toLocaleString()}
                          </strong>
                          {t(item.currency)}
                        </span>
                      </div>
                    )
                  )}
                </div>
              )}
              <Button
                addonClasses="w-full mt-[30px]"
                disabled={!checkoutInfo.can_pay}
                loading={payMutate.isLoading}
                onClick={() => payMutate.mutate()}
              >
                {t("pay")}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default Desktop;
