import { useEffect, useState } from "react";

const parseZero = (value: number) => (value < 10 ? `0${value}` : `${value}`);

const getReturnValues = (countDown: number) => {
  if (!isNaN(countDown)) {
    const days = parseZero(Math.floor(countDown / (1000 * 60 * 60 * 24)));
    const hours = parseZero(
      Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    );
    const minutes = parseZero(
      Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
    );
    const seconds = parseZero(Math.floor((countDown % (1000 * 60)) / 1000));
    const total_seconds = parseZero(Math.floor(countDown / 1000));

    return { days, hours, minutes, seconds, total_seconds };
  } else {
    return {
      days: "00",
      hours: "00",
      minutes: "00",
      seconds: "00",
      total_seconds: "00",
    };
  }
};

const useCountdown = (
  targetDate: number,
  callback: () => void,
  enabled: boolean
) => {
  const [countDown, setCountDown] = useState(targetDate - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      if (enabled && targetDate) {
        if (targetDate - new Date().getTime() <= 0) {
          return callback();
        }

        setCountDown(targetDate - new Date().getTime());
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [targetDate]);

  return getReturnValues(countDown);
};

export default useCountdown;
