import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Calendar,
  Edit,
  Filter2,
  Paper,
  Plus,
  Search,
  Show,
  Upload,
} from "react-iconly";
import { Link, useSearchParams } from "react-router-dom";
import { Button, CheckBox, Pagination, Select, Table } from "src/components";
import { useMediaQuery, useSearchDebounce } from "src/hooks";
import { ColorPalette, Helpers } from "src/utils";
import { IPaymentsInterface } from "./types";
import { createColumnHelper } from "@tanstack/react-table";
import { PaymentApi } from "src/api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { DetailModal, EmptyPayment, FilterSec, Footer } from "./partials";

type Props = {};

enum PAYMENT_STATUS {
  SUCCESSFUL = "SUCCESSFUL",
  FAILED = "FAILED",
  PENDING = "PENDING",
}

const Payments = (props: Props) => {
  const { t, i18n } = useTranslation("payments/ts");

  const isMobile = useMediaQuery("(max-width: 1023px)");

  const [pageCount, setPageCount] = useState<number>(1);
  const [listDetail, setListDetail] = useState<Helpers.keyable>();
  const [detailModal, setDetailModal] = useState<Helpers.keyable>({
    show: false,
    data: undefined,
    stamp: new Date(),
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const getPaymentStatusT = (status: string) => {
    switch (status) {
      case PAYMENT_STATUS.SUCCESSFUL:
        return t("successful");
      case PAYMENT_STATUS.FAILED:
        return t("failed");
      case PAYMENT_STATUS.PENDING:
        return t("pending");

      default:
        break;
    }
  };

  const getPaymentStatusColor = (status: string) => {
    switch (status) {
      case PAYMENT_STATUS.SUCCESSFUL:
        return "success";
      case PAYMENT_STATUS.FAILED:
        return "error";
      case PAYMENT_STATUS.PENDING:
        return "primary";

      default:
        break;
    }
  };

  const columnHelper = createColumnHelper<IPaymentsInterface>();
  const columns = [
    columnHelper.accessor("index", {
      header: () => <span>#</span>,
      cell: ({ row }) => row.index + 1,
      enableSorting: false,
      size: 60,
    }),
    columnHelper.accessor("id", {
      header: () => <span>{t("payment_number")}</span>,
      cell: (row) => row.getValue(),
      enableSorting: false,
      meta: {
        mobile_header: t("payment_number"),
      },
    }),
    columnHelper.accessor("order", {
      header: () => <span>{t("order_id")}</span>,
      cell: (row) => (row.getValue() ? row.getValue().join(" | ") : ""),
      enableSorting: false,
      meta: {
        mobile_header: t("order_id"),
      },
    }),
    columnHelper.accessor("internal_tracking_code", {
      header: () => <span>{t("tracking_code")}</span>,
      cell: (row) => row.getValue(),
      enableSorting: false,
      meta: {
        mobile_header: t("tracking_code"),
      },
    }),
    columnHelper.accessor("type", {
      header: () => <span>{t("type")}</span>,
      cell: (row) => row.getValue(),
      enableSorting: false,
      meta: {
        mobile_header: t("type"),
      },
    }),
    columnHelper.accessor("amount", {
      header: () => <span>{t("total_price")}</span>,
      cell: (row) => row.getValue().toLocaleString(),
      enableSorting: true,
      sortingFn: () => 1,
      meta: {
        mobile_header: t("total_price"),
      },
    }),
    columnHelper.accessor("created_date", {
      header: () => <span>{t("date")}</span>,
      cell: (row) => row.getValue(),
      sortingFn: () => 1,
      meta: {
        mobile_header: t("created_date"),
      },
    }),
    columnHelper.accessor("status", {
      header: () => (
        <div className="w-full flex items-center justify-start">
          <span>{t('status')}</span>
        </div>
      ),
      meta: {
        mobile_header: t("status"),
      },
      cell: (row) => (
        <div className="flex items-center justify-between gap-6">
          <div
            className={`px-3 py-[5px] bg-${getPaymentStatusColor(
              row.getValue()
            )}-50 rounded-lg text-${getPaymentStatusColor(
              row.getValue()
            )}-500 text-xs text-center`}
          >
            {getPaymentStatusT(row.getValue())}
          </div>
          <div
            className="cursor-pointer"
            onClick={() =>
              setDetailModal({
                show: true,
                stamp: new Date(),
                data: row.row.original,
              })
            }
          >
            <Show primaryColor={ColorPalette.grey["700"]} size={24} />
          </div>
        </div>
      ),
      enableSorting: false,
      size: 200,
    }),
  ];

  const {
    data,
    isFetching: isQueryLoading,
    status,
    refetch,
  } = useQuery({
    queryKey: ["[List] Payment"],
    queryFn: () =>
      PaymentApi.getPaymentList({
        params: Object.fromEntries(searchParams),
      }).then((result) => {
        return result;
      }),
    cacheTime: 0,
  });

  const result = React.useMemo(() => {
    if (!isQueryLoading && data && status === "success") {
      const { results, ...rest } = data;

      const page_size = parseInt(searchParams.get("page_size")) || 10;
      setPageCount(Math.ceil(rest.count / page_size));

      setListDetail(rest);
      return results.map((item: IPaymentsInterface, index: number) => ({
        ...item,
        index,
      }));
    } else {
      return [];
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [searchParams]);

  if (result.length == 0 && !isQueryLoading && Array.from(searchParams).length == 0) {
    return <EmptyPayment />;
  } else
    return (
      <div className="w-full">
        <DetailModal
          isOpen={detailModal.show}
          setIsOpen={(state) =>
            setDetailModal({ ...detailModal, show: state, stamp: new Date() })
          }
          data={detailModal.data}
        />
        <FilterSec refetch={refetch} />
        <div className="mb-[34px]">
          <Table
            data={result}
            columns={columns}
            title={t("payment_list")}
            Icon={Paper}
            headerColor={"grey-100"}
            bordered={false}
            loading={isQueryLoading}
            onSort={(key) => {
              const sorted = searchParams.get("ordering") || "-";
              if (sorted.includes("-")) {
                searchParams.set("ordering", key);
              } else {
                searchParams.set("ordering", "-" + key);
              }
              searchParams.set("page", "1");
              setSearchParams(searchParams);
            }}
          />
        </div>
        {listDetail ? (
          pageCount > 1 && (
            <Footer pageCount={pageCount || 1} count={listDetail.count} />
          )
        ) : (
          <Skeleton height={54} />
        )}
      </div>
    );
};

export default Payments;
