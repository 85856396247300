import React from "react";
import { useTranslation } from "react-i18next";
import { Discount } from "react-iconly";
import { ColorPalette } from "src/utils";
import { LevelCard } from "./partials";

type Props = {};

const DiscountLevel = (props: Props) => {
  const { i18n, t } = useTranslation("discount/ts");

  return (
    <div className="w-full">
      <div
        className="px-5 py-2 bg-primary-50 flex justify-between items-center mb-5"
        style={{ borderRadius: 100 }}
      >
        <div className="flex items-center">
          <Discount
            size={20}
            style={{
              margin: i18n.dir() == "rtl" ? "0 0 0 12px" : "0 12px 0 0",
            }}
            primaryColor={ColorPalette.grey["700"]}
          />
          <span
            className={`${
              i18n.dir() == "rtl" ? "ml-3" : "mr-3"
            } text-base text-grey-700`}
          >
            {t("discount_level")}:
          </span>
        </div>
        <span className="text-primary-500 text-base">
          <strong className="font-bold text-base">02</strong> / 10
        </span>
      </div>
      {[1, 2, 3, 4].map((item) => (
        <LevelCard type={item == 1 ? "Success" : "Simple"} />
      ))}
    </div>
  );
};

export default DiscountLevel;
