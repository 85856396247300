import React from "react";
import { Outlet } from "react-router-dom";
import { Header, SideBar } from "./partials";

type Props = {};

const MainLayout = (props: Props) => {
  return (
    <main className="w-full h-full grid grid-cols-12">
      <SideBar />
      <div className="col-span-12 h-full lg:col-span-10 flex flex-col overflow-y-scroll no-scrollbar">
        <Header />
        <div className="px-4 lg:px-[30px] pt-5 safari-only overflow-x-hidden flex-1">
          <Outlet />
        </div>
      </div>
    </main>
  );
};

export default MainLayout;
