import { Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker2";
import { useTranslation } from "react-i18next";
import { Calendar, Filter2, Plus, Search, Upload } from "react-iconly";
import { Link, useSearchParams } from "react-router-dom";
import { CoreApi, ServiceApi } from "src/api";
import { HideFilter } from "src/assets";
import { Button, CheckBox, IconButton } from "src/components";
import { useMediaQuery, useSearchDebounce } from "src/hooks";
import { useAuthStore, useCommonStore } from "src/store";
import { ColorPalette, Helpers } from "src/utils";
import moment from "moment-jalaali";

type Props = {
  refetch: any;
};

enum ACCOUNT_TYPE {
  CREATED = "NEW",
  TRANSFERRED = "TRANSFERRED",
}

enum ACCOUNT_STATUS {
  ACTIVE = "ACTIVE",
  DEACTIVE = "DEACTIVE",
}

const FilterSec = (props: Props) => {
  const { t, i18n } = useTranslation("accounts/ts");

  const isMobile = useMediaQuery("(max-width: 1023px)");

  const [searchParams, setSearchParams] = useSearchParams();
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [csvLoading, setCsvLoading] = useState(false);

  const { setNewOrderModal } = useCommonStore();
  const { access } = useAuthStore();

  const [debouncedSearch, setDebouncedSearch] = useSearchDebounce({
    delay: 350,
    dtext: searchParams.get("search") || "",
  });

  const { data: currencies } = useQuery({
    queryKey: ["[Currency List] Orders"],
    queryFn: () =>
      CoreApi.getCurrencyList().then((result) => {
        return result.results;
      }),
    initialData: [],
    cacheTime: 0,
  });

  useEffect(() => {
    if (debouncedSearch.length > 0) {
      searchParams.set("search", debouncedSearch);
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }
  }, [debouncedSearch]);

  return (
    <div className="w-full">
      <div className="w-full flex items-center justify-between flex-wrap mb-[30px]">
        <div className="flex items-center gap-3">
          <IconButton
            key={"btn-export"}
            bgColor="bg-success-500"
            bgColorHovered="hover:bg-success-700"
            ringColor="focus:ring focus:ring-success-200"
            icon={<Upload />}
            size="Large"
            loading={csvLoading}
            onClick={() => {
              const params = {
                ...Object.fromEntries(searchParams),
                format: "csv",
              };
              setCsvLoading(true);
              fetch(
                `https://api.adsition.com/service/adwords/adwords-account?${new URLSearchParams(
                  params
                ).toString()}`,
                {
                  headers: {
                    Authorization: "Bearer " + access,
                  },
                }
              )
                .then((result) => result.blob())
                .then((data) => {
                  window.open(URL.createObjectURL(data), "_blank");
                })
                .finally(() => {
                  setCsvLoading(false);
                });
            }}
          />
          <IconButton
            key={"btn-filter"}
            bgColor="bg-secondary-400"
            bgColorHovered="hover:bg-secondary-700"
            ringColor="focus:ring focus:ring-secondary-200"
            icon={<img src={HideFilter} />}
            size="Large"
            onClick={() => setFilterOpen((prev) => !prev)}
          />
          <div className="hidden py-2 pr-2 px-[14px] rounded-lg border border-grey-200 gap-3 lg:flex items-center">
            <input
              placeholder={t("search")}
              className="outline-none"
              defaultValue={searchParams.get("search")}
              onChange={(e) => {
                setDebouncedSearch(e.target.value);
                if (e.target.value.length == 0) {
                  searchParams.delete("search");
                  setSearchParams(searchParams);
                }
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  props.refetch();
                }
              }}
            />
            <IconButton
              bgColor="bg-primary-500"
              bgColorHovered="hover:bg-primary-700"
              ringColor="focus:ring focus:ring-primary-200"
              icon={<Search size={16} primaryColor={ColorPalette.grey["50"]} />}
              size="Small"
              onClick={() => props.refetch()}
            />
          </div>
        </div>
        <div className="flex items-center gap-3"></div>
      </div>
      <Transition
        enter="transition-all ease-in-out duration-500"
        enterFrom="opacity-0 translate-y-6"
        enterTo="opacity-100 translate-y-0"
        leave="transition-all ease-in-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        show={filterOpen}
      >
        <div className="py-[26px] px-5 mb-[30px] flex items-center  justify-between flex-wrap border border-grey-200 rounded-[20px]">
          <div className="flex flex-wrap items-center gap-2">
            <span className="text-sm text-grey-800 font-semibold">
              {t("currency")}:
            </span>
            {currencies.map((item: Helpers.keyable) => (
              <CheckBox
                label={t(item?.name)}
                checked={searchParams.get("currency") == item.id}
                onChange={(e) => {
                  if (e.target.checked) {
                    searchParams.set("currency", item.id);
                    searchParams.set("page", "1");
                    setSearchParams(searchParams);
                  }
                }}
              />
            ))}
          </div>
          <div className="mt-3 lg:mt-0 flex flex-wrap items-center gap-2">
            <span className="text-sm text-grey-800 font-semibold">
              {t("status")}:
            </span>
            <CheckBox
              label={t("active")}
              checked={searchParams.get("status") == ACCOUNT_STATUS.ACTIVE}
              onChange={(e) => {
                if (e.target.checked) {
                  searchParams.set("status", ACCOUNT_STATUS.ACTIVE);
                  searchParams.set("page", "1");
                  setSearchParams(searchParams);
                }
              }}
            />
            <CheckBox
              label={t("deactive")}
              checked={searchParams.get("status") == ACCOUNT_STATUS.DEACTIVE}
              onChange={(e) => {
                if (e.target.checked) {
                  searchParams.set("status", ACCOUNT_STATUS.DEACTIVE);
                  searchParams.set("page", "1");
                  setSearchParams(searchParams);
                }
              }}
            />
          </div>
          <div className="mt-3 lg:mt-0 flex flex-wrap items-center gap-2">
            <span className="text-sm text-grey-800 font-semibold">
              {t("type")}:
            </span>
            <CheckBox
              label={t("created")}
              checked={searchParams.get("type") == ACCOUNT_TYPE.CREATED}
              onChange={(e) => {
                if (e.target.checked) {
                  searchParams.set("type", ACCOUNT_TYPE.CREATED);
                  searchParams.set("page", "1");
                  setSearchParams(searchParams);
                }
              }}
            />
            <CheckBox
              label={t("transferred")}
              checked={searchParams.get("type") == ACCOUNT_TYPE.TRANSFERRED}
              onChange={(e) => {
                if (e.target.checked) {
                  searchParams.set("type", ACCOUNT_TYPE.TRANSFERRED);
                  searchParams.set("page", "1");
                  setSearchParams(searchParams);
                }
              }}
            />
          </div>
          <div
            className="flex justify-center mt-4 lg:mt-0 cursor-pointer"
            onClick={() => setSearchParams()}
          >
            <span className="text-xs text-error-400">
              {t("remove_filters")}
            </span>
          </div>
        </div>
      </Transition>
      <div className="flex py-2 px-[14px] rounded-lg border border-grey-200 gap-3 lg:hidden items-center mb-[30px]">
        <input
          placeholder={t("search")}
          className="flex-1 outline-none"
          defaultValue={searchParams.get("search")}
          onChange={(e) => setDebouncedSearch(e.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              props.refetch();
            }
          }}
        />
        <IconButton
          bgColor="bg-primary-500"
          bgColorHovered="hover:bg-primary-700"
          ringColor="focus:ring focus:ring-primary-200"
          icon={<Search size={16} primaryColor={ColorPalette.grey["50"]} />}
          size="Small"
          onClick={() => props.refetch()}
        />
      </div>
    </div>
  );
};

export default FilterSec;
