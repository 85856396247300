import React, { Fragment } from "react";
import { useQuery } from "@tanstack/react-query";

import { useAuthStore } from "src/store";
import { AuthApi } from "src/api";
import { Splash } from "src/components";

type Props = {
  children?: React.ReactNode;
};

const AuthProvider = (props: Props) => {
  const { isAuthenticated, setUser } = useAuthStore();

  const profileQuery = useQuery({
    queryKey: ["[User] Profile"],
    queryFn: () =>
      AuthApi.getMyPorfile().then((result) => {
        setUser(result);
        return result;
      }),
    enabled: isAuthenticated,
    cacheTime: 0,
  });

  return <Fragment>{props.children}</Fragment>;
};

export default AuthProvider;
