import React, { lazy, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import MainLayout from "src/layout";
import { useAuthStore } from "src/store";

import Auth from "./Auth";
import CallBack from "./CallBack";
import Dashboard from "./Dashboard";
import DiscountLevel from "./DiscountLevel";
import OrdersPage from "./Orders";
import PaymentsPage from "./Payments";
import Profile from "./Profile";
import ShoppingCart from "./ShoppingCart";
import TicketsPage from "./Tickets";
import AccountsPage from "./Accounts";
import WalletPage from "./Wallet";
import SettingPage from './Setting'
import PaymentIds from "./PaymentIds";

function BasePage() {
  const { isAuthenticated } = useAuthStore();
  
  return (
    <Routes>
      {isAuthenticated ? (
        <>
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route path="/callback" element={<CallBack />} />
          <Route element={<MainLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/cart" element={<ShoppingCart />} />
            <Route path="/profile/*" element={<Profile />} />
            <Route path="/discount-level" element={<DiscountLevel />} />
            <Route path="/orders/*" element={<OrdersPage />} />
            <Route path="/tickets/*" element={<TicketsPage />} />
            <Route path="/payments/*" element={<PaymentsPage />} />
            <Route path="/accounts/*" element={<AccountsPage />} />
            <Route path="/wallet/*" element={<WalletPage />} />
            <Route path="/setting" element={<SettingPage />} />
            <Route path="/payment-ids/*" element={<PaymentIds />} />
          </Route>
        </>
      ) : (
        <>
          <Route path="/" element={<Navigate to="/auth" replace />} />
          <Route path="/auth/*" element={<Auth />} />
        </>
      )}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default BasePage;
