import { AxiosCustomRequestConfig, http } from "../../services/Api";

interface keyable {
  [key: string]: any;
}

export const getCurrencyList = async () => {
  const response = http.get("/core/currency/");
  const data = (await response).data;
  return data;
};

export const getCurrencyRateList = async () => {
  const response = http.get("/core/currency-rate/");
  const data = (await response).data;
  return data;
};