import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { ForgotForm, LoginForm, LoginOtpForm, RegisterForm } from "../Forms";

import { ForgotMobileBg, LoginMobileBg } from "src/assets";
import { Header } from "./partials";

type Props = {};

const Mobile = (props: Props) => {
  const { pathname } = useLocation();

  return (
    <main className="flex flex-col w-full h-full overflow-y-scroll pt-20">
      <Header />
      <img
        className="mb-5"
        src={pathname.includes("forgot") ? ForgotMobileBg : LoginMobileBg}
        style={{ width: "100%", height: "27%" }}
      />
      <div className="flex-1 pb-[30px] px-4">
        <Routes>
          <Route path="/" element={<Navigate to="/auth/login" replace />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/login-otp" element={<LoginOtpForm />} />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/forgot" element={<ForgotForm />} />
        </Routes>
      </div>
    </main>
  );
};

export default Mobile;
