import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Buy, Plus } from "react-iconly";
import { Button, CheckBox, Input, Modal } from "src/components";
import { useMediaQuery } from "src/hooks";
import { FormField, ToastMsg } from "src/shared";
import { ColorPalette } from "src/utils";
import { Step1, Step2 } from "./partials";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { AuthApi } from "src/api";
import { IFormValues } from "./types";
import { useAuthStore } from "src/store";
import { serialize } from "object-to-formdata";

type Props = {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
};

const initialValues: IFormValues = {
  email: undefined,
  first_name: undefined,
  last_name: undefined,
  national_code: undefined,
  otp: "",
};

const CompleteProfileModal = (props: Props) => {
  const { t, i18n } = useTranslation("dashboard/ts");
  const { t: validationTranslations } = useTranslation("validation/ts");

  const { user } = useAuthStore();

  const [step, setStep] = useState<number>(1);

  const validationSchema1 = Yup.object({
    first_name: Yup.string()
      .min(1, validationTranslations("field_required"))
      .required(validationTranslations("field_required")),
    last_name: Yup.string()
      .min(1, validationTranslations("field_required"))
      .required(validationTranslations("field_required")),
    email: Yup.string()
      .email(validationTranslations("email"))
      .required(validationTranslations("field_required")),
    national_code: Yup.string().required(
      validationTranslations("field_required")
    ),
  });

  const validationSchema2 = Yup.object({
    otp: Yup.string()
      .length(5)
      .required(validationTranslations("field_required")),
  });

  const verifyEmail = useMutation(AuthApi.verifyEmail, {
    onSuccess: (res: AxiosResponse) => {
      toast(<ToastMsg type="success" message={t("alert_profile_completed")} />);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    },
    onError: (error: AxiosError) => {
      if (error.response.status == 400) {
        toast(<ToastMsg type="error" message={t("otp_error")} />);
      }
    },
  });

  const changeEmail = useMutation(AuthApi.changeEmail, {
    onSuccess: (res: AxiosResponse) => {
      setStep(2);
    },
    onError: (error: AxiosError) => {
      if (error.response.status == 400) {
        toast(<ToastMsg type="error" message={t("alert_email_wrong")} />);
      }
    },
  });

  const updateProfile = useMutation(AuthApi.patchProfile, {
    onSuccess: (res) => {},
    onError: (error: AxiosError) => {
      if (error.response.status == 400) {
        toast(<ToastMsg type="error" message={t("data_store_error")} />);
      }
    },
  });

  const resnedOtp = async () => {
    try {
      await changeEmail.mutateAsync({ body: { email: formik.values.email } });
      toast(<ToastMsg type="success" message={t("alert_otp_sent_success")} />);
    } catch (error) {}
  };

  const handleStep1 = async (values: IFormValues) => {
    try {
      await updateProfile.mutateAsync({
        params: { id: user.id },
        body: serialize(values),
      });
      if (user.email_verification) {
        toast(
          <ToastMsg type="success" message={t("alert_profile_completed")} />
        );
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        await changeEmail.mutateAsync({
          body: {
            email: values.email,
          },
        });
      }
    } catch (err) {}
  };

  const formik = useFormik({
    initialValues,
    validationSchema: step == 1 ? validationSchema1 : validationSchema2,
    onSubmit: (values) =>
      step == 1
        ? handleStep1(values)
        : verifyEmail.mutate({
            body: {
              otp: values.otp,
            },
          }),
    validateOnChange: true,
    validateOnMount: true,
  });

  useEffect(() => {
    formik.setValues({
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      national_code: user.national_code,
      otp: "",
    });
  }, [user]);

  return (
    <Modal
      title={t("complete_profile")}
      isOpen={props.isOpen}
      onClose={() => props.setIsOpen(false)}
    >
      <div className="w-full h-full flex flex-col justify-between">
        {step == 1 ? (
          <Step1 formik={formik} />
        ) : (
          <Step2
            formik={formik}
            step={step}
            backStep={() => setStep(1)}
            resendOtp={resnedOtp}
            resendLoading={changeEmail.isLoading}
          />
        )}

        <div className="flex items-center">
          <div className={`flex-1 gap-3 flex items-center flex-row-reverse`}>
            <Button
              addonClasses="w-full"
              icon={step == 1 && <Plus size={24} />}
              onClick={() => formik.submitForm()}
              loading={
                updateProfile.isLoading ||
                changeEmail.isLoading ||
                verifyEmail.isLoading
              }
            >
              {t("next_step")}
            </Button>
            <a
              href="#"
              className={`w-[42px] h-[42px] flex justify-center items-center p-[8px] border border-primary-300 border-dashed rounded-[100px]`}
            >
              <span className="text-[10px] text-grey-600">
                <strong className="text-sm text-grey-800">{step}</strong>/2
              </span>
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CompleteProfileModal;
