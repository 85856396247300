import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { LogoEn, LogoFa } from "src/assets";
import { Button } from "src/components";

type Props = {};

const Header = (props: Props) => {
  const { t, i18n } = useTranslation("auth/ts");

  const navigate = useNavigate();

  const { pathname } = useLocation();

  return (
    <div className="bg-white pt-5 pb-[14px] flex justify-between border-b border-b-grey-200">
      <img src={i18n.language == "en" ? LogoEn : LogoFa} />
      <div className="flex items-center">
        <span
          className={`text-[14px] text-grey-700 ${
            i18n.dir() == "rtl" ? "ml-5" : "mr-5"
          }`}
        >
          {pathname.includes("login")
            ? t("dont_have_account")
            : t("already_account")}
        </span>
        <Button
          addonClasses="!w-[120px]"
          onClick={() =>
            pathname.includes("login")
              ? navigate("register")
              : navigate("login")
          }
        >
          {pathname.includes("login") ? t("signUp") : t("signIn")}
        </Button>
      </div>
    </div>
  );
};

export default Header;
