import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  bgColor: string;
  textColor: string;
  name: string;
  title: string;
  count: string;
  icon: React.ReactNode;
};

const DashboardCard = ({
  bgColor,
  textColor,
  count,
  name,
  title,
  icon,
}: Props) => {
  const { i18n, t } = useTranslation();

  return (
    <div className={`py-4 lg:py-5 px-[14px] lg:px-4 rounded-[20px] ${bgColor}`}>
      <div className="w-full flex justify-between items-center mb-5 lg:mb-7">
        <div className="flex items-center">
          <span
            className={`text-base lg:text-xl font-bold ${textColor} ${
              i18n.dir() == "rtl" ? "ml-2" : "mr-2"
            }`}
          >
            {count}
          </span>
          <span className={`text-xs lg:text-xl ${textColor}`}>{title}</span>
        </div>
        {icon}
      </div>
      <h4 className="text-xs lg:text-base text-grey-700">{name}</h4>
    </div>
  );
};

export default DashboardCard;
