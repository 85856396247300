import { CloseCircle } from "iconsax-react";
import React, { useState } from "react";
import { CloseSvg } from "src/assets";

interface ITextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  children?: React.ReactNode;
  status?: "success" | "error";
  sizes?: "Large" | "Small";
  icon?: React.ReactNode;
  value?: string;
  disabled?: boolean;
}

const Classes = (list: any) => {
  return list.filter(Boolean).join(" ");
};

const TextArea = (props: ITextAreaProps) => {
  const [focused, setFocused] = useState<Boolean>(false);

  const getDefaultStyles = () => {
    return `border-grey-200 rounded-lg flex items-center border [&>svg]:text-grey-700 transition-all duration-300 ${
      props.disabled ? "bg-grey-200 [&>svg]:text-grey-500" : ""
    } ${focused ? "bg-primary-50 !border-primary-500" : ""}`;
  };

  const getStatusStyles = () => {
    switch (props.status) {
      case "success":
        return `!border-success-500 bg-success-50 [&>textarea]:text-grey-700`;

      case "error":
        return `!border-error-500 bg-error-50 [&>textarea]:text-grey-700`;

      default:
        return `[&>textarea]:text-grey-700`;
    }
  };

  const getSizeStyles = () => {
    switch (props.sizes) {
      case "Large":
        return `p-[14px]`;

      case "Small":
        return `px-[14px] pt-3 pb-[11.28px] `;

      default:
        return `p-[14px]`;
    }
  };

  return (
    <div
      className={Classes([
        props.status ? getStatusStyles() : "",
        getDefaultStyles(),
        getSizeStyles(),
      ])}
    >
      <div
        className={`ml-2 [&>svg]:${
          props.disabled ? "text-grey-500" : "text-grey-700"
        }`}
      >
        {props.icon}
      </div>
      <textarea
        {...props}
        className={`bg-transparent border-0 flex-grow align-middle focus:outline-none placeholder-grey-500 ${
          props.disabled ? "text-grey-500" : "text-grey-800"
        }`}
        style={{ maxWidth: "100%" }}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        rows={4}
      />
      {props.value && <img src={CloseSvg} />}
    </div>
  );
};

export default TextArea;
