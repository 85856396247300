import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FailedPaymentSvg, SuccessfulPaymentSvg } from "src/assets";
import { Button } from "src/components";

type Props = {};

const CallBack = (props: Props) => {
  const { t } = useTranslation("callback/ts");

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div
        style={{ maxWidth: 640 }}
        className="flex flex-col items-center px-4 lg:p-0"
      >
        {searchParams.get("type") == "error" ? (
          <>
            <img
              className="w-full mb-[50px]"
              style={{ maxWidth: 704 }}
              src={FailedPaymentSvg}
            />
            <p className="text-xl text-grey-800 mb-2 font-semibold">
              {t("failed_title")}
            </p>
            <p className="text-base text-grey-700 mb-[30px]">
              {t("failed_desc")}
            </p>
            <div className="w-full grid grid-cols-12 gap-6">
              <div className="col-span-6">
                <Button addonClasses="w-full">{t("try_again")}</Button>
              </div>
              <div className="col-span-6">
                <Button addonClasses="w-full" type="Secondary">
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <img
              className="w-full mb-[50px]"
              style={{ maxWidth: 704 }}
              src={SuccessfulPaymentSvg}
            />
            <p className="text-xl text-grey-800 mb-2 font-semibold">
              {t("success_title")}
            </p>
            <p className="text-base text-grey-700 mb-5">{t("success_desc")}</p>
            <div className="w-full flex justify-between items-center mb-[30px]">
              <span className="text-sm text-grey-700">
                {t("tracking_code")}
              </span>
              <span className="text-sm text-grey-800 font-semibold">
                {searchParams.get("tracking_code")}
              </span>
            </div>
            <Button
              addonClasses="w-full"
              onClick={() =>
                navigate(`/orders`)
              }
            >
              {t("order_detail")}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default CallBack;
