import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import Root from "./screens/Root";
import Single from "./screens/Single";

const OrdersPage = () => {
  return (
    <Routes>
      <Route path="/" element={<Root />} />
      <Route path="/single/:id" element={<Single />} />
    </Routes>
  );
};

export default OrdersPage;
