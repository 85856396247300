import { Card } from "iconsax-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Buy, Paper, Plus } from "react-iconly";
import i18n from "src/i18n/i18n";
import { useAuthStore, useCommonStore } from "src/store";
import { ColorPalette } from "src/utils";
import {
  Articles,
  CompleteProfile,
  DashboardCard,
  ExchangeCalculator,
  ExchangeRate,
  OrdersTable,
  RecentTickets,
} from "./partials";
import { useQuery } from "@tanstack/react-query";
import { OrderApi } from "src/api";

const Dashboard = () => {
  const { t } = useTranslation("dashboard/ts");

  const { user } = useAuthStore();
  const { setNewOrderModal } = useCommonStore();

  const { data } = useQuery({
    queryKey: ["[KPI] Dashboard"],
    queryFn: () =>
      OrderApi.getDashboardKpi().then((result) => {
        return result;
      }),
  });

  const shouldShowCompleteProfile = () => {
    if (user) {
      const flag =
        !user.first_name ||
        user?.first_name.length == 0 ||
        !user.last_name ||
        user?.last_name.length == 0 ||
        !user.email ||
        !user.national_code ||
        !user.email_verification;
      return flag;
    } else {
      return false;
    }
  };

  return (
    <div className="w-full">
      {shouldShowCompleteProfile() && <CompleteProfile />}
      <section className="w-full grid grid-cols-12 gap-4 mb-[30px] lg:mb-5">
        <div className="col-span-6 lg:col-span-3">
          <DashboardCard
            bgColor="bg-primary-50"
            textColor="text-primary-500"
            name={t("cart_items")}
            title={t("item")}
            count={data ? data.cart_items : "0"}
            icon={<Buy size={24} primaryColor={ColorPalette.primary["500"]} />}
          />
        </div>
        <div className="col-span-6 lg:col-span-3">
          <DashboardCard
            bgColor="bg-secondary-50"
            textColor="text-secondary-500"
            name={t("new_order")}
            title={t("orders")}
            count={data ? data.new_order : "0"}
            icon={
              <Paper size={24} primaryColor={ColorPalette.secondary["500"]} />
            }
          />
        </div>
        <div className="col-span-6 lg:col-span-3">
          <DashboardCard
            bgColor="bg-success-50"
            textColor="text-success-500"
            name={t("total_payment")}
            title={""}
            count={data ? data.total_payments : "0"}
            icon={<Card size={24} color={ColorPalette.success["500"]} />}
          />
        </div>
        <div className="col-span-6 lg:col-span-3">
          <div
            className="w-full h-full flex items-center justify-center p-[30px] lg:p-[37px] border border-dashed border-primary-500 rounded-[20px] cursor-pointer"
            onClick={() => setNewOrderModal(true)}
          >
            <div className="flex items-center">
              <Plus size={20} primaryColor={ColorPalette.primary["500"]} />
              <span
                className={`relative text-sm text-primary-500 ${
                  i18n.dir() == "rtl" ? "mr-2" : "ml-2"
                }`}
              >
                {t("new_order")}
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full grid grid-cols-12 gap-4 mb-[30px]">
        <div className="col-span-12 lg:col-span-6">
          <ExchangeRate />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <ExchangeCalculator />
        </div>
      </section>
      <section className="w-full grid grid-cols-12 gap-[30px] lg:gap-4 mb-[30px]">
        <div className="col-span-12">
          <OrdersTable />
        </div>
        {/* <div className="col-span-12 lg:col-span-5">
          <RecentTickets />
        </div> */}
      </section>
      {/* <section className="w-full">
        <Articles />
      </section> */}
    </div>
  );
};

export default Dashboard;
