import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { ColorPalette, Helpers } from "src/utils";
import { useMediaQuery } from "src/hooks";
import { PaymentApi } from "src/api";
import { toast } from "react-toastify";
import { FormField, ToastMsg } from "src/shared";
import { AxiosError } from "axios";
import { serialize } from "object-to-formdata";
import { ChevronDown, ChevronUp, Plus } from "react-iconly";
import { Collapse } from "react-collapse";
import { Button, IconButton, Input } from "src/components";
import { IFormValues } from "./types";
import { Clipboard } from "iconsax-react";
import { CopySvg } from "src/assets";

type Props = {
  data: Helpers.keyable;
  amount: number;
  onFinish: () => void;
};

const initialValues: IFormValues = {
  image: undefined,
  tracking_code: undefined,
  amount: undefined,
};

const ShebaSatna = (props: Props) => {
  const { t, i18n } = useTranslation("wallet/ts");
  const { t: validationTranslations } = useTranslation("validation/ts");

  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const bankInfo = Helpers.DetectCreditBank(
    props.data.card_number.length > 6
      ? props?.data?.card_number.replaceAll("-", "")
      : ""
  );

  const isMobile = useMediaQuery("(max-width: 1023px)");

  const validationSchema = Yup.object({
    tracking_code: Yup.string()
      .max(128)
      .required(validationTranslations("field_required")),
    image: Yup.mixed().required(validationTranslations("field_required")),
  });

  const createPaymentMutuate = useMutation(PaymentApi.createOfflinePayment, {
    onSuccess: (res) => {
      toast(<ToastMsg type="success" message={t("success_payment")} />);
      queryClient.invalidateQueries({
        queryKey: ["[List] Wallet Transcation"],
      });
      props.onFinish();
    },
    onError: (error: AxiosError) => {
      if (error.response.status == 400) {
        toast(<ToastMsg type="error" message={t("error_payment")} />);
      }
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) =>
      createPaymentMutuate.mutate({
        body: serialize({ ...values, amount: props.amount }),
      }),
    validateOnChange: true,
    validateOnMount: true,
  });

  return (
    <div className="flex-1 flex flex-col justify-between">
      <div className="w-full">
        <input
          type={"file"}
          accept="image/png, image/jpeg, image/jpg"
          className="hidden"
          id="payment-img-inp"
          multiple={false}
          onChange={(e) => {
            if (
              ["image/png", "image/jpeg", " image/jpg"].includes(
                e.target.files[0].type
              )
            ) {
              formik.setFieldValue("image", e.target.files[0]);
            } else {
              formik.setFieldError(
                "image",
                validationTranslations("valid_pic")
              );
            }
          }}
        />
        <div className="px-4 py-5 rounded-[20px] bg-grey-50 mb-5">
          <div
            className="flex justify-between items-center"
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <h4 className="text-base font-semibold text-grey-800">
              {t("payment_detail")}:
            </h4>
            {isOpen ? (
              <ChevronUp size={20} primaryColor={ColorPalette.grey["700"]} />
            ) : (
              <ChevronDown size={20} primaryColor={ColorPalette.grey["700"]} />
            )}
          </div>
          {bankInfo && (
            <Collapse isOpened={isOpen}>
              <div className="flex justify-between items-center mb-[14px] pb-[14px] border-b border-b-grey-200 mt-5">
                <h6 className="text text-grey-700">{t("sheba_number")}</h6>
                <div className="flex gap-3 text-grey-800 font-semibold">
                  {props?.data?.iban}
                  <img
                    src={CopySvg}
                    onClick={() => {
                      navigator.clipboard.writeText(props?.data?.iban);
                      toast(<ToastMsg type="success" message={t("copied")} />);
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-between items-center mb-[14px] pb-[14px] border-b border-b-grey-200">
                <h6 className="text text-grey-700">{t("card_number")}</h6>
                <div className="flex gap-3 text-grey-800 font-semibold">
                  {props?.data?.card_number}
                  <img
                    src={CopySvg}
                    onClick={() => {
                      navigator.clipboard.writeText(props?.data?.card_number);
                      toast(<ToastMsg type="success" message={t("copied")} />);
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-between items-center mb-[14px] pb-[14px] border-b border-b-grey-200">
                <h6 className="text text-grey-700">{t("account_owner")}</h6>
                <span className="text-grey-800 font-semibold">
                  {props?.data?.account_owner}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <h6 className="text text-grey-700">{t("bank_name")}</h6>
                <span className="text-grey-800 font-semibold flex items-center gap-2">
                  {t(props.data.bank_name)}
                </span>
              </div>
            </Collapse>
          )}
        </div>
        <h4 className="text-base font-semibold text-grey-800 mb-5">
          {t("pay_sheba_or_satna")}:
        </h4>
        <div className="w-full mb-[14px]">
          <FormField
            label={t("tracking_code")}
            field={
              <Input
                name="tracking_code"
                type={"tracking_code"}
                placeholder={t("enter_tracking_code")}
                sizes={isMobile ? "Small" : "Large"}
                onChange={(e) =>
                  formik.setFieldValue("tracking_code", e.target.value)
                }
                onFocus={() => formik.setFieldTouched("tracking_code", true)}
                value={formik.values.tracking_code}
                autoComplete={"on"}
                onClear={() => formik.setFieldValue("tracking_code", "")}
              />
            }
            message={
              formik.errors.tracking_code &&
              formik.touched.tracking_code && {
                type: "ERROR",
                content: formik.errors.tracking_code,
              }
            }
          />
        </div>
        <div className="gap-3 flex items-start mb-[30px]">
          <div className="flex-1">
            <FormField
              label={""}
              field={
                <Input
                  name="image"
                  type={"text"}
                  placeholder={t("enter_image")}
                  sizes={isMobile ? "Small" : "Large"}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "image",
                      formik.values.image ? formik.values.image.name : ""
                    )
                  }
                  readOnly={true}
                  onFocus={() => formik.setFieldTouched("image", true)}
                  value={formik.values.image ? formik.values.image.name : null}
                />
              }
              message={
                formik.errors.image &&
                formik.touched.image && {
                  type: "ERROR",
                  content: formik.errors.image,
                }
              }
            />
          </div>
          <div className="mt-2">
            <IconButton
              bgColor="bg-success-500"
              bgColorHovered="hover:bg-success-700"
              ringColor="focus:ring focus:ring-success-200"
              icon={<Plus size={24} primaryColor={ColorPalette.grey["50"]} />}
              size={isMobile ? "Medium" : "Large"}
              onClick={() => {
                let inp: HTMLElement = document.querySelector(
                  "#payment-img-inp"
                ) as HTMLElement;
                inp.click();
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex-1 gap-3 flex items-center">
          <a
            href="#"
            className={`w-[42px] h-[42px] flex justify-center items-center p-[8px] border border-primary-300 border-dashed rounded-[100px]`}
          >
            <span className="text-[10px] text-grey-600">
              <strong className="text-sm text-grey-800">2</strong>/2
            </span>
          </a>
          <Button
            addonClasses="w-full"
            onClick={() => formik.submitForm()}
            loading={createPaymentMutuate.isLoading}
            disabled={!formik.isValid}
          >
            {t("pay")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ShebaSatna;
