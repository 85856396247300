import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";

import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

import { Helpers } from "./utils";
import { AuthProvider } from "./providers";

import Pages from "src/pages";
import { Splash } from "./components";
import { useAuthStore, useCommonStore } from "./store";
import { NewOrderModal, UpdateAppModal } from "./shared";
import { SkeletonTheme } from "react-loading-skeleton";
import { setApiClient } from "./services/Api";
import { AxiosError } from "axios";
import { useServiceWorker } from "./hooks";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const { t, i18n } = useTranslation();

  const lang = localStorage.getItem("i18nextLng") || "en";
  const dir = localStorage.getItem("i18nDir") || "ltr";

  document.documentElement.dir = dir;
  document.documentElement.lang = lang;
  const { setNewOrderModal, newOrderModal } = useCommonStore();

  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const [showUpdateModal, setShowUpateModal] = useState(false);

  useEffect(() => {
    if (showReload && waitingWorker) {
      setShowUpateModal(true);
    }
  }, [waitingWorker, showReload, reloadPage]);

  useEffect(() => {
    Helpers.SetI18nInstance(i18n);
    setApiClient(queryClient);
  }, []);

  return (
    <Suspense fallback={<></>}>
      <UpdateAppModal
        isOpen={showUpdateModal}
        setIsOpen={setShowUpateModal}
        onUpdate={() => reloadPage()}
      />
      <BrowserRouter>
        <ToastContainer
          position={i18n.dir() == "rtl" ? "top-right" : "top-left"}
          rtl={i18n.dir() == "rtl"}
          autoClose={3000}
          style={{ zIndex: 1000000000 }}
        />
        <QueryClientProvider client={queryClient}>
          <NewOrderModal setIsOpen={setNewOrderModal} isOpen={newOrderModal} />
          <AuthProvider>
            <SkeletonTheme borderRadius={"12px"}>
              <Pages />
            </SkeletonTheme>
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
