import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, Modal } from "src/components";
import { useMediaQuery } from "src/hooks";
import { FormField, ToastMsg } from "src/shared";

import * as Yup from "yup";
import { useFormik } from "formik";

import { IFormValues } from "./types";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useAuthStore } from "src/store";
import { PaymentApi } from "src/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface ICreditCard {
  id?: number;
  card_number: string;
  iban: string;
  owner: any;
}

type Props = {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  data: ICreditCard;
};

const initialValues: IFormValues = {
  iban: undefined,
  card_number: undefined,
  owner: undefined,
  id: undefined,
};

const AddCardModal = (props: Props) => {
  const { t, i18n } = useTranslation("profile/ts");
  const { t: validationTranslations } = useTranslation("validation/ts");

  const { user } = useAuthStore();

  const isMobile = useMediaQuery("(max-width: 1023px)");

  const client = useQueryClient();

  const validationSchema = Yup.object({
    iban: Yup.string()
      .length(24, validationTranslations("length_24"))
      .required(validationTranslations("field_required")),
    card_number: Yup.string()
      .length(16, validationTranslations("length_16"))
      .required(validationTranslations("field_required")),
  });

  const createCardMutuate = useMutation(PaymentApi.createCreditCard, {
    onSuccess: (res) => {
      toast(
        <ToastMsg
          type="success"
          message={validationTranslations("data_store_success")}
        />
      );
      client.invalidateQueries({ queryKey: ["[Cards] Profile"] });
      props.setIsOpen(false);
    },
    onError: (error: AxiosError) => {
      if (error.response.status == 400) {
        toast(<ToastMsg type="error" message={t("data_store_error")} />);
      }
    },
  });

  const updatCardMutuate = useMutation(PaymentApi.updateCreditCard, {
    onSuccess: (res) => {
      toast(
        <ToastMsg
          type="success"
          message={validationTranslations("data_store_success")}
        />
      );
      client.invalidateQueries({ queryKey: ["[Cards] Profile"] });
      props.setIsOpen(false);
    },
    onError: (error: AxiosError) => {
      if (error.response.status == 400) {
        toast(<ToastMsg type="error" message={t("data_store_error")} />);
      }
    },
  });

  const formik = useFormik({
    initialValues: { ...initialValues, owner: user.id },
    validationSchema,
    onSubmit: (values) =>
      values.id
        ? updatCardMutuate.mutate({
            body: { ...values, iban: "IR" + values.iban },
            params: { id: values.id },
          })
        : createCardMutuate.mutate({
            body: { ...values, iban: "IR" + values.iban },
          }),
    validateOnChange: true,
  });

  useEffect(() => {
    if (props.data) {
      formik.setValues({ ...props.data, iban: props.data.iban.slice(2) });
    }
  }, [props.data]);

  useEffect(() => {
    if (!props.isOpen) {
      formik.resetForm();
    }
  }, [props.isOpen]);

  return (
    <Modal
      title={t("add_new_card")}
      isOpen={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      showClose={false}
    >
      <div className="w-full h-full flex flex-col justify-between">
        <div className="w-full">
          <div className="w-full mb-[14px]">
            <FormField
              label={t("sheba_number")}
              field={
                <div
                  className={`flex items-center w-full ${
                    i18n.dir() == "rtl" ? "flex-row-reverse" : ""
                  }`}
                >
                  <div
                    className={`w-12 h-12 lg:w-[50px] lg:h-[50px] bg-grey-100 flex justify-center items-center rounded-tl-lg rounded-bl-lg`}
                  >
                    <span className="text-base font-bold text-grey-800">
                      IR
                    </span>
                  </div>
                  <div className="flex-1">
                    <Input
                      placeholder={t("sheba_number")}
                      sizes={isMobile ? "Small" : "Large"}
                      onChange={(e) =>
                        formik.setFieldValue("iban", e.target.value)
                      }
                      onFocus={() => formik.setFieldTouched("iban", true)}
                      value={formik.values.iban}
                      autoComplete={"on"}
                      onClear={() => formik.setFieldValue("iban", "")}
                      addonClassName={"rounded-tl-none rounded-bl-none"}
                    />
                  </div>
                </div>
              }
              message={
                formik.errors.iban &&
                formik.touched.iban && {
                  type: "ERROR",
                  content: formik.errors.iban,
                }
              }
            />
          </div>

          <div className="w-full mb-[30px]">
            <FormField
              label={t("credit_number")}
              field={
                <Input
                  placeholder={t("credit_number")}
                  sizes={isMobile ? "Small" : "Large"}
                  onChange={(e) =>
                    formik.setFieldValue("card_number", e.target.value)
                  }
                  onFocus={() => formik.setFieldTouched("card_number", true)}
                  value={formik.values.card_number}
                  autoComplete={"on"}
                  onClear={() => formik.setFieldValue("card_number", "")}
                />
              }
              message={
                formik.errors.card_number &&
                formik.touched.card_number && {
                  type: "ERROR",
                  content: formik.errors.card_number,
                }
              }
            />
          </div>
        </div>
        <div className="w-full grid grid-cols-12 gap-3">
          <div className="col-span-6">
            <Button
              addonClasses="w-full"
              onClick={() => formik.submitForm()}
              disabled={!formik.isValid}
              loading={
                createCardMutuate.isLoading || updatCardMutuate.isLoading
              }
            >
              {t("add_card")}
            </Button>
          </div>
          <div className="col-span-6">
            <Button
              addonClasses="w-full"
              type="Secondary"
              onClick={() => props.setIsOpen(false)}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddCardModal;
