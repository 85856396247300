import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, CheckBox, Input, PhoneInput } from "src/components";
import { useMediaQuery } from "src/hooks";
import { FormField } from "src/shared";

type Props = {
  onFinish: () => void;
  formik: any;
  loading: boolean;
};

const containsUpperCase = (str: string) => str !== str.toLowerCase();
const containsLowerCase = (str: string) => str !== str.toUpperCase();
const containsNumberAndCharacter = (str: string) =>
  /\d/.test(str) && /[a-zA-Z]/.test(str);

const Step1 = ({ formik, onFinish, loading }: Props) => {
  const { t, i18n } = useTranslation("auth/ts");
  const isMobile = useMediaQuery("(max-width: 1023px)");

  return (
    <div className="w-full h-full flex flex-col items-center justify-between">
      <div className="w-full flex flex-col items-center">
        <h1
          className={`text-grey-800 ${
            isMobile ? "text-base" : "text-[32px]"
          } mb-2 font-semibold`}
        >
          {t("create_account")}
        </h1>
        <p className={`text-grey-700 ${isMobile ? "text-sm" : "text-lg"} mb-5`}>
          {t("describe_self")}
        </p>
        <div className="w-full mb-[14px]">
          <FormField
            label={t("email_field")}
            field={
              <Input
                name="email"
                type={"email"}
                placeholder={t("enter_email")}
                sizes={isMobile ? "Small" : "Large"}
                onChange={(e) => formik.setFieldValue("email", e.target.value)}
                onFocus={() => formik.setFieldTouched("email", true)}
                value={formik.values.email}
                autoComplete={"on"}
                onClear={() => formik.setFieldValue("email", "")}
              />
            }
            message={
              formik.errors.email &&
              formik.touched.email && {
                type: "ERROR",
                content: formik.errors.email,
              }
            }
          />
        </div>
        <div className="w-full mb-[14px]">
          <FormField
            label={t("mobile_field")}
            field={
              <PhoneInput
                placeholder={t("enter_mobile")}
                name="phone"
                type={"phone"}
                sizes={isMobile ? "Small" : "Large"}
                onChangeText={(val, code) => {
                  formik.setFieldValue("phone_number", val);
                  formik.setFieldValue("country_code", code);
                }} 
                selectedFlag={formik.values.country_code}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    formik.submitForm();
                  }
                }}
                value={formik.values.phone_number}
                onFocus={() => formik.setFieldTouched("phone_number", true)}
                autoComplete={"on"}
                onClear={() => formik.setFieldValue("phone_number", "")}
              />
            }
            message={
              formik.errors.phone_number &&
              formik.touched.phone_number && {
                type: "ERROR",
                content: formik.errors.phone_number,
              }
            }
          />
        </div>
        <div className="w-full mb-[10px]">
          <FormField
            label={t("pass_field")}
            field={
              <Input
                name="password"
                type={"password"}
                placeholder={t("enter_pass")}
                sizes={isMobile ? "Small" : "Large"}
                onChange={(e) =>
                  formik.setFieldValue("password", e.target.value)
                }
                onFocus={() => formik.setFieldTouched("password", true)}
                value={formik.values.password}
                autoComplete={"on"}
                onClear={() => formik.setFieldValue("password", "")}
              />
            }
          />
        </div>
        {formik.values.password && (
          <>
            <div className="w-full mb-[10px]">
              <CheckBox
                checked={Boolean(formik.values.password)}
                type={formik.values.password.length >= 8 ? "SUCCESS" : "ERROR"}
                label={t("pass_8_hint")}
              />
            </div>
            <div className="w-full mb-[10px]">
              <CheckBox
                checked={Boolean(formik.values.password)}
                type={
                  containsLowerCase(formik.values.password) &&
                  containsUpperCase(formik.values.password)
                    ? "SUCCESS"
                    : "ERROR"
                }
                label={t("pass_contain_ch_hint")}
              />
            </div>
            <div className="w-full">
              <CheckBox
                checked={Boolean(formik.values.password)}
                type={
                  containsNumberAndCharacter(formik.values.password)
                    ? "SUCCESS"
                    : "ERROR"
                }
                label={t("pass_contain_num_hint")}
              />
            </div>
          </>
        )}
      </div>
      <Button
        disabled={!formik.isValid}
        addonClasses="w-full mt-10 lg: mt:0"
        onClick={() => formik.submitForm()}
        loading={loading}
      >
        {t("create_account")}
      </Button>
    </div>
  );
};

export default Step1;
