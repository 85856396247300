import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CoreApi, PaymentApi, ServiceApi } from "src/api";
import {
  Button,
  CheckBox,
  IconButton,
  Input,
  Modal,
  RadioButton,
  Select,
} from "src/components";

import * as Yup from "yup";
import { useFormik } from "formik";

import { IFormValues } from "./types";
import { AxiosError, AxiosResponse } from "axios";
import { Step1, Step2 } from "./partials";

type Props = {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
};

const initialValues: IFormValues = {
  image: undefined,
  tracking_code: undefined,
  amount: undefined,
};

const IncreaseCredit = (props: Props) => {
  const { t, i18n } = useTranslation("wallet/ts");
  const { t: validationTranslations } = useTranslation("validation/ts");

  const [step, setStep] = useState<number>(1);

  const validationSchema = Yup.object({
    amount: Yup.number().required(validationTranslations("field_required")),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (step == 1) {
        setStep(2);
      }
    },
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
  });

  useEffect(() => {
    if (!props.isOpen) {
      setStep(1);
    }
  }, [props.isOpen]);

  return (
    <Modal
      title={t("increase_credit_wallet")}
      isOpen={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      showClose={false}
    >
      <div className="w-full h-full flex flex-col justify-between">
        {step == 1 ? (
          <Step1 formik={formik} step={step} />
        ) : (
          <Step2 formik={formik} onFinish={() => props.setIsOpen(false)} />
        )}
      </div>
    </Modal>
  );
};

export default IncreaseCredit;
