import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ArrowLeft, ArrowRight, Plus, Swap } from "react-iconly";
import { ClipLoader } from "react-spinners";
import { CoreApi, ServiceApi } from "src/api";
import { UsaFlag } from "src/assets";
import { Button, IconButton, Input, Select } from "src/components";
import { useMediaQuery } from "src/hooks";
import { FormField } from "src/shared";
import { useCommonStore } from "src/store";
import { ColorPalette, Helpers } from "src/utils";

type Props = {};

const ExchangeCalculator = (props: Props) => {
  const { t, i18n } = useTranslation("dashboard/ts");

  const Rial = {
    label: t("toman"),
    image: "https://api.adsition.com/media/w3c_3.png",
    value: 4,
  };

  const { setNewOrderModal } = useCommonStore();

  const isMobile = useMediaQuery("(max-width:1023px");

  const [from, setFrom] = useState<Helpers.keyable>();
  const [to, setTo] = useState<Helpers.keyable>(Rial);
  const [result, setResult] = useState<string>();
  const [amount, setAmount] = useState<number>();
  const [minPurchase, setMinPurchase] = useState(0);

  const { data: currencies } = useQuery({
    queryKey: ["[Currency List] Orders"],
    queryFn: () =>
      CoreApi.getCurrencyList().then((result) => {
        return result.results;
      }),
  });

  const calcMutate = useMutation(ServiceApi.adwordsCalculator, {
    onSuccess: (res) => {
      setResult(res.total);
    },
    onError: (error: AxiosError) => {},
  });

  useEffect(() => {
    if (from && amount) {
      calcMutate.mutate({
        body: {
          from_currency: from.value,
          to_currency: to.value,
          amount,
        },
      });
    }
  }, [from, , to, amount]);

  return (
    <div className="py-5 px-4 rounded-[20px] border border-grey-200">
      <div className="w-full flex justify-between items-center mb-5">
        <h4 className="text-sm lg:text-lg font-semibold text-grey-800">
          {t("exchange_calculator")}
        </h4>
      </div>
      <div className="w-full flex gap-2 mb-5 flex-nowrap">
        <div className="flex-1" style={{ maxWidth: "calc(50% - 20px)" }}>
          <FormField
            label={t("exchange_type")}
            field={
              <Select
                options={
                  currencies
                    ? currencies.map((item: Helpers.keyable) => ({
                        label: t(item.name),
                        value: item.id,
                        image: item.icon,
                        last_price: item.last_price,
                        adwords_min_purchase: item.adwords_min_purchase,
                      }))
                    : []
                }
                value={from as any}
                onChange={(e) => {
                  setFrom(e);
                  setTo(Rial);
                  setMinPurchase(e.adwords_min_purchase);
                }}
                placeholder={t("enter_currency")}
              />
            }
          />
        </div>
        {calcMutate.isLoading ? (
          <div style={{ margin: "40px auto 0 auto" }}>
            <ClipLoader
              loading={true}
              size={20}
              color={ColorPalette.grey["700"]}
            />
          </div>
        ) : i18n.language == "en" ? (
          <ArrowRight
            size={20}
            style={{
              margin: isMobile ? "35px auto 0 auto" : "40px auto 0 auto",
            }}
            primaryColor={ColorPalette.grey["700"]}
          />
        ) : (
          <ArrowLeft
            size={20}
            style={{
              margin: isMobile ? "35px auto 0 auto" : "40px auto 0 auto",
            }}
            primaryColor={ColorPalette.grey["700"]}
          />
        )}
        <div className="flex-1" style={{ maxWidth: "calc(50% - 20px)" }}>
          <FormField
            label={t("value")}
            field={
              <Input
                placeholder={t("enter_amount")}
                sizes={isMobile ? "Small" : "Large"}
                value={amount}
                onClear={() => setAmount(0)}
                onChange={(e) =>
                  setAmount(
                    Number(
                      Helpers.convertPerToEn(e.target.value)
                        .replace(/[^\d]/g, "")
                        .replace(/^0+/, "")
                    )
                  )
                }
              />
            }
          />
        </div>
      </div>
      {result != undefined ? (
        <div className="w-full flex justify-between items-center mb-[30px] gap-3">
          <div className="flex-1 flex justify-between items-center rounded-xl bg-grey-100 p-[14px]">
            <div className="flex items-center">
              <img
                src={to.image}
                style={{
                  margin: i18n.dir() == "rtl" ? "0 0 0 8px" : "0 8px 0 0",
                }}
                width={24}
              />
              <span className="text-xs lg:text-sm text-grey-700">
                {to.label}
              </span>
            </div>
            <div className="flex items-center">
              <span
                style={{
                  margin: i18n.dir() == "rtl" ? "0 0 0 8px" : "0 8px 0 0",
                }}
                className="text-base text-grey-800 font-bold"
              >
                {result.toLocaleString()}
              </span>
              <span className="text-xs lg:text-sm text-grey-600">
                {to.label}
              </span>
            </div>
          </div>
          <IconButton
            key={"btn-filter"}
            bgColor="bg-secondary-400"
            bgColorHovered="hover:bg-secondary-700"
            ringColor="focus:ring focus:ring-secondary-200"
            icon={<Swap size={24} primaryColor="white" />}
            size={isMobile ? "Medium" : "Large"}
            onClick={() => {
              const _from = from;
              const _to = to;
              setAmount(Number(result));
              setFrom(_to);
              setTo(_from);
            }}
          />
        </div>
      ) : (
        <div className="mb-[30px] h-[52px]"></div>
      )}
      <Button
        addonClasses="w-full"
        size={isMobile ? "Small" : "Medium"}
        icon={<Plus size={20} />}
        disabled={!result}
        onClick={() => {
          setNewOrderModal(
            true,
            undefined,
            from.value != 0 ? from : to,
            amount
          );
        }}
      >
        {t("new_order")}
      </Button>
    </div>
  );
};

export default ExchangeCalculator;
