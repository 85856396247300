import { useTranslation } from "react-i18next";
import { Button, Input, Modal, Select } from "src/components";

import { IFormValues } from "./types";
import { IranFlag } from "src/assets";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CoreApi, ServiceApi, WalletApi } from "src/api";
import { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { ColorPalette, Helpers } from "src/utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ClipLoader } from "react-spinners";
import { FormField, ToastMsg } from "src/shared";
import { ArrowLeft, ArrowRight, ChevronDown, ChevronUp } from "react-iconly";
import { useMediaQuery } from "src/hooks";
import { Collapse } from "react-collapse";
import { toast } from "react-toastify";

type Props = {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  ballance: number;
};

const initialValues: IFormValues = {
  amount: undefined,
  amount_to_show: undefined,
  currency: undefined,
};
const ConvetWallet = (props: Props) => {
  const { t, i18n } = useTranslation("wallet/ts");
  const { t: validationTranslations } = useTranslation("validation/ts");

  const isMobile = useMediaQuery("(max-width:1023px");

  const queryClient = useQueryClient();

  const [details, setDetails] = useState<Helpers.keyable>();
  const [minPurchase, setMinPurchase] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const validationSchema = Yup.object({
    amount: Yup.number()
      .min(minPurchase, `${t("min_purchase")} ${minPurchase}`)
      .required(validationTranslations("field_required")),
    currency: Yup.mixed().required(validationTranslations("field_required")),
  });

  const { data: currencies } = useQuery({
    queryKey: ["[Currency List] Orders"],
    queryFn: () =>
      CoreApi.getCurrencyList().then((result) => {
        return result.results;
      }),
  });

  const calcMutate = useMutation(ServiceApi.adwordsCalculator, {
    onSuccess: (res) => {
      setDetails(res);
      formik.validateForm()
    },
    onError: (error: AxiosError) => {},
  });

  const swapMutate = useMutation(WalletApi.SwapToman, {
    onSuccess: (res) => {
      toast(<ToastMsg type="success" message={t("swapped_success")} />);
      props.setIsOpen(false);
      queryClient.invalidateQueries({
        queryKey: ["[List] Wallet Transcation"],
      });
      queryClient.invalidateQueries({ queryKey: ["[List] Wallet"] });
    },
    onError: (error: AxiosError) => {
      if ((error.response.data as any).amount) {
        toast(<ToastMsg type="error" message={t("insufficient_ballance")} />);
      } else {
        toast(<ToastMsg type="error" message={t("swapped_error")} />);
      }
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) =>
      swapMutate.mutate({
        body: {
          ...values,
          currency: values.currency.value,
        },
      }),
  });

  console.log(formik.values.amount, minPurchase)

  const getBallanceBgColor = () => {
    if (props.ballance == 0) {
      return "bg-error-50";
    } else {
      return "bg-grey-50";
    }
  };

  const getBallanceColor = () => {
    if (props.ballance == 0) {
      return "text-error-500";
    } else {
      return "text-grey-800";
    }
  };

  useEffect(() => {
    if (
      formik.values.amount &&
      formik.values.amount >= minPurchase &&
      formik.values.currency
    ) {
      calcMutate.mutate({
        body: {
          to_currency: 4,
          from_currency: formik.values.currency.value,
          amount: formik.values.amount,
        },
      });
    }
    formik.validateForm()
  }, [formik.values.amount, formik.values.currency]);

  return (
    <Modal
      title={t("convert_wallet_title")}
      isOpen={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      showClose={false}
    >
      <div className="w-full h-full flex flex-col justify-between">
        <div className="w-full">
          <h4 className="text-base text-grey-800 font-semibold mb-4">
            {t("wallet_credit")}
          </h4>
          <div
            className={`mb-5 py-5 px-4 rounded-xl flex items-center justify-between ${getBallanceBgColor()}`}
          >
            <div className="flex items-center gap-[10px]">
              <img src={IranFlag} className="w-6 h-6 rounded-full" />
              <span className="text-grey-700 text-md font-semibold">
                {t("toman")}
              </span>
            </div>
            <span className="flex gap-[10px] text-md text-grey-600">
              <strong className={`font-bold text-base ${getBallanceColor()}`}>
                {props.ballance.toLocaleString()}
              </strong>
              {t("toman")}
            </span>
          </div>
          <div className="w-full flex gap-2 mb-[30px] flex-nowrap">
            <div className="flex-1" style={{ maxWidth: "calc(50% - 20px)" }}>
              <FormField
                label={t("amount")}
                field={
                  <Input
                    placeholder={t("enter_amount")}
                    sizes={isMobile ? "Small" : "Large"}
                    value={formik.values.amount_to_show}
                    onClear={() => formik.setFieldValue("amount", 0)}
                    onFocus={() => formik.setFieldTouched("amount", true)}
                    onChange={(e) => {
                      const amountToShow = Helpers.convertPerToEn(
                        e.target.value
                      )
                        .replace(/[^\d]/g, "")
                        .replace(/^0+/, "");
                      formik.setFieldValue("amount", Number(amountToShow));
                      formik.setFieldValue(
                        "amount_to_show",
                        amountToShow.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      );
                    }}
                  />
                }
                message={
                  formik.errors.amount &&
                  formik.touched.amount && {
                    type: "ERROR",
                    content: formik.errors.amount,
                  }
                }
              />
            </div>
            {calcMutate.isLoading ? (
              <div style={{ margin: "40px auto 0 auto" }}>
                <ClipLoader
                  loading={true}
                  size={20}
                  color={ColorPalette.grey["700"]}
                />
              </div>
            ) : i18n.language == "en" ? (
              <ArrowRight
                size={20}
                style={{
                  margin: isMobile ? "35px auto 0 auto" : "40px auto 0 auto",
                }}
                primaryColor={ColorPalette.grey["700"]}
              />
            ) : (
              <ArrowLeft
                size={20}
                style={{
                  margin: isMobile ? "35px auto 0 auto" : "40px auto 0 auto",
                }}
                primaryColor={ColorPalette.grey["700"]}
              />
            )}
            <div className="flex-1" style={{ maxWidth: "calc(50% - 20px)" }}>
              <FormField
                label={t("currency_type")}
                field={
                  <Select
                    options={
                      currencies
                        ? currencies.map((item: Helpers.keyable) => ({
                            label: t(item.name),
                            value: item.id,
                            image: item.icon,
                            last_price: item.last_price,
                            adwords_min_purchase: item.adwords_min_purchase,
                          }))
                        : []
                    }
                    value={formik.values.currency}
                    onChange={(e) => {
                      formik.setFieldValue("currency", e);
                      setMinPurchase(e.adwords_min_purchase);
                    }}
                    placeholder={t("enter_currency")}
                  />
                }
                message={
                  formik.errors.currency &&
                  formik.touched.currency && {
                    type: "ERROR",
                    content: formik.errors.currency,
                  }
                }
              />
            </div>
          </div>
          {details && (
            <div className="px-4 py-5 rounded-[20px] bg-grey-50 mb-5">
              <div
                className="flex justify-between items-center"
                onClick={() => setIsOpen((prev) => !prev)}
              >
                <h4 className="text-base font-semibold text-grey-800">
                  {t("payment_details")}:
                </h4>
                {isOpen ? (
                  <ChevronUp
                    size={20}
                    primaryColor={ColorPalette.grey["700"]}
                  />
                ) : (
                  <ChevronDown
                    size={20}
                    primaryColor={ColorPalette.grey["700"]}
                  />
                )}
              </div>
              <Collapse isOpened={isOpen}>
                <div className="flex justify-between items-center mb-5 mt-5">
                  <h6 className="text text-grey-700">{t("subtotal")}</h6>
                  <span className="text-sm text-grey-600 gap-2 flex">
                    <strong className="text-grey-800 font-semibold">
                      {details?.subtotal.toLocaleString()}
                    </strong>
                    {t("toman")}
                  </span>
                </div>
                <div className="flex justify-between items-center mb-[14px] pb-[14px] border-b border-b-grey-200">
                  <h6 className="text text-grey-700">{t("wage")}</h6>
                  <span className="text-sm text-grey-600 gap-2 flex">
                    <strong className="text-grey-800 font-semibold">
                      {details?.wage}
                    </strong>
                    %
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <h6 className="text text-grey-800 font-bold">{t("total")}</h6>
                  <span className="text-sm text-grey-600 py-1 px-2 bg-secondary-50 rounded-lg flex gap-2 items-center">
                    <strong className="text-xl font-bold text-secondary-500">
                      {details?.total.toLocaleString()}
                    </strong>{" "}
                    {t("toman")}
                  </span>
                </div>
              </Collapse>
            </div>
          )}
        </div>
        <div className="flex justify-center lg:justify-end">
          <div
            className={`w-full lg:max-w-sm grid grid-cols-12 
          gap-3`}
          >
            <div className="col-span-6">
              <Button
                addonClasses="w-full"
                type="Secondary"
                onClick={() => props.setIsOpen(false)}
              >
                {validationTranslations("cancel")}
              </Button>
            </div>
            <div className="col-span-6">
              <Button
                addonClasses="w-full"
                onClick={() => formik.submitForm()}
                disabled={!formik.isValid}
                loading={swapMutate.isLoading}
              >
                {t("convert")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConvetWallet;
