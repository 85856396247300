import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Calendar,
  Edit,
  Filter2,
  Paper,
  Plus,
  Search,
  Show,
  Upload,
} from "react-iconly";
import { Link, useSearchParams } from "react-router-dom";
import { ColorPalette, Helpers } from "src/utils";
import { IAccountsDetailInterface, IAccountsInterface } from "./types";
import { createColumnHelper } from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";
import { OrderApi, ServiceApi } from "src/api";
import {
  AccountDetailModal,
  EmptyAccount,
  FilterSec,
  Footer,
} from "./partials";
import Skeleton from "react-loading-skeleton";
import { Table } from "src/components";
import { MoreSquare } from "iconsax-react";
import { MoreSvg } from "src/assets";
import { Popover, Transition } from "@headlessui/react";
type Props = {};

enum ACCOUNT_TYPE {
  CREATED = "NEW",
  TRANSFERRED = "TRANSFERRED",
}

enum ACCOUNT_STATUS {
  ACTIVE = "ACTIVE",
  DEACTIVE = "DEACTIVE",
}

const Accounts = (props: Props) => {
  const { t, i18n } = useTranslation("accounts/ts");

  const [pageCount, setPageCount] = useState<number>(1);
  const [detailModal, setDetailModal] = useState<Helpers.keyable>({
    show: false,
    data: undefined,
    stamp: new Date(),
  });
  const [listDetail, setListDetail] = useState<IAccountsDetailInterface>();

  const [searchParams, setSearchParams] = useSearchParams();

  const getAccountTypeT = (status: string) => {
    switch (status) {
      case ACCOUNT_TYPE.TRANSFERRED:
        return t("transferred");
      case ACCOUNT_TYPE.CREATED:
        return t("created");

      default:
        break;
    }
  };

  const getAccountTypeColor = (status: string) => {
    switch (status) {
      case ACCOUNT_TYPE.TRANSFERRED:
        return "bg-primary-50 text-primary-500";
      case ACCOUNT_TYPE.CREATED:
        return "bg-success-50 text-success-500";

      default:
        break;
    }
  };

  const getAccountStatusT = (status: string) => {
    switch (status) {
      case ACCOUNT_STATUS.ACTIVE:
        return t("active");
      case ACCOUNT_STATUS.DEACTIVE:
        return t("deactive");

      default:
        break;
    }
  };

  const getAccountStatusColor = (status: string) => {
    switch (status) {
      case ACCOUNT_STATUS.ACTIVE:
        return "text-success-700";
      case ACCOUNT_STATUS.DEACTIVE:
        return "text-grey-500";

      default:
        break;
    }
  };

  const columnHelper = createColumnHelper<IAccountsInterface>();

  const columns = [
    columnHelper.accessor("index", {
      header: () => <span>#</span>,
      cell: ({ row }) => row.index + 1,
      enableSorting: false,
      size: 60,
    }),
    columnHelper.accessor("number", {
      header: () => <span>{t("account_number")}</span>,
      cell: (row) => row.getValue(),
      enableSorting: false,
      meta: {
        mobile_header: t("account_number"),
      },
    }),
    columnHelper.accessor("name", {
      header: () => <span>{t("name")}</span>,
      cell: (row) => row.getValue(),
      sortingFn: () => 1,
      meta: {
        mobile_header: t("name"),
      },
    }),
    columnHelper.accessor("website", {
      header: () => <span>{t("website_address")}</span>,
      cell: (row) => row.getValue(),
      enableSorting: false,
      meta: {
        mobile_header: t("website_address"),
      },
    }),
    columnHelper.accessor("currency", {
      header: () => <span>{t("currency")}</span>,
      cell: (row) => {
        let cur = row.getValue() as Helpers.keyable;
        if (cur) {
          return (
            <div className="flex items-center">
              <img
                src={cur?.icon}
                className="w-6 h-6"
                style={{
                  margin: i18n.dir() == "rtl" ? "0 0 0 8px" : "0 8px 0 0",
                }}
              />
              <span className="text-sm text-grey-700">{t(cur?.name)}</span>
            </div>
          );
        } else {
          return <></>;
        }
      },
      meta: {
        mobile_header: t("currency"),
      },
    }),
    columnHelper.accessor("type", {
      header: () => <span>{t("type")}</span>,
      cell: (row) => (
        <div className="flex items-center justify-start">
          <div
            className={`px-3 py-[5px] ${getAccountTypeColor(
              row.getValue()
            )} rounded-lg text-xs text-center`}
          >
            {getAccountTypeT(row.getValue())}
          </div>
        </div>
      ),
      sortingFn: () => 1,
      size: 100,
      meta: {
        mobile_header: t("type"),
      },
    }),
    columnHelper.accessor("status", {
      header: () => <span>{t("status")}</span>,
      cell: (row) => (
        <div className="flex items-center justify-start">
          <div
            className={`px-3 py-[5px] ${getAccountStatusColor(
              row.getValue()
            )} rounded-lg text-xs text-center`}
          >
            {getAccountStatusT(row.getValue())}
          </div>
        </div>
      ),
      sortingFn: () => 1,
      size: 100,
      meta: {
        mobile_header: t("status"),
      },
    }),
    columnHelper.accessor("action", {
      header: () => <span></span>,
      cell: (row) => (
        <div className="flex items-center justify-end gap-2">
          <Popover className="relative">
            <Popover.Button>
              <img src={MoreSvg} className="cursor-pointer" />
            </Popover.Button>

            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Popover.Panel className="absolute z-10 right-[100%]">
                <div className="py-2 rounded-lg bg-white border border-grey-200">
                  <Link
                    className="w-full min-w-[200px] flex items-center gap-2 hover:bg-grey-200 px-[14px] py-[10px]"
                    to={`/accounts/single/${row.row.original.id}`}
                  >
                    <Show primaryColor={ColorPalette.grey["700"]} size={24} />
                    {t("show_details")}
                  </Link>
                  <Link
                    className="w-full min-w-[200px] flex items-center gap-2 hover:bg-grey-200 px-[14px] py-[10px]"
                    to={`#`}
                    onClick={() => {
                      setDetailModal({
                        show: true,
                        stamp: new Date(),
                        data: row.row.original,
                      });
                    }}
                  >
                    <Edit primaryColor={ColorPalette.grey["700"]} size={24} />
                    {t("edit")}
                  </Link>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>
      ),
      enableSorting: false,
      size: 200,
    }),
  ];

  const {
    data,
    isFetching: isQueryLoading,
    status,
    refetch,
  } = useQuery({
    queryKey: ["[List] Account"],
    queryFn: () =>
      ServiceApi.getAdwordsAccount({
        params: Object.fromEntries(searchParams),
      }).then((result) => {
        return result;
      }),
    cacheTime: 0,
  });

  const result = React.useMemo(() => {
    if (!isQueryLoading && data && status === "success") {
      const { results, ...rest } = data;

      const page_size = parseInt(searchParams.get("page_size")) || 10;
      setPageCount(Math.ceil(rest.count / page_size));

      setListDetail(rest);
      return results.map((item: IAccountsInterface, index: number) => ({
        ...item,
        index,
      }));
    } else {
      return [];
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [searchParams]);

  if (
    result.length == 0 &&
    !isQueryLoading &&
    Array.from(searchParams).length == 0
  ) {
    return <EmptyAccount />;
  } else
    return (
      <div className="w-full">
        <AccountDetailModal
          isOpen={detailModal.show}
          setIsOpen={(state) =>
            setDetailModal({ ...detailModal, show: state, stamp: new Date() })
          }
          data={detailModal.data}
        />
        <FilterSec refetch={refetch} />
        <div className="mb-[34px]">
          <Table
            data={result}
            columns={columns}
            title={t("account_list")}
            Icon={Paper}
            headerColor={"grey-100"}
            bordered={false}
            loading={isQueryLoading}
            onSort={(key) => {
              const sorted = searchParams.get("ordering") || "-";
              if (sorted.includes("-")) {
                searchParams.set("ordering", key);
              } else {
                searchParams.set("ordering", "-" + key);
              }
              searchParams.set("page", "1");
              setSearchParams(searchParams);
            }}
          />
        </div>
        {listDetail ? (
          pageCount > 1 && (
            <Footer
              pageCount={pageCount || 1}
              count={listDetail.count}
              t={t}
              i18n={i18n}
            />
          )
        ) : (
          <Skeleton height={54} />
        )}
      </div>
    );
};

export default Accounts;
