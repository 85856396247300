import { useTranslation } from "react-i18next";

import { useMediaQuery } from "src/hooks";
import { MessageTab, TicketInfo } from "../partials";

type Props = {};

const Desktop = (props: Props) => {
  const { t } = useTranslation("tickets/ts");

  const isMobile = useMediaQuery("(max-width: 1023px)");

  return (
    <div className="w-full h-full grid grid-cols-12 gap-4">
      <div className="col-span-12 lg:col-span-5 my-[10px]">
        <TicketInfo />
      </div>
      <div className="col-span-12 lg:col-span-7 pb-[20px] pt-[10px]">
        <MessageTab />
      </div>
    </div>
  );
};

export default Desktop;
