import { CloseCircle, Danger, TickSquare } from "iconsax-react";
import { useTranslation } from "react-i18next";
import { InfoSquare } from "react-iconly";
import { CloseSvg } from "src/assets";
import { ColorPalette } from "src/utils";

type Props = {
  type: "error" | "success" | "info" | "warning";
  message: string;
};

const ToastMsg = (props: Props) => {
  const { i18n, t } = useTranslation("validation/ts");

  const getIcon = () => {
    switch (props.type) {
      case "error":
        return (
          <Danger
            size={20}
            color={ColorPalette.error["400"]}
            style={{
              margin: i18n.dir() == "rtl" ? "0 0 0 12px" : "0 12px 0 0",
            }}
          />
        );
      case "info":
        return (
          <InfoSquare
            size={20}
            primaryColor={ColorPalette.error["400"]}
            style={{
              margin: i18n.dir() == "rtl" ? "0 0 0 12px" : "0 12px 0 0",
            }}
          />
        );
      case "success":
        return (
          <TickSquare
            size={20}
            color={ColorPalette.success["500"]}
            style={{
              margin: i18n.dir() == "rtl" ? "0 0 0 12px" : "0 12px 0 0",
            }}
          />
        );
      case "warning":
        return (
          <InfoSquare
            size={20}
            primaryColor={ColorPalette.warning["400"]}
            style={{
              margin: i18n.dir() == "rtl" ? "0 0 0 12px" : "0 12px 0 0",
            }}
          />
        );

      default:
        break;
    }
  };

  const getBgColor = () => {
    switch (props.type) {
      case "error":
        return "bg-error-50";
      case "info":
        return "bg-info-50";

      case "success":
        return "bg-success-50";

      case "warning":
        return "bg-warning-50";

      default:
        break;
    }
  };

  const getTitleColor = () => {
    switch (props.type) {
      case "error":
        return "text-error-400";
      case "info":
        return "text-info-500";

      case "success":
        return "text-success-500";

      case "warning":
        return "text-warning-400";

      default:
        break;
    }
  };

  const getBorderColor = () => {
    switch (props.type) {
      case "error":
        return "border-error-400";
      case "info":
        return "border-info-500";

      case "success":
        return "border-success-500";

      case "warning":
        return "border-warning-400";

      default:
        break;
    }
  };

  return (
    <div
      className={`w-full px-4 pt-3 pb-4 ${getBgColor()} rounded-lg border ${getBorderColor()} flex items-start`}
      style={{ width: "100%", maxWidth: 420 }}
    >
      {getIcon()}
      <div className="flex-1">
        <div className="w-full flex-col">
          <a className={`text-sm ${getTitleColor()} font-semibold`}>
            {t(props.type)}
          </a>
          <p className="text-sm text-grey-700">{t(props.message)}</p>
        </div>
      </div>
      <img
        src={CloseSvg}
        style={{
          margin: i18n.dir() == "rtl" ? "0 16px 0 0" : "0 0 0 16px",
        }}
      />
    </div>
  );
};

export default ToastMsg;
