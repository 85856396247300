import { AuthApi } from "src/api";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface keyable {
  [key: string]: any;
}

interface IAuthStore {
  access?: string;
  refresh?: string;
  isAuthenticated: boolean;
  user?: keyable;
  setUser: (user: keyable) => void;
  refreshUser: () => Promise<void>;
  loginUser: (accessToken: string, refreshToken: string) => void;
  logoutUser: () => void;
}

const useAuthStore = create<IAuthStore>()(
  persist(
    (set, get) => ({
      access: undefined,
      refresh: undefined,
      isAuthenticated: false,
      user: undefined,
      setUser: (user: object) => {
        set({ user });
      },
      refreshUser: async () =>
        AuthApi.postAuthRefresh({
          body: {
            refresh: get().refresh,
          },
        })
          .then(({ access }) => set({ access, isAuthenticated: true }))
          .catch(() => {
            set({ access: undefined, refresh: undefined, isAuthenticated: false });
          }),
      loginUser: (accessToken, refreshToken) => {
        set({
          access: accessToken,
          refresh: refreshToken,
          isAuthenticated: true,
        });
      },
      logoutUser: () => {
        set({ access: undefined, refresh: undefined, isAuthenticated: false });
      },
    }),
    {
      name: "adsition-auth-storage",
    }
  )
);

export default useAuthStore;
