import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Input, PhoneInput } from "src/components";
import { useMediaQuery } from "src/hooks";
import { FormField } from "src/shared";

type Props = {
  onFinish: () => void;
  formik: any;
  loading: boolean;
};

const Step1 = ({ formik, onFinish, loading }: Props) => {
  const { t, i18n } = useTranslation("auth/ts");
  const isMobile = useMediaQuery("(max-width: 1023px)");

  return (
    <div className="w-full h-full flex flex-col items-center justify-between">
      <div className="w-full flex flex-col items-center">
        <h1
          className={`text-grey-800 ${
            isMobile ? "text-base" : "text-[32px]"
          } mb-2 font-semibold`}
        >
          {t("welcome_back")}
        </h1>
        <p className={`text-grey-700 ${isMobile ? "text-sm" : "text-lg"} mb-5`}>
          {t("describe_self_login")}
        </p>
        <div className="w-full mb-[14px]">
          <FormField
            label={t("mobile_field") + "*"}
            field={
              <PhoneInput
                placeholder={t("enter_mobile")}
                name="phone"
                type={"phone"}
                sizes={isMobile ? "Small" : "Large"}
                onChangeText={(val, code) => {
                  formik.setFieldValue("phone_number", val);
                  formik.setFieldValue("country_code", code);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    formik.submitForm();
                  }
                }}
                selectedFlag={formik.values.country_code}
                value={formik.values.phone_number}
                onFocus={() => formik.setFieldTouched("phone_number", true)}
                autoComplete={"on"}
                onClear={() => formik.setFieldValue("phone_number", "")}
              />
            }
            message={
              formik.errors.phone_number &&
              formik.touched.phone_number && {
                type: "ERROR",
                content: formik.errors.phone_number,
              }
            }
          />
        </div>
      </div>
      <div className="w-full flex flex-col">
        <div className="mb-[30px]">
          <Button
            addonClasses="w-full"
            onClick={() => formik.submitForm()}
            loading={loading}
            disabled={!formik.isValid}
          >
            {t("signIn")}
          </Button>
        </div>
        <div className="flex items-center justify-center">
          <div className="flex items-center justify-center gap-[30px]">
            <span className="text-sm text-grey-700">{t("try_email")}</span>
            <Link to="/auth/login" className="text-[13px] text-primary-500">
              {t("login")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
