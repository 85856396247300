import { CloseCircle } from "iconsax-react";
import React, { useState } from "react";
import { Hide, Show } from "react-iconly";
import { CloseSvg } from "src/assets";

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode;
  status?: "success" | "error";
  sizes?: "Large" | "Small";
  icon?: React.ReactNode;
  value?: string | number;
  disabled?: boolean;
  onClear?: () => void;
  addonClassName?: string;
}

const Classes = (list: any) => {
  return list.filter(Boolean).join(" ");
};

const Input = (props: IInputProps) => {
  const [focused, setFocused] = useState<Boolean>(false);
  const [secured, setSecured] = useState<Boolean>(true);

  const getDefaultStyles = () => {
    return `border-grey-200 rounded-lg flex items-center border [&>svg]:text-grey-700 transition-all duration-300
     ${props.addonClassName ? props.addonClassName : ""}
    ${props.disabled ? "bg-grey-200 [&>svg]:text-grey-500" : ""} ${
      focused ? "bg-primary-50 !border-primary-500" : ""
    }`;
  };

  const getStatusStyles = () => {
    switch (props.status) {
      case "success":
        return `!border-success-500 bg-success-50 [&>input]:text-grey-700`;

      case "error":
        return `!border-error-500 bg-error-50 [&>input]:text-grey-700`;

      default:
        return `[&>input]:text-grey-700`;
    }
  };

  const getSizeStyles = () => {
    switch (props.sizes) {
      case "Large":
        return `p-[14px]`;

      case "Small":
        return `px-[14px] py-3`;

      default:
        return `p-[14px]`;
    }
  };

  const renderInputEnd = () => {
    if (props.icon) {
      return (
        <div
          className={`mr-2 [&>svg]:${
            props.disabled ? "text-grey-500" : "text-grey-700"
          }`}
        >
          {props.icon}
        </div>
      );
    } else if (props.value && !props.disabled) {
      if (props.type == "password") {
        return (
          <div onClick={() => setSecured(!secured)}>
            {secured ? <Show size={20} /> : <Hide size={20} />}
          </div>
        );
      } else if (props.onClear) {
        return (
          <img
            src={CloseSvg}
            onClick={() => {
              props.onClear();
            }}
          />
        );
      }
    } else {
      return <></>;
    }
  };

  return (
    <div
      className={Classes([
        props.status ? getStatusStyles() : "",
        getDefaultStyles(),
        getSizeStyles(),
      ])}
    >
      <input
        {...props}
        className={`${
          props.sizes == "Small"
            ? "leading-4 h-4 text-xs"
            : "leading-5 h-5 text-sm"
        } bg-transparent border-0 flex-grow align-middle focus:outline-none placeholder-grey-500 ${
          props.disabled ? "text-grey-500" : "text-grey-800"
        }`}
        style={{ maxWidth: "100%" }}
        type={
          props.type == "password"
            ? secured
              ? "password"
              : "text"
            : props.type
        }
        onFocus={(e) => {
          setFocused(true);
          props.onFocus && props.onFocus(e);
        }}
        onBlur={(e) => {
          setFocused(false);
          props.onBlur && props.onBlur(e);
        }}
      />
      {renderInputEnd()}
    </div>
  );
};

export default Input;
