import { AxiosCustomRequestConfig, http } from "../../services/Api";

interface keyable {
  [key: string]: any;
}

export const createCreditCard = async ({ body }: AxiosCustomRequestConfig) => {
  const response = http.post("/payment/credit-card/", body);
  const data = (await response).data;
  return data;
};

export const getCreditCards = async () => {
  const response = http.get("/payment/credit-card/");
  const data = (await response).data;
  return data;
};

export const deleteCreditCard = async (id: number) => {
  const response = http.delete(`/payment/credit-card/${id}`);
  const data = (await response).data;
  return data;
};

export const updateCreditCard = async ({
  params,
  body,
}: AxiosCustomRequestConfig) => {
  const response = http.put(`/payment/credit-card/${params.id}/`, body);
  const data = (await response).data;
  return data;
};

export const getPaymentMethods = async ({
  params,
}: AxiosCustomRequestConfig) => {
  const response = http.get("/payment/payment-methods/", { params });
  const data = (await response).data;
  return data;
};

export const createOfflinePayment = async ({
  body,
}: AxiosCustomRequestConfig) => {
  const response = http.post("/payment/offline-payment/", body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  const data = (await response).data;
  return data;
};

export const createCryptoPayment = async ({
  body,
}: AxiosCustomRequestConfig) => {
  const response = http.post("/payment/cryptocurrency-payment/", body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  const data = (await response).data;
  return data;
};

export const getPaymentList = async ({ params }: AxiosCustomRequestConfig) => {
  const response = http.get("/payment/payment/", { params });
  const data = (await response).data;
  return data;
};

export const getOfflinePaymentList = async ({ params }: AxiosCustomRequestConfig) => {
  const response = http.get("/payment/offline-transaction/", { params });
  const data = (await response).data;
  return data;
};

export const payWithWallet = async () => {
  const response = http.post("/payment/pay-order-with-wallet/");
  const data = (await response).data;
  return data;
};
