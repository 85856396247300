import { useMutation, useQuery } from "@tanstack/react-query";
import { Trash } from "iconsax-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Edit } from "react-iconly";
import Skeleton from "react-loading-skeleton";
import { OrderApi, PaymentApi } from "src/api";
import { UsaFlag } from "src/assets";
import { ColorPalette, Helpers } from "src/utils";
import { ICartItemInterface, IPaymentDetail } from "../Desktop/types";
import {
  AggreementAlert,
  EmptyCart,
  MobileOrderCard,
  PaymentDetailFloat,
} from "../partials";
import { toast } from "react-toastify";
import { ToastMsg } from "src/shared";
import { AxiosError } from "axios";

type Props = {};

const Mobile = (props: Props) => {
  const { i18n, t } = useTranslation("cart/ts");

  const { data: checkoutInfo, refetch } = useQuery({
    queryKey: ["[Info] Checkout"],
    queryFn: () =>
      OrderApi.getCheckoutInfo().then((result) => {
        return result;
      }),
    cacheTime: 0,
  });

  const {
    data,
    isLoading: isQueryLoading,
    status,
  } = useQuery({
    queryKey: ["[List] Cart"],
    queryFn: () =>
      OrderApi.getCartItems().then((result) => {
        return result.results;
      }),
  });

  const result = React.useMemo(() => {
    if (!isQueryLoading && data && status === "success") {
      if (data.length > 0) {
        const { adwords_cart_item, ...rest } = data[0];
        return adwords_cart_item.map(
          (item: ICartItemInterface, index: number) => ({
            ...item,
            index,
          })
        );
      }
      return [];
    } else {
      return [];
    }
  }, [data]);

  if (result.length == 0 && !isQueryLoading) {
    return <EmptyCart />;
  } else {
    return (
      <div className="w-full pb-10">
        <AggreementAlert />
        {checkoutInfo && <PaymentDetailFloat checkoutInfo={checkoutInfo} />}
        <div className="w-full flex flex-col items-center gap-4 rounded-[20px] border border-grey-200 py-5 px-[14px] ">
          {!isQueryLoading
            ? result.map((item: ICartItemInterface, index: number) => (
                <MobileOrderCard
                  item={item}
                  bordered={index != result.length - 1}
                  index={index}
                />
              ))
            : [1, 2, 3, 4].map(() => (
                <div className="w-full">
                  <Skeleton
                    height={162}
                    width={"100%"}
                    style={{ marginBottom: 12 }}
                  />
                </div>
              ))}
        </div>
      </div>
    );
  }
};

export default Mobile;
