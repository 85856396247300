import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ArrowLeft,
  ArrowRight,
  ChevronLeft,
  ChevronRight,
  Edit,
  Home,
  Lock,
} from "react-iconly";
import { Link, useNavigate } from "react-router-dom";
import { Button, IconButton } from "src/components";
import { useAuthStore } from "src/store";
import { ColorPalette } from "src/utils";
import ChangePassModal from "../ChangePassModal";

type Props = {};

const Header = (props: Props) => {
  const { i18n, t } = useTranslation("profile/ts");

  const { user } = useAuthStore();
  const navigate = useNavigate()

  const [passModal, setPassModal] = useState<boolean>(false);

  const hasName = () =>
    (user.first_name && user.first_name.length > 0) ||
    (user.last_name && user.last_name.length > 0);

  return (
    <div className="w-full flex flex-wrap justify-between items-center mb-5 gap-5">
      <ChangePassModal setIsOpen={setPassModal} isOpen={passModal} />
      <div className="rounded-lg border border-grey-200 p-3 flex items-center gap-2">
        <Link to="/dashboard">
          <Home size={24} primaryColor={ColorPalette.grey["600"]} />
        </Link>
        {i18n.dir() == "rtl" ? (
          <ChevronLeft size={24} primaryColor={ColorPalette.grey["600"]} />
        ) : (
          <ChevronRight size={24} primaryColor={ColorPalette.grey["600"]} />
        )}
        <span className="text-sm text-grey-600">{t("profile")}</span>
        {i18n.dir() == "rtl" ? (
          <ChevronLeft size={24} primaryColor={ColorPalette.grey["600"]} />
        ) : (
          <ChevronRight size={24} primaryColor={ColorPalette.grey["600"]} />
        )}
        <span className="text-sm text-grey-800 font-semibold">
          {hasName() ? `${user?.first_name} ${user?.last_name}` : t("you")}
        </span>
      </div>
      <div className="flex items-center gap-3">
        <IconButton
          bgColor="bg-primary-500"
          bgColorHovered="hover:bg-primary-700"
          ringColor="ring:bg-primary-200"
          icon={<Edit size={24} primaryColor={ColorPalette.grey["50"]} />}
          size={"Large"}
          onClick={() => navigate('/profile/edit')}
        />
        <Button
          type="Orange"
          icon={<Lock size={24} primaryColor={ColorPalette.grey["50"]} />}
          onClick={() => setPassModal(true)}
        >
          {t("change_pass")}
        </Button>
      </div>
    </div>
  );
};

export default Header;
