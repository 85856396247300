import { useQuery } from "@tanstack/react-query";
import { Minus } from "iconsax-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoCircle } from "react-iconly";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import { OrderApi } from "src/api";
import { TimeLineFilledSvg, TimeLineSvg, UsaFlag } from "src/assets";
import { ColorPalette } from "src/utils";
import { OrderInfo, OrderTimeLine } from "./partials";

type Props = {};

const Single = (props: Props) => {
  const { t, i18n } = useTranslation("orders/ts");

  const { id } = useParams();

  const [tabIndex, setTabIndex] = useState<number>(0);

  return (
    <div className="w-full">
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList
          className="border-0 p-3 rounded-2xl bg-primary-50 flex items-center outline:none mb-5"
          style={{ width: "fit-content" }}
        >
          <Tab
            style={{
              background:
                tabIndex == 0 ? ColorPalette.primary["100"] : "transparent",
            }}
            className="after:d-none cursor-pointer py-2 px-5 flex items-center gap-2 !rounded-lg outline:none"
          >
            <InfoCircle
              filled={tabIndex == 0}
              size={20}
              primaryColor={
                tabIndex == 0
                  ? ColorPalette.primary["500"]
                  : ColorPalette.grey["700"]
              }
            />
            <span
              className={`text-sm ${
                tabIndex == 0 ? "text-primary-500 font-bold" : "text-grey-700"
              }`}
            >
              {t("order_detail")}
            </span>
          </Tab>
          <Tab
            style={{
              background:
                tabIndex == 1 ? ColorPalette.primary["100"] : "transparent",
            }}
            className="after:d-none cursor-pointer py-2 px-5 flex items-center gap-2 !rounded-lg outline:none"
          >
            <img
              src={tabIndex == 1 ? TimeLineFilledSvg : TimeLineSvg}
              className="w-5 h-5"
            />
            <span
              className={`text-sm ${
                tabIndex == 1 ? "text-primary-500 font-bold" : "text-grey-700"
              }`}
            >
              {t("timeline")}
            </span>
          </Tab>
        </TabList>

        <TabPanel>
          <OrderInfo />
        </TabPanel>
        <TabPanel>
          <OrderTimeLine />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Single;
