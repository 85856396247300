import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Swap } from "react-iconly";
import Skeleton from "react-loading-skeleton";
import { WalletApi } from "src/api";
import { UsaFlag } from "src/assets";
import { ColorPalette } from "src/utils";
import { ConvertWallet } from "./partials";
import { useSearchParams } from "react-router-dom";

type Props = {};

const CreditSec = (props: Props) => {
  const { t } = useTranslation("wallet/ts");

  const [convertWalletModal, setConvertWalletModal] = useState<boolean>(false);
  const [ballance, setBallance] = useState<number>(0);

  const [searchParams] = useSearchParams();

  const { data, refetch } = useQuery({
    queryKey: ["[List] Wallet"],
    queryFn: () =>
      WalletApi.getUserWallets({}).then((result) => {
        return result.results;
      }),
    cacheTime: 0,
    enabled: false,
  });

  useEffect(() => {
    !convertWalletModal && refetch();
  }, [convertWalletModal]);

  return (
    <div className="rounded-[20px] border border-grey-200 p-5 mb-5">
      <ConvertWallet
        isOpen={convertWalletModal}
        setIsOpen={setConvertWalletModal}
        ballance={ballance}
      />
      <h4 className="text-lg mb-5">{t("wallet_credit")}</h4>
      <div className="w-full grid grid-cols-12 items-center gap-x-4 gap-y-5">
        {data
          ? data.map((wallet: any) => (
              <div className="col-span-6 lg:col-span-3">
                <div className="w-full">
                  <div className="px-4 py-5 rounded-[20px] bg-grey-50">
                    <div className="flex-1 flex justify-between">
                      <div className="flex items-center mb-[30px] gap-[10px]">
                        <img
                          src={wallet.currency.icon}
                          className="w-6 h-6 rounded-full"
                        />
                        <span className="text-grey-700 text-md font-semibold">
                          {t(wallet?.currency?.name)}
                        </span>
                      </div>
                      {wallet.currency.name == "IRR" && (
                        <div
                          onClick={() => {
                            setConvertWalletModal(true);
                            setBallance(wallet.balance);
                          }}
                        >
                          <Swap
                            style={{ transform: "rotate(90deg)" }}
                            size={24}
                            primaryColor={ColorPalette.grey["600"]}
                          />
                        </div>
                      )}
                    </div>
                    <div className="flex items-center justify-between">
                      <span className="text-base text-grey-800 font-bold">
                        {wallet.balance.toLocaleString()}
                      </span>
                      <span className="text-md text-grey-600 font-bold">
                        {wallet.currency.name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : [1, 2, 3, 4].map(() => (
              <div className="col-span-6 lg:col-span-3">
                <div className="w-full">
                  <Skeleton height={118} width={"100%"} />
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default CreditSec;
