import React, { lazy } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import {
  ForgotForm,
  LoginForm,
  LoginOtpForm,
  RegisterForm,
} from "../Forms";

import { ForgotBg, LoginBg } from "src/assets";
import { Header } from "./partials";

type Props = {};

const bodyStyles = (bg: string) => ({
  background: `url(${bg})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPositionX: "center",
  backgroundPositionY: "-20px",
});

const Desktop = (props: Props) => {
  const { pathname } = useLocation();

  return (
    <main
      className="px-[30px] flex flex-col w-full h-full bg-center bg-no-repeat"
      style={bodyStyles(pathname.includes("forgot") ? ForgotBg : LoginBg)}
    >
      <Header />
      <div className="flex flex-1 justify-center items-center py-5">
        <div className="bg-white rounded-[20px] h-full w-full min-w-[641px] max-w-[46%] shadow-authform py-[30px] px-5">
          <Routes>
            <Route path="/" element={<Navigate to="/auth/login" replace />} />
            <Route path="/register" element={<RegisterForm />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/login-otp" element={<LoginOtpForm />} />
            <Route path="/forgot" element={<ForgotForm />} />
          </Routes>
        </div>
      </div>
    </main>
  );
};

export default Desktop;
