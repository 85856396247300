import { useQuery } from "@tanstack/react-query";
import { Minus } from "iconsax-react";
import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { OrderApi } from "src/api";
import { ColorPalette } from "src/utils";

type Props = {};

enum ORDER_STATUS {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
  REFUNDED = "REFUNDED",
}

const OrderInfo = (props: Props) => {
  const { t, i18n } = useTranslation("orders/ts");

  const { id } = useParams();

  const { data, isLoading, status, refetch } = useQuery({
    queryKey: [`[Single-${id}] Payment`],
    queryFn: () =>
      OrderApi.getSingleOrder({
        params: { id },
      }).then((result) => {
        return result;
      }),
  });

  const getOrderStatusT = (status: string) => {
    switch (status) {
      case ORDER_STATUS.DRAFT:
        return t("draft");
      case ORDER_STATUS.PENDING:
        return t("pending");
      case ORDER_STATUS.PROCESSING:
        return t("processing");
      case ORDER_STATUS.COMPLETED:
        return t("completed");
      case ORDER_STATUS.CANCELED:
        return t("canceled");
      case ORDER_STATUS.REFUNDED:
        return t("refunded");

      default:
        break;
    }
  };

  const getOrderStatusColor = (status: string) => {
    switch (status) {
      case ORDER_STATUS.DRAFT:
        return "bg-grey-50 text-grey-500";
      case ORDER_STATUS.PENDING:
        return "bg-primary-50 text-primary-500";
      case ORDER_STATUS.PROCESSING:
        return "bg-warning-50 text-warning-500";
      case ORDER_STATUS.COMPLETED:
        return "bg-success-50 text-success-500";
      case ORDER_STATUS.CANCELED:
        return "bg-error-50 text-error-500";
      case ORDER_STATUS.REFUNDED:
        return "bg-secondary-50 text-secondary-500";

      default:
        break;
    }
  };

  const result = React.useMemo(() => {
    if (!isLoading && data && status === "success") {
      const result = data
        ? data.adwords_order_detail.length > 0
          ? data.adwords_order_detail[0]
          : {}
        : {};
      return { ...data, ...result };
    }
  }, [data]);

  return (
    <div className="w-full mt-12">
      {result ? (
        <div className="w-full pt-10 px-[14px] pb-5 border border-grey-200 rounded-[20px] relative mt-[30px] mb-[58px]">
          <div className="absolute top-[-24px] p-3 gap-2 border border-grey-200 rounded-lg bg-white flex items-center">
            <Minus size={24} color={ColorPalette.grey["800"]} />
            <span className="text-grey-800 text-sm">{t("order_details")}</span>
          </div>
          <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
            <span className="text-grey-700 text-xs lg:text-sm">
              {t("order_number")}
            </span>
            <span className="text-grey-800 text-sm font-semibold">
              {data?.id}
            </span>
          </div>
          <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
            <span className="text-grey-700 text-xs lg:text-sm">
              {t("account_number")}
            </span>
            <span className="text-grey-800 text-sm font-semibold">
              {result?.account}
            </span>
          </div>
          <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
            <span className="text-grey-700 text-xs lg:text-sm">
              {t("request_date")}
            </span>
            <span className="text-grey-800 text-sm font-semibold">
              {data?.created_date}
            </span>
          </div>
          <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
            <span className="text-grey-700 text-xs lg:text-sm">
              {t("currency")}
            </span>
            <span className="text-grey-800 text-sm font-semibold flex items-center">
              <img
                src={result?.currency?.icon}
                className="w-6 h-6"
                style={{
                  margin: i18n.dir() == "rtl" ? "0 0 0 8px" : "0 8px 0 0",
                }}
              />
              <span className="text-sm text-grey-700">
                {result?.currency?.name}
              </span>
            </span>
          </div>
          <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
            <span className="text-grey-700 text-xs lg:text-sm">
              {t("amount")}
            </span>
            <span className="text-grey-800 text-sm font-semibold">
              {result?.amount}
            </span>
          </div>
          <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
            <span className="text-grey-700 text-xs lg:text-sm">
              {t("payment_date")}
            </span>
            <span className="text-grey-800 text-sm font-semibold">-</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-grey-700 text-xs lg:text-sm">
              {t("status")}
            </span>
            <span className="text-grey-800 text-sm font-semibold">
              <div
                className={`px-3 py-[5px] ${getOrderStatusColor(
                  data.status
                )} rounded-lg text-xs text-center`}
              >
                {getOrderStatusT(data.status)}
              </div>
            </span>
          </div>
        </div>
      ) : (
        <Skeleton height={384} className="mb-3" />
      )}
      {result ? (
        <div className="w-full pt-10 px-[14px] pb-5 border border-grey-200 rounded-[20px] relative mt-[30px]">
          <div className="absolute top-[-24px] p-3 gap-2 border border-grey-200 rounded-lg bg-white flex items-center">
            <Minus size={24} color={ColorPalette.grey["800"]} />
            <span className="text-grey-800 text-sm">{t("order_details")}</span>
          </div>
          <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
            <span className="text-grey-700 text-xs lg:text-sm">
              {t("currency_price")}
            </span>
            <span className="text-grey-600 text-xs">
              <strong className="text-grey-800 text-sm font-semibold">
                {result?.currency?.last_price.toLocaleString()}
              </strong>{" "}
            </span>
          </div>
          <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
            <span className="text-grey-700 text-xs lg:text-sm">
              {t("price")}
            </span>
            <span className="text-grey-600 text-xs">
              <strong className="text-grey-800 text-sm font-semibold">
                5,300,000
              </strong>{" "}
              {`${t("toman")} `}
            </span>
          </div>
          {/* <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
            <span className="text-grey-700 text-xs lg:text-sm">
              {t("discount")}
            </span>
            <span className="text-error-300 text-xs">
              <strong className="text-error-400 text-sm font-semibold">
                -15,000
              </strong>{" "}
              {`${t("toman")} `}
            </span>
          </div> */}
          <div className="w-full flex justify-between items-center pb-4 mb-[14px] border-b border-b-grey-200">
            <h6 className="text-sm text-grey-700">{t("wage")}</h6>
            <span className="text-xs text-grey-600">
              <strong className="text-base font-semibold text-grey-800">
                {result?.wage}
              </strong>{" "}
              %
            </span>
          </div>
          <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
            <span className="text-grey-700 text-xs lg:text-sm">
              {t("total")}
            </span>
            <span className="text-xs text-grey-600 py-1 px-2 bg-secondary-50 rounded-lg">
              <strong className="text-sm font-bold text-secondary-500">
                5,663,600
              </strong>{" "}
              {t("toman")}
            </span>
          </div>
        </div>
      ) : (
        <Skeleton height={264} />
      )}
    </div>
  );
};

export default OrderInfo;
