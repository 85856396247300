import React, { Dispatch, SetStateAction } from "react";
import { CloseCircle } from "iconsax-react";
import ModalComp from "react-modal";
import { CloseSvg, UpdateSvg } from "src/assets";
import { useMediaQuery } from "src/hooks";
import { ColorPalette } from "src/utils";
import { useTranslation } from "react-i18next";
import { Button } from "src/components";

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onUpdate: () => void;
};

const UpdateAppModal = (props: Props) => {
  const isMobile = useMediaQuery("(max-width:1023px)");

  const { t } = useTranslation("dashboard/ts");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "90%",
      maxWidth: 500,
      borderRadius: 20,
      padding: 0,
      display: "flex",
    },
    overlay: {
      background: "rgba(23, 26, 28, 0.85)",
      zIndex: 10000000,
    },
  };

  return (
    <ModalComp
      isOpen={props.isOpen}
      onRequestClose={() => props.setIsOpen(false)}
      style={customStyles}
      closeTimeoutMS={500}
    >
      <div className="w-full py-4 px-[14px] flex flex-col items-center">
        <img src={UpdateSvg} className="mb-5" />
        <h4 className="text-sm text-grey-800 mb-2">{t("update_available")}</h4>
        <p className="text-xs text-grey-700 mb-[29px]">
          {t("update_available_desc")}
        </p>
        <div className="w-full flex items-center gap-3">
          <div className="flex-1">
            <Button
              type="Secondary"
              addonClasses="w-full"
              onClick={() => props.setIsOpen(false)}
              size={isMobile ? "Small" : "Medium"}
            >
              {t("later")}
            </Button>
          </div>
          <div className="flex-1">
            <Button
              addonClasses="w-full"
              onClick={() => {
                // @ts-ignore
                window.location.reload(true);
              }}
              size={isMobile ? "Small" : "Medium"}
            >
              {t("update")}
            </Button>
          </div>
        </div>
      </div>
    </ModalComp>
  );
};

export default UpdateAppModal;
