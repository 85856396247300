import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "src/hooks";

import * as Yup from "yup";
import { useFormik } from "formik";
import { PaymentApi } from "src/api";
import { toast } from "react-toastify";
import { FormField, ToastMsg } from "src/shared";
import { AxiosError } from "axios";
import { serialize } from "object-to-formdata";
import { Button, IconButton, Input, RadioButton } from "src/components";
import { ChevronDown, ChevronUp, Plus } from "react-iconly";
import { ColorPalette, Helpers } from "src/utils";
import { Collapse } from "react-collapse";
import { IFormValues } from "./types";
import { Clipboard } from "iconsax-react";
import { CopySvg } from "src/assets";

type Props = {
  data: Helpers.keyable;
  onFinish: () => void;
  amount: number;
};

const initialValues: IFormValues = {
  image: undefined,
  tracking_code: undefined,
  cryptocurrency: undefined,
};

const CryptoSec = (props: Props) => {
  const { t, i18n } = useTranslation("wallet/ts");
  const { t: validationTranslations } = useTranslation("validation/ts");

  const [isOpen, setIsOpen] = useState(true);
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width: 1023px)");

  const validationSchema = Yup.object({
    tracking_code: Yup.string()
      .max(128)
      .required(validationTranslations("field_required")),
    image: Yup.mixed().required(validationTranslations("field_required")),
    cryptocurrency: Yup.mixed().required(
      validationTranslations("field_required")
    ),
  });

  const createPaymentMutuate = useMutation(PaymentApi.createCryptoPayment, {
    onSuccess: (res) => {
      toast(<ToastMsg type="success" message={t("success_payment")} />);
      queryClient.invalidateQueries({
        queryKey: ["[List] Wallet Transcation"],
      });
      props.onFinish();
    },
    onError: (error: AxiosError) => {
      if (error.response.status == 400) {
        toast(<ToastMsg type="error" message={t("error_payment")} />);
      }
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) =>
      createPaymentMutuate.mutate({
        body: serialize({
          ...values,
          cryptocurrency: values.cryptocurrency.id,
          cryptocurrency_amount: values.cryptocurrency.amount,
          amount: props.amount,
        }),
      }),
    validateOnChange: true,
    validateOnMount: true,
  });

  return (
    <div className="w-full">
      <div className="w-full h-full flex flex-col justify-between">
        <div className="w-full">
          <input
            type={"file"}
            accept="image/png, image/jpeg, image/jpg"
            className="hidden"
            id="payment-img-inp"
            multiple={false}
            onChange={(e) => {
              if (
                ["image/png", "image/jpeg", " image/jpg"].includes(
                  e.target.files[0].type
                )
              ) {
                formik.setFieldValue("image", e.target.files[0]);
              } else {
                formik.setFieldError(
                  "image",
                  validationTranslations("valid_pic")
                );
              }
            }}
          />
          <div className="px-4 py-5 rounded-[20px] bg-grey-50 mb-5">
            <div
              className="flex justify-between items-center"
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <h4 className="text-base font-semibold text-grey-800">
                {t("currency")}:
              </h4>
              {isOpen ? (
                <ChevronUp size={20} primaryColor={ColorPalette.grey["700"]} />
              ) : (
                <ChevronDown
                  size={20}
                  primaryColor={ColorPalette.grey["700"]}
                />
              )}
            </div>
            <Collapse isOpened={isOpen}>
              <div className="mt-5 flex flex-col gap-[9px]">
                {props.data.currencies.map((item: Helpers.keyable) => (
                  <div
                    className={`flex items-center justify-between rounded-xl bg-grey-100 px-[10px] py-[14px] cursor-pointer`}
                    onClick={() => formik.setFieldValue("cryptocurrency", item)}
                  >
                    <div className="flex items-center gap-3">
                      <RadioButton
                        label=""
                        checked={
                          formik.values.cryptocurrency &&
                          formik.values.cryptocurrency.id == item.id
                        }
                      />
                      <div className="gap-2 flex items-center">
                        <img src={item.icon} width={24} />
                        <span className="text-sm text-grey-700 font-semibold">
                          {item.name}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Collapse>
          </div>
          {formik.values.cryptocurrency && (
            <div className="px-4 py-5 flex justify-between items-center rounded-[20px] bg-grey-50 mb-5">
              <h4 className="text-base font-semibold text-grey-800">
                {t("wallet_id")}
              </h4>
              <div className="flex items-center gap-3 text-sm font-semibold text-grey-800">
                {formik.values?.cryptocurrency?.wallet}
                <img
                  src={CopySvg}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      formik.values?.cryptocurrency?.wallet
                    );
                    toast(<ToastMsg type="success" message={t("copied")} />);
                  }}
                />
              </div>
            </div>
          )}
          {formik.values.cryptocurrency && (
            <div className="px-4 py-5 flex justify-between items-center rounded-[20px] bg-success-50 mb-5">
              <h4 className="text-base font-semibold text-grey-800">
                {t("required_currency")}
              </h4>
              <span className="text-sm font-semibold text-grey-800">
                {formik.values.cryptocurrency.amount}
              </span>
            </div>
          )}
          <div className="w-full mb-[14px]">
            <FormField
              label={t("tracking_code")}
              field={
                <Input
                  name="tracking_code"
                  type={"tx_id"}
                  placeholder={t("enter_tx_id")}
                  sizes={isMobile ? "Small" : "Large"}
                  onChange={(e) =>
                    formik.setFieldValue("tracking_code", e.target.value)
                  }
                  onFocus={() => formik.setFieldTouched("tracking_code", true)}
                  value={formik.values.tracking_code}
                  autoComplete={"on"}
                  onClear={() => formik.setFieldValue("tracking_code", "")}
                />
              }
              message={
                formik.errors.tracking_code &&
                formik.touched.tracking_code && {
                  type: "ERROR",
                  content: formik.errors.tracking_code,
                }
              }
            />
          </div>
          <div className="gap-3 flex items-start mb-[30px]">
            <div className="flex-1">
              <FormField
                label={""}
                field={
                  <Input
                    name="image"
                    type={"text"}
                    placeholder={t("enter_image")}
                    sizes={isMobile ? "Small" : "Large"}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "image",
                        formik.values.image ? formik.values.image.name : ""
                      )
                    }
                    readOnly={true}
                    onFocus={() => formik.setFieldTouched("image", true)}
                    value={
                      formik.values.image ? formik.values.image.name : null
                    }
                  />
                }
                message={
                  formik.errors.image &&
                  formik.touched.image && {
                    type: "ERROR",
                    content: formik.errors.image,
                  }
                }
              />
            </div>
            <div className="mt-2">
              <IconButton
                bgColor="bg-success-500"
                bgColorHovered="hover:bg-success-700"
                ringColor="focus:ring focus:ring-success-200"
                icon={<Plus size={24} primaryColor={ColorPalette.grey["50"]} />}
                size={isMobile ? "Medium" : "Large"}
                onClick={() => {
                  let inp: HTMLElement = document.querySelector(
                    "#payment-img-inp"
                  ) as HTMLElement;
                  inp.click();
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex-1 gap-3 flex items-center">
            <a
              href="#"
              className={`w-[42px] h-[42px] flex justify-center items-center p-[8px] border border-primary-300 border-dashed rounded-[100px]`}
            >
              <span className="text-[10px] text-grey-600">
                <strong className="text-sm text-grey-800">2</strong>/2
              </span>
            </a>
            <Button
              addonClasses="w-full"
              onClick={() => formik.submitForm()}
              loading={createPaymentMutuate.isLoading}
              disabled={!formik.isValid}
            >
              {t("pay")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CryptoSec;
