import React from "react";
import styles from "./styles.module.css";

interface IRadioButtonInterface
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const RadioButton = (props: IRadioButtonInterface) => {
  return (
    <label
      className={`${styles.radio} ${
        props.disabled ? "text-grey-500" : "text-grey-700"
      } text-[14px] ${props.checked ? "text-primary-500 !font-semibold" : ""}`}
    >
      <input type="radio" {...props} />
      {props.label}
    </label>
  );
};

export default RadioButton;
