import React from "react";
import { useTranslation } from "react-i18next";
import { Send } from "react-iconly";
import { Link } from "react-router-dom";
import { MessagePin, SampleAvatar } from "src/assets";
import { Input } from "src/components";
import { useMediaQuery } from "src/hooks";
import { ColorPalette } from "src/utils";
import MessageCard from "../MessageCard";

type Props = {};

const MessageTab = (props: Props) => {
  const { t } = useTranslation("tickets/ts");

  const isMobile = useMediaQuery("(max-width: 1023px)");

  return (
    <div className="w-full h-full rounded-[20px] border border-grey-200 p-5 flex flex-col items-ceter">
      <div className="pb-[14px] border-b border-b-grey-200 mb-[14px] flex items-center justify-between">
        <div className="flex items-center gap-[14px]">
          <img className="w-[30px] h-[30px] rounded-full" src={SampleAvatar} />
          <span className="text-sm text-grey-700">luna karen</span>
        </div>
        <div className="py-[5px] px-3 bg-primary-50 rounded-lg text-xs text-primary-500">
          {t("in_process")}
        </div>
      </div>
      <div className="flex flex-col items-center justify-between flex-1">
        <div className="w-full flex flex-col items-center">
          <div
            className="py-[5px] px-3 bg-grey-50 rounded-lg text-xs text-grey-700 mb-5 self-center"
            style={{ width: "fit-content" }}
          >
            {t("today")}
          </div>
          {[1, 2, 3].map((item) => (
            <MessageCard type={item % 2 == 0 ? "me" : "support"} />
          ))}
        </div>
        <div className="w-full flex items-center gap-3">
          <div className="flex-1">
            <Input
              placeholder={t("enter_your_msg")}
              sizes={isMobile ? "Small" : "Large"}
              icon={<img src={MessagePin} />}
            />
          </div>
          <Link
            to="#"
            className="p-[9px] lg:p-3 bg-primary-500 rounded-lg flex items-center justify-center"
          >
            <Send size={24} primaryColor={ColorPalette.grey["50"]} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MessageTab;
