import { Banks } from "src/constants";

let I18nInstance: any;

export const SetI18nInstance = (i18n: any) => {
  I18nInstance = i18n;
};

export interface keyable {
  [key: string]: any;
}

export const ChangeLanguage = (lng: string) => {
  I18nInstance.changeLanguage(lng);
  localStorage.setItem("i18nextLng", lng);
  localStorage.setItem("i18nDir", lng == "en" ? "ltr" : "rtl");
};

export const DetectCreditBank = (num: string) => {
  const numbers = parseInt(num.substring(0, 6));
  return Banks.find((item) => item.card_no == numbers);
};

export function convertPerToEn(num: string) {
  const id = {
    "۰": "0",
    "۱": "1",
    "۲": "2",
    "۳": "3",
    "۴": "4",
    "۵": "5",
    "۶": "6",
    "۷": "7",
    "۸": "8",
    "۹": "9",
  };
  return num
    ? num.toString().replace(/[^0-9.]/g, function (w) {
        return id[w] || w;
      })
    : "";
}

export function waitForElm(selector: any) {
  return new Promise(resolve => {
      if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver(mutations => {
          if (document.querySelector(selector)) {
              observer.disconnect();
              resolve(document.querySelector(selector));
          }
      });

      observer.observe(document.body, {
          childList: true,
          subtree: true
      });
  });
}