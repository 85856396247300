import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CreditCards, Header, PersonalInfo } from "./partials";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { CardFillSvg, CardSvg } from "src/assets";
import { ColorPalette } from "src/utils";
import { Profile } from "iconsax-react";
import { useSearchParams } from "react-router-dom";

type Props = {};

const Root = (props: Props) => {
  const { i18n, t } = useTranslation("profile/ts");

  const [tabIndex, setTabIndex] = useState<number>(0);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("tab")) {
      switch (searchParams.get("tab")) {
        case "cards":
          setTabIndex(1);
          break;
        case "info":
          setTabIndex(0);
          break;
        default:
          break;
      }
    }
  }, [searchParams]);

  return (
    <div className="w-full flex flex-col">
      <Header />
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList
          className="border-0 p-3 rounded-2xl bg-primary-50 flex items-center outline:none mb-5"
          style={{ width: "fit-content" }}
        >
          <Tab
            style={{
              background:
                tabIndex == 0 ? ColorPalette.primary["100"] : "transparent",
            }}
            className="after:d-none cursor-pointer py-2 px-5 flex items-center gap-2 !rounded-lg outline:none"
          >
            <Profile
              variant={tabIndex == 0 ? "Bold" : "Outline"}
              size={20}
              color={
                tabIndex == 0
                  ? ColorPalette.primary["500"]
                  : ColorPalette.grey["700"]
              }
            />
            <span
              className={`text-sm ${
                tabIndex == 0 ? "text-primary-500 font-bold" : "text-grey-700"
              }`}
            >
              {t("personal_info")}
            </span>
          </Tab>
          <Tab
            style={{
              background:
                tabIndex == 1 ? ColorPalette.primary["100"] : "transparent",
            }}
            className="after:d-none cursor-pointer py-2 px-5 flex items-center gap-2 !rounded-lg outline:none"
          >
            <img
              src={tabIndex == 1 ? CardFillSvg : CardSvg}
              className="w-5 h-5"
            />
            <span
              className={`text-sm ${
                tabIndex == 1 ? "text-primary-500 font-bold" : "text-grey-700"
              }`}
            >
              {t("my_cards")}
            </span>
          </Tab>
        </TabList>

        <TabPanel>
          <PersonalInfo />
        </TabPanel>
        <TabPanel>
          <CreditCards />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Root;
