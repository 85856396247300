import { Google, Money } from "iconsax-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Calling,
  Category,
  Chart,
  Home,
  Bag,
  Logout,
  Notification,
  Paper,
  Setting,
} from "react-iconly";
import { useLocation } from "react-router-dom";
import { AuthApi } from "src/api";
import {
  GoogleFillSvg,
  GoogleSvg,
  LogoEn,
  LogoFa,
  MoneyFillSvg,
  MoneySvg,
} from "src/assets";
import { useAuthStore } from "src/store";
import MenuItem from "../MenuItem";

type Props = {};

const SideBar = (props: Props) => {
  const { i18n, t } = useTranslation("sidebar/ts");

  const { logoutUser, refresh } = useAuthStore();

  const styles = {
    boxShadow: "0px 2px 20px 0px rgba(46, 51, 56, 0.10)",
  };

  const [loading, setLodaing] = useState<boolean>(false);

  const logout = () => {
    setLodaing(true);
    AuthApi.postLogout({ body: { refresh } })
      .then((result) => {
        setLodaing(false);
        logoutUser();
      })
      .catch((err) => {
        setLodaing(false);
      });
  };

  return (
    <aside
      className="hidden h-screen col-span-0 lg:col-span-2 h-full bg-white lg:flex flex-col items-center py-5"
      style={styles}
    >
      <img
        src={i18n.language == "en" ? LogoEn : LogoFa}
        className="mb-[30px] px-5"
      />
      <div className="flex-1 w-full flex flex-col justify-between">
        <ul className="w-full p-0 flex flex-col ">
          <MenuItem path={"dashboard"} title={t("home")} icon={Home} />
          <MenuItem
            path={"accounts"}
            title={t("account")}
            customIcon={{ simple: GoogleSvg, fill: GoogleFillSvg }}
          />
          <MenuItem path={"orders"} title={t("orders")} icon={Paper} />
          <MenuItem
            path={"payments"}
            title={t("financial")}
            customIcon={{ simple: MoneySvg, fill: MoneyFillSvg }}
            subMenu={[
              {
                name: t("payments"),
                path: "/payments",
              },
              {
                name: t("wallet"),
                path: "/wallet",
              },
              {
                name: t("payment_ids"),
                path: "/payment-ids",
              },
              {
                name: t("cards"),
                path: "/profile?tab=cards",
              },
            ]}
          />
          <MenuItem
            path={"support"}
            title={t("support")}
            icon={Calling}
            type="disabled"
          />
        </ul>
        <ul className="w-full p-0 flex flex-col ">
          <MenuItem path={"setting"} title={t("setting")} icon={Setting} />
          <MenuItem
            type="disabled"
            path={"notifications"}
            title={t("notifications")}
            icon={Notification}
          />
          <MenuItem
            type="danger"
            path={"logout"}
            title={t("logout")}
            icon={Logout}
            onClick={logout}
            loading={loading}
          />
        </ul>
      </div>
    </aside>
  );
};

export default SideBar;
