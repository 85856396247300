import { Popover, Transition } from "@headlessui/react";
import { ArrowDown2, CloseCircle, SearchNormal1 } from "iconsax-react";
import React, { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseSvg } from "src/assets";
import { Countries } from "src/constants";

import { ColorPalette, Helpers } from "src/utils";

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode;
  status?: "success" | "error";
  sizes?: "Large" | "Small";
  value?: string;
  disabled?: boolean;
  onChangeText: (val: string, code: string) => void;
  onClear?: () => void;
  selectedFlag?: string;
}

const Classes = (list: any) => {
  return list.filter(Boolean).join(" ");
};

const PhoneInput = (props: IInputProps) => {
  const { i18n } = useTranslation();

  const [focused, setFocused] = useState<Boolean>(false);
  const [selectedFlag, setSelectedFlag] = useState<number>(
    Countries.find((item) => item.code == props.selectedFlag)?.id || 98
  );

  const InputRef = useRef<HTMLInputElement>(null);

  const getDefaultStyles = () => {
    return `border-grey-200 rounded-lg flex items-center border [&>svg]:text-grey-700 transition-all duration-300 ${
      props.disabled ? "bg-grey-200 [&>svg]:text-grey-500" : ""
    } ${focused ? "bg-primary-50 !border-primary-500" : ""}`;
  };

  const getStatusStyles = () => {
    switch (props.status) {
      case "success":
        return `!border-success-500 bg-success-50 [&>input]:text-grey-700`;

      case "error":
        return `!border-error-500 bg-error-50 [&>input]:text-grey-700`;

      default:
        return `[&>input]:text-grey-700`;
    }
  };

  const getSizeStyles = () => {
    switch (props.sizes) {
      case "Large":
        return `p-[14px]`;

      case "Small":
        return `px-[14px] pt-3 pb-[11.28px] `;

      default:
        return `p-[14px]`;
    }
  };

  return (
    <div
      className={Classes([
        props.status ? getStatusStyles() : "",
        getDefaultStyles(),
        getSizeStyles(),
      ])}
    >
      <Popover
        className={`relative  ${
          i18n.dir() == "rtl"
            ? "border-l-grey-200 border-l"
            : "border-r-grey-200 border-r"
        } flex items-center`}
      >
        {({ open, close }) => (
          <>
            <Popover.Button className="focus:outline-none w-full">
              {selectedFlag && (
                <div
                  className={`${
                    i18n.dir() == "rtl" ? "ml-4" : "mr-4"
                  } flex items-center gap-2`}
                >
                  <ArrowDown2 size={20} color={ColorPalette.grey["700"]} />
                  <img
                    src={
                      Countries.find((item) => item.id == selectedFlag)?.flag
                    }
                    style={{ width: 20, height: 20 }}
                    className="mr-2"
                  />
                  <span
                    className={"whitespace-nowrap text-[14px] text-grey-700"}
                  >
                    {`${
                      Countries.find((item) => item.id == selectedFlag)?.name
                    } (${
                      Countries.find((item) => item.id == selectedFlag)?.code
                    })`}
                  </span>
                </div>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className="absolute z-10 mt-6 w-full -translate-x-1/2 transform"
                style={{ width: "calc(100% + 14px)", left: "45%" }}
              >
                <div className="overflow-hidden rounded-lg shadow-lg border border-grey-200 py-5 bg-white">
                  {/* <div className="w-full px-[9.6px] mb-[14px]">
                    <div className="w-full rounded-md border border-grey-200 py-2 pl-[14px] pr-2 flex justify-between items-center">
                      <input
                        placeholder="Search"
                        className="focus:outline-none flex-1"
                      />
                      <button className="p-[10px] bg-primary-500 rounded-md">
                        <SearchNormal1 size={16} color={"white"} />
                      </button>
                    </div>
                  </div> */}
                  <div>
                    {Countries.map((country, index) => (
                      <div
                        className={`w-full py-2 px-4 flex items-center cursor-pointer ${
                          selectedFlag == country.id ? "bg-primary-50" : ""
                        }`}
                        onClick={() => {
                          setSelectedFlag(country.id);
                          close();
                          setTimeout(() => {
                            InputRef?.current?.focus();
                          }, 100);
                        }}
                      >
                        <img
                          src={country.flag}
                          style={{ width: 20, height: 20 }}
                          className="mr-2"
                        />
                        <span
                          className={`text-[14px] ${
                            selectedFlag == country.id
                              ? "text-primary-500 font-semibold"
                              : "text-grey-700"
                          }`}
                        >
                          {`${country.name} (${country.code})`}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <input
        {...props}
        ref={InputRef}
        className={`bg-transparent border-0 flex-1 align-middle focus:outline-none placeholder-grey-500 ${
          props.disabled ? "text-grey-500" : "text-grey-800"
        } ${i18n.dir() == "rtl" ? "mr-3" : "ml-3"}`}
        onFocus={(e) => {
          setFocused(true);
          props.onFocus && props.onFocus(e);
        }}
        onBlur={() => setFocused(false)}
        onChange={(e) => {
          props.onChangeText(
            Helpers.convertPerToEn(e.target.value)
              .replace(/[^\d]/g, "")
              .replace(/^0+/, ""),
            Countries.find((item) => item.id == selectedFlag)?.code
          );
        }}
      />
      <div>
        {props.value && (
          <img
            src={CloseSvg}
            onClick={(e) => {
              e.preventDefault();
              props.onClear();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PhoneInput;
