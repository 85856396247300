import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PaymentApi, WalletApi } from "src/api";
import { RadioButton } from "src/components";
import { ColorPalette } from "src/utils";
import { CryptoSec, PaymentIdSec, ShebaSatna } from "./partials";
import { ClipLoader } from "react-spinners";

type Props = {
  formik: any;
  onFinish: () => void;
};

const Step2 = ({ formik, onFinish }: Props) => {
  const { t } = useTranslation("wallet/ts");

  const [selectedPayment, setSelectedPayment] = useState<string>();

  const { data: paymentMethods } = useQuery({
    queryKey: ["[List] Payment Method", formik.values.amount],
    queryFn: () =>
      PaymentApi.getPaymentMethods({
        params: {
          toman_amount: formik.values.amount,
        },
      }).then((result) => {
        return result;
      }),
    cacheTime: 0,
  });

  const getPaymentMethodTKey = (key: string) => {
    switch (key) {
      case "offline":
        return t("shebasatna");

      case "cryptocurrency":
        return t("crypto");

      case "payment_id":
        return t("payment_id");

      default:
        break;
    }
  };

  return (
    <div className="w-full h-full flex flex-col">
      {paymentMethods ? (
        <>
          <h4 className="mb-5 text-lg text-grey-800">{t("payment_method")}:</h4>
          <div className="w-full flex items-center flex-wrap gap-4 mb-6">
            {Object.keys(paymentMethods).map((key: string) => (
              <RadioButton
                label={t(getPaymentMethodTKey(key))}
                checked={selectedPayment == key}
                onChange={(e) => e.target.checked && setSelectedPayment(key)}
                disabled={!paymentMethods[key]["status"]}
              />
            ))}
          </div>
        </>
      ) : (
        <ClipLoader size={24} color={ColorPalette.primary["500"]} />
      )}
      <div className="flex flex-1">
        {selectedPayment == "payment_id" ? (
          <PaymentIdSec />
        ) : selectedPayment == "offline" ? (
          <ShebaSatna
            data={paymentMethods?.offline}
            amount={formik.values.amount}
            onFinish={onFinish}
          />
        ) : selectedPayment == "cryptocurrency" ? (
          <CryptoSec
            data={paymentMethods?.cryptocurrency}
            onFinish={onFinish}
            amount={formik.values.amount}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Step2;
