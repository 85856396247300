import { CloseCircle } from "iconsax-react";
import React from "react";
import ModalComp from "react-modal";
import { CloseSvg } from "src/assets";
import { useMediaQuery } from "src/hooks";
import { ColorPalette } from "src/utils";

type Props = {
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  showClose: boolean;
};

const Modal = (props: Props) => {
  const isMobile = useMediaQuery("(max-width:1023px)");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "90%",
      maxHeight: "95%",
      maxWidth: 704,
      borderRadius: 20,
      padding: 0,
      minHeight: 590,
      display: "flex",
    },
    overlay: {
      background: "rgba(23, 26, 28, 0.85)",
      zIndex: 10000000,
    },
  };

  return (
    <ModalComp
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      style={customStyles}
      closeTimeoutMS={500}
    >
      <div className="flex-1 flex justify-between flex-col overflow-y-scroll">
        <div className="w-full flex justify-between items-center py-4 px-[14px] lg:p-5 shadow-modalHeader">
          <h2 className="text-grey-800 text-sm lg:text-xl font-semibold">
            {props.title}
          </h2>
          <div>
            <img src={CloseSvg} onClick={props.onClose} />
          </div>
        </div>
        <div className="pt-5 pb-7 lg:py-5 px-[14px] lg:p-5 flex-1">
          {props.children}
        </div>
      </div>
    </ModalComp>
  );
};

Modal.defaultProps = {
  showClose: true,
};

export default Modal;
