import React from "react";
import OtpInputComp from "react-otp-input";
import { useMediaQuery } from "src/hooks";
import { ColorPalette } from "src/utils";

interface Props {
  numInputs: number;
  value?: any;
  onChange: (code: string) => void;
  error?: boolean;
}

const OtpInput = ({ ...compProps }: Props) => {
  const isMobile = useMediaQuery("(max-width: 1023px)");

  return (
    <OtpInputComp
      {...compProps}
      shouldAutoFocus={true}
      containerStyle={{
        width: "100%",
        justifyContent: "space-between",
        padding: "0 10px",
        direction: "ltr",
      }}
      inputStyle={{
        width: isMobile ? 50 : 80,
        height: isMobile ? 50 : 80,
        borderBottom: `1px solid ${ColorPalette.grey["100"]}`,
        borderBottomColor: compProps.error
          ? ColorPalette.error["300"]
          : ColorPalette.grey["100"],
        color: compProps.error
          ? ColorPalette.error["400"]
          : ColorPalette.grey["800"],
        outline: "none",
        fontWeight: 700,
        fontSize: 20,
      }}
      inputType="number"
      renderInput={(props) => <input {...props} />}
    />
  );
};

export default OtpInput;
