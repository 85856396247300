import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { Create, Root, Single } from "./screens";

const TicketsPage = () => {
  return (
    <Routes>
      <Route path="/" element={<Root />} />
      <Route path="/single/:id" element={<Single />} />
      <Route path="/create" element={<Create />} />
    </Routes>
  );
};

export default TicketsPage;
