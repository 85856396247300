import React from "react";
import { Pagination as PaginationComp } from "react-headless-pagination";
import { useTranslation } from "react-i18next";
import { ArrowLeft, ArrowRight, ChevronLeft, ChevronRight } from "react-iconly";
import { DoubleArrowLeft, DoubleArrowRight } from "src/assets";
import { ColorPalette } from "src/utils";

type Props = {
  totalPages?: number;
  currentPage?: number;
  onChangePage?: (page: number) => void;
};

const Pagination = ({
  totalPages = 10,
  currentPage = 1,
  onChangePage,
}: Props) => {
  const { i18n } = useTranslation();

  const renderButtons = () => {
    if (i18n.dir() == "rtl") {
      return (
        <>
          {currentPage - 2 > 0 && (
            <img
              src={DoubleArrowRight}
              className="cursor-pointer"
              onClick={() => onChangePage(currentPage - 2)}
            />
          )}
          {currentPage - 1 > 0 && (
            <PaginationComp.PrevButton
              className="mx-1"
              onClick={() => onChangePage(currentPage - 1)}
            >
              <ChevronRight size={20} primaryColor={ColorPalette.grey["700"]} />
            </PaginationComp.PrevButton>
          )}
          <nav className="flex justify-center">
            <ul className="flex items-center">
              <PaginationComp.PageButton
                activeClassName="bg-secondary-50 rounded-[10px] text-secondary-400"
                inactiveClassName="text-sm text-grey-700"
                className="p-[10px] w-[38px] h-[38px] flex justify-center items-center cursor-pointer"
              />
            </ul>
          </nav>
          {currentPage + 1 < totalPages && (
            <PaginationComp.NextButton
              className="mx-1"
              onClick={() => onChangePage(currentPage + 1)}
            >
              <ChevronLeft size={20} primaryColor={ColorPalette.grey["700"]} />
            </PaginationComp.NextButton>
          )}
          {currentPage + 2 < totalPages && (
            <img
              src={DoubleArrowLeft}
              className="cursor-pointer"
              onClick={() => onChangePage(currentPage + 2)}
            />
          )}
        </>
      );
    } else {
      return (
        <>
          {currentPage - 2 > 0 && (
            <img
              src={DoubleArrowLeft}
              className="cursor-pointer"
              onClick={() => onChangePage(currentPage - 2)}
            />
          )}
          {currentPage - 1 > 0 && (
            <PaginationComp.PrevButton
              className="mx-1"
              onClick={() => onChangePage(currentPage - 1)}
            >
              <ChevronLeft size={20} primaryColor={ColorPalette.grey["700"]} />
            </PaginationComp.PrevButton>
          )}
          <nav className="flex justify-center">
            <ul className="flex items-center">
              <PaginationComp.PageButton
                activeClassName="bg-secondary-50 rounded-[10px] text-secondary-400"
                inactiveClassName="text-sm text-grey-700"
                className="p-[10px] w-[38px] h-[38px] flex justify-center items-center cursor-pointer"
              />
            </ul>
          </nav>
          {currentPage + 1 < totalPages && (
            <PaginationComp.NextButton
              className="mx-1"
              onClick={() => onChangePage(currentPage + 1)}
            >
              <ChevronRight size={20} primaryColor={ColorPalette.grey["700"]} />
            </PaginationComp.NextButton>
          )}
          {currentPage + 2 < totalPages && (
            <img
              src={DoubleArrowRight}
              className="cursor-pointer"
              onClick={() => onChangePage(currentPage + 2)}
            />
          )}
        </>
      );
    }
  };

  return (
    <div className="flex items-cente gap-12">
      <PaginationComp
        currentPage={currentPage - 1}
        setCurrentPage={(page) => onChangePage(page + 1)}
        className="flex items-center"
        truncableText="..."
        totalPages={totalPages}
        edgePageCount={2}
        middlePagesSiblingCount={2}
      >
        {renderButtons()}
      </PaginationComp>
    </div>
  );
};

export default Pagination;
