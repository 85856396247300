import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { toast } from "react-toastify";
import { useAuthStore } from "src/store";

import { ToastMsg } from "src/shared";
import ToastAlert from "./alert";
import { QueryClient } from "@tanstack/react-query";

let queryClient: QueryClient;
export const setApiClient = (client: QueryClient) => {
  queryClient = client;
};

const blackList = ["/auth/logout/", "/auth/login/"];

export interface AxiosCustomRequestConfig extends AxiosRequestConfig {
  id?: string;
  slug?: string;
  body?: any;
}

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  TooManyRequests = 429,
  InternalServerError = 500,
  ServerDown = 502,
}

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
};

const injectToken = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  try {
    const token = useAuthStore.getState().access;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  } catch (error: any) {
    throw new Error(error);
  }
};

export class Http {
  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const http = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers,
    });

    http.interceptors.request.use(injectToken, (error) => {
      return Promise.reject(error);
    });

    http.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError) => {
        return this.handleError(error);
      }
    );

    this.instance = http;
    return http;
  }

  request<T = any, R = AxiosResponse<T>>(
    config: AxiosCustomRequestConfig
  ): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosCustomRequestConfig
  ): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosCustomRequestConfig
  ): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosCustomRequestConfig
  ): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosCustomRequestConfig
  ): Promise<R> {
    return this.http.patch<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosCustomRequestConfig
  ): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  private handleUnauthorized = (error: AxiosError) => {
    const { config } = error;
    if (
      useAuthStore.getState().refresh &&
      !config.url.includes("/auth/logout")
    ) {
      useAuthStore
        .getState()
        .refreshUser()
        .then(() => {
          this.http.request(config).then((res) => res);
        })
        .catch(() => {
          useAuthStore.getState().logoutUser();
        });
    } else {
      useAuthStore.getState().logoutUser();
    }
  };

  private handleError(error: any) {
    try {
      const { response } = error;
      const { status } = response;

      switch (status) {
        case StatusCode.ServerDown: {
          ToastAlert("error", "Server Down");
          break;
        }
        case StatusCode.NotFound: {
          ToastAlert("error", "Content Not Found");
          break;
        }
        case StatusCode.InternalServerError: {
          ToastAlert("error", "Unknown Server Error");
          break;
        }
        case StatusCode.Forbidden: {
          // Handle Forbidden
          break;
        }

        case StatusCode.Unauthorized: {
          this.handleUnauthorized(error);
          break;
        }
        case StatusCode.TooManyRequests: {
          ToastAlert("error", "Too Many Requests");
          break;
        }
      }
    } catch (error) {}

    return Promise.reject(error);
  }
}

export const http = new Http();
