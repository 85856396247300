import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IconProps, Paper, Swap } from "react-iconly";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "src/hooks";
import { ColorPalette, Helpers } from "src/utils";

type Props = {
  data: any;
  columns: any;
  title: string;
  Icon: FC<IconProps>;
  headerColor: string;
  bordered?: boolean;
  onSort?: (key: string) => void;
  loading?: boolean;
};

const Table = ({
  Icon,
  headerColor,
  title,
  columns,
  data,
  bordered,
  loading,
  onSort,
}: Props) => {
  const { i18n, t } = useTranslation();

  const isMobile = useMediaQuery("(max-width: 1023px)");

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const getMobileRowValue = () => {};

  return (
    <div
      className={`w-full flex flex-col ${
        bordered || isMobile ? "border border-grey-200 rounded-[20px]" : ""
      }`}
    >
      <div
        className={`w-full flex items-center ${
          bordered || isMobile ? "rounded-t-[20px]" : ""
        } border-b border-b-grey-200 bg-${headerColor} py-3.5 px-5 `}
      >
        <Icon
          size={20}
          primaryColor={ColorPalette.grey["800"]}
          style={{
            margin: i18n.dir() == "rtl" ? "0 0 0 8px" : "0 8px 0 0",
          }}
        />
        <h2 className="text-sm lg:text-base font-semibold text-grey-800">
          {title}
        </h2>
      </div>
      {isMobile ? (
        loading ? (
          <div className="p-2">
            {[1, 2, 3, 4, 5].map(() => (
              <div className="mb-4">
                <Skeleton height={250} />
              </div>
            ))}
          </div>
        ) : (
          <div className="pt-5 px-[14px]">
            {table.getRowModel().rows.map((row, index: number) => (
              <div
                className={`pb-[17px] mb-[14px] ${
                  data.length - 1 != index ? "border-b" : ""
                } border-b-grey-200 flex flex-col gap-5`}
              >
                {columns.map(
                  (header: Helpers.keyable, colIndex: number) =>
                    header.meta && (
                      <div className={`flex items-center justify-between`}>
                        <span className="text-sm text-grey-800 font-semibold flex items-center gap-2">
                          {colIndex == 0 && (
                            <div className="w-7 h-7 rounded-lg bg-grey-100 flex justify-center items-center text-grey-700">
                              {index + 1}
                            </div>
                          )}
                          {header.meta.mobile_header}
                        </span>
                        {flexRender(
                          row.getVisibleCells()[colIndex].column.columnDef.cell,
                          row.getVisibleCells()[colIndex].getContext()
                        )}
                      </div>
                    )
                )}
              </div>
            ))}
          </div>
        )
      ) : (
        <div className="w-full no-scrollbar">
          <table className="w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className={`bg-${headerColor}`}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        className="p-3 text-sm text-grey-800 font-bold"
                        align="center"
                        colSpan={header.colSpan}
                        style={{ width: header.getSize() }}
                      >
                        <div className="w-full flex items-center">
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}

                          {header.column.getCanSort() && (
                            <div
                              className="cursor-pointer"
                              onClick={() => onSort(header.column.id as string)}
                            >
                              <Swap
                                size={24}
                                primaryColor={ColorPalette.grey["800"]}
                                style={{
                                  margin:
                                    i18n.dir() == "rtl"
                                      ? "0 0 0 0"
                                      : "0 0 0 8px",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            {!loading && (
              <tbody>
                {table.getRowModel().rows.map((row, index) => {
                  return (
                    <tr
                      key={row.id}
                      className={`border-b-grey-200 ${
                        table.getRowModel().rows.length - 1 != index
                          ? "border-b"
                          : ""
                      }`}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            className="text-sm p-3 pb-4 text-grey-700"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
          {loading && (
            <div className="mt-2">
              {[1, 2, 3, 4, 5].map(() => (
                <div className="mb-2">
                  <Skeleton height={50} />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Table;
