import React from "react";
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";

interface IButtonProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  size?: "Small" | "Medium" | "Large";
  type?: "Primary" | "Secondary" | "Teritiary" | "Orange";
  children?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  addonClasses?: string;
  icon?: React.ReactElement;
  reverse?: boolean;
}

const Classes = (list: any) => {
  return list.filter(Boolean).join(" ");
};

const Button = (props: IButtonProps) => {
  const { t, i18n } = useTranslation();

  const getDefaultStyles = () => {
    return `whitespace-nowrap [&>svg]:text-white w-fit flex leading-[18px] ${
      props.reverse ? "flex-row-reverse" : ""
    } ${
      props.disabled ? "cursor-not-allowed" : "cursor-pointer"
    } items-center justify-center rounded-lg align-middle transition-all duration-300 `;
  };

  const getTypeStyles = () => {
    switch (props.type) {
      case "Primary":
        return `bg-primary-500 text-grey-50 hover:bg-primary-700 focus-whitin:border-2 focus-whitin:border-primary-200 ${
          props.disabled
            ? "!bg-grey-200 text-grey-500 [&>svg]:text-grey-200"
            : ""
        }`;

      case "Orange":
        return `bg-secondary-500 text-grey-50 hover:bg-secondary-700 focus-whitin:border-2 focus-whitin:border-secondary-200 ${
          props.disabled
            ? "!bg-grey-200 text-grey-500 [&>svg]:text-grey-200"
            : ""
        }`;

      case "Secondary":
        return `bg-white border border-primary-700 text-primary-500 hover:border-[1.5px] hover:border-primary-700 focus-whitin:bg-grey-50
        ${
          props.disabled
            ? "!bg-white !border-grey-200 !text-grey-500"
            : ""
        }`;

      case "Teritiary":
        return `text-primary-500 hover:bg-grey-50 focus-whitin:border-[1.5px] focus-whitin:border-primary-700 
        ${props.disabled ? "text-grey-500 cursor-not-allowed" : ""}`;

      default:
        return `bg-primary-500 text-grey-50 hover:bg-primary-700 focus-whitin:border-2 focus-whitin:border-primary-200 ${
          props.disabled
            ? "!bg-grey-200 text-grey-500 [&>svg]:!text-grey-500"
            : ""
        }`;
    }
  };

  const getSizeStyles = () => {
    switch (props.size) {
      case "Small":
        return "px-4 py-2 text-xs font-medium leading-4";

      case "Medium":
        return "px-4 py-3 text-[13px] font-medium leading-4";

      case "Large":
        return "px-4 py-[14px] text-sm font-medium leading-4";

      default:
        return "px-4 py-3 text-[13px] font-medium leading-4";
    }
  };

  return (
    <a
      {...props}
      className={Classes([
        getDefaultStyles(),
        getTypeStyles(),
        getSizeStyles(),
        props.addonClasses || "",
      ])}
      onClick={!props.disabled ? props.onClick : () => {}}
    >
      {props.loading ? (
        <ClipLoader
          color={"white"}
          loading={true}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        props.icon
      )}
      <div className="px-2 flex items-center">{props.children}</div>
      {/* {i18n.dir() == "rtl" && props.icon} */}
    </a>
  );
};

export default Button;
