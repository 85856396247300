import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Pagination, Select } from "src/components";

type Props = {
  count: number;
  pageCount: number;
};

const Footer = ({ count, pageCount }: Props) => {
  const { t, i18n } = useTranslation("payments/ts");

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="w-full flex items-center justify-between mb-6">
      <div className="flex items-center gap-[10px]">
        <span className="flex flex-row items-center gap-1 text-base text-grey-700">
          <span>
            1-{count < 10 ? count : searchParams.get("page_size") || 10}
          </span>
          <span>{t("of")} </span>
          <span>
            {count} {t("items")}
          </span>
        </span>
        <Select
          placeholder="10"
          small={true}
          value={
            searchParams.get("page_size")
              ? {
                  value: searchParams.get("page_size"),
                  label: searchParams.get("page_size"),
                }
              : { value: "10", label: "10" }
          }
          options={[
            { label: "10", value: 10 },
            { label: "20", value: 20 },
            { label: "50", value: 50 },
          ]}
          onChange={(data) => {
            searchParams.set("page_size", data.value);
            setSearchParams(searchParams);
          }}
        />
      </div>
      <Pagination
        currentPage={parseInt(searchParams.get("page")) || 1}
        totalPages={pageCount}
        onChangePage={(page: number) => {
          searchParams.set("page", String(page));
          setSearchParams(searchParams);
        }}
      />
    </div>
  );
};

export default Footer;
