import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { LogoEn, LogoFa } from "src/assets";
import { Button } from "src/components";

type Props = {};

const Header = (props: Props) => {
  const { i18n, t } = useTranslation("auth/ts");

  const { pathname } = useLocation();

  const navigate = useNavigate();

  return (
    <div className="w-full fixed top-0 px-4">
      <div className="w-full bg-white pt-3 pb-[15px] flex justify-between border-b border-b-grey-200 ">
        <img src={i18n.language == "en" ? LogoEn : LogoFa} style={{ maxWidth: 118 }} />
        <div className="flex items-center">
          <Button
            addonClasses="!w-[85px]"
            size="Small"
            onClick={() =>
              pathname.includes("login")
                ? navigate("register")
                : navigate("login")
            }
          >
            {pathname.includes("login") ? t("signUp") : t("signIn")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Header;
