import i18n, { use } from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      convertDetectedLanguage: (lng: string) => lng.split("-")[0],
    },
    fallbackLng: "en",
    keySeparator: ":", // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: { useSuspense: false },
  });

export default i18n;
