import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, Modal } from "src/components";
import { FormField, ToastMsg } from "src/shared";
import { ColorPalette, Helpers } from "src/utils";
import { IFormValues } from "./types";
import { useMediaQuery } from "src/hooks";

import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ServiceApi } from "src/api";
import { AxiosError } from "axios";

type Props = {
  data: Helpers.keyable;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
};

const initialValues: IFormValues = {
  name: undefined,
};

const AccountDetailModal = (props: Props) => {
  const isMobile = useMediaQuery("(max-width:1023px)");

  const { t, i18n } = useTranslation("accounts/ts");
  const { t: validationTranslations } = useTranslation("validation/ts");

  const queryClient = useQueryClient();

  const updateAccountMutuate = useMutation(ServiceApi.patchAdwordsAccount, {
    onSuccess: (res) => {
      toast(
        <ToastMsg
          type="success"
          message={validationTranslations("data_store_success")}
        />
      );
      queryClient.invalidateQueries({
        queryKey: ["[List] Account"],
      });
      props.setIsOpen(false);
    },
    onError: (error: AxiosError) => {
      if (error.response.status == 400) {
        toast(<ToastMsg type="error" message={t("data_store_error")} />);
      }
    },
  });

  const validationSchema = Yup.object({
    name: Yup.string().required(validationTranslations("field_required")),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) =>
      updateAccountMutuate.mutate({
        params: { id: props.data.id },
        body: values,
      }),
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
  });

  useEffect(() => {
    if (props.data) {
      formik.setValues({ name: props.data.name });
    }
  }, [props.data]);

  return (
    <Modal
      title={t("account_details")}
      isOpen={props.isOpen}
      onClose={() => props.setIsOpen(false)}
    >
      <div className="w-full h-full flex flex-col justify-between">
        <div className="w-full">
          <h4 className="mb-5 text-base text-grey-800">{t("pick_name")}</h4>
          {props.data && (
            <>
              <div className="px-4 py-5 bg-grey-50 flex items-center justify-between mb-5 rounded-[20px]">
                <span className="text-md text-grey-700">
                  {t("account_number")}
                </span>
                <span className="text-md text-grey-700">
                  {props?.data?.number}
                </span>
              </div>
              <FormField
                label={t("name_field")}
                field={
                  <Input
                    placeholder={t("account_name")}
                    sizes={isMobile ? "Small" : "Large"}
                    onChange={(e) => {
                      formik.setFieldValue("name", e.target.value);
                    }}
                    onFocus={() => formik.setFieldTouched("name", true)}
                    value={formik.values.name}
                    autoComplete={"on"}
                    onClear={() => formik.setFieldValue("name", "")}
                  />
                }
                message={
                  formik.errors.name &&
                  formik.touched.name && {
                    type: "ERROR",
                    content: formik.errors.name,
                  }
                }
              />
            </>
          )}
        </div>
        <div className="flex justify-center lg:justify-end">
          <div
            className={`w-full lg:max-w-sm grid grid-cols-12 
          gap-3`}
          >
            <div className="col-span-6">
              <Button
                addonClasses="w-full"
                type="Secondary"
                onClick={() => props.setIsOpen(false)}
              >
                {t("cancel")}
              </Button>
            </div>
            <div className="col-span-6">
              <Button
                addonClasses="w-full"
                onClick={() => formik.submitForm()}
                disabled={!formik.isValid}
                loading={updateAccountMutuate.isLoading}
              >
                {t("submit")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AccountDetailModal;
