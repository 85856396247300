import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowLeft, ArrowRight } from "react-iconly";
import { Button } from "src/components";
import { useMediaQuery } from "src/hooks";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { ColorPalette } from "src/utils";
import CompleteProfileModal from "../CompleteProfileModal";
import { useAuthStore } from "src/store";

type Props = {};

const CompleteProfile = (props: Props) => {
  const { user } = useAuthStore();

  const isMobile = useMediaQuery("(max-width: 992px)");

  const { t, i18n } = useTranslation("dashboard/ts");

  const [completeModal, setCompleteModal] = useState(false);

  const getScore = () => {
    let score = 0;
    if (user) {
      if (user.first_name || user?.first_name.length != 0) {
        score += 1;
      }
      if (user.last_name || user?.last_name.length != 0) {
        score += 1;
      }
      if (user.email) {
        score += 1;
      }
      if (user.national_code) {
        score += 1;
      }
      if (user.email_verification) {
        score += 1;
      }
    }
    return score;
  };

  return (
    <div className="w-full px-4 py-5 bg-grey-50 rounded-[20px] flex flex-col mb-[30px]">
      <CompleteProfileModal
        isOpen={completeModal}
        setIsOpen={setCompleteModal}
      />
      <div className="flex justify-between items-center ">
        <div className="flex flex-col">
          <h5 className="text-sm lg:text-lg text-grey-800 font-semibold mb-2">
            {t("complete_profile")}
          </h5>
          <p className="text-xs lg:text-sm mb-[22px] text-grey-700">
            {t("complete_profile_hint")}
          </p>
          {!isMobile && (
            <Button
              size={isMobile ? "Small" : "Medium"}
              icon={
                i18n.dir() == "rtl" ? (
                  <ArrowLeft size={20} />
                ) : (
                  <ArrowRight size={20} />
                )
              }
              reverse={true}
              onClick={() => setCompleteModal(true)}
            >
              {t("complete_profile")}
            </Button>
          )}
        </div>
        <div>
          <CircularProgressbar
            value={(getScore() / 5) * 100}
            text={
              i18n.dir() == "rtl" ? `5 / ${getScore()}` : `${getScore()} / 5`
            }
            styles={{
              root: {
                width: isMobile ? 60 : 118,
              },
              path: {
                stroke: ColorPalette.success["400"],
              },
              trail: {
                stroke: ColorPalette.grey["100"],
              },
              text: {
                fill: ColorPalette.grey["800"],
              },
            }}
          />
        </div>
      </div>
      {isMobile && (
        <Button
          addonClasses="w-full mt-4"
          size={isMobile ? "Small" : "Medium"}
          icon={
            i18n.dir() == "rtl" ? (
              <ArrowLeft size={20} />
            ) : (
              <ArrowRight size={20} />
            )
          }
          reverse={true}
          onClick={() => setCompleteModal(true)}
        >
          {t("complete_profile")}
        </Button>
      )}
    </div>
  );
};

export default CompleteProfile;
