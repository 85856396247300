import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, CheckBox, Input } from "src/components";
import { useMediaQuery } from "src/hooks";
import { FormField } from "src/shared";

type Props = {};

const ForgotForm = (props: Props) => {
  const { t, i18n } = useTranslation("auth/ts");
  const isMobile = useMediaQuery("(max-width: 1023px)");

  return (
    <div className="w-full h-full flex flex-col items-center justify-between">
      <div className="w-full flex flex-col items-center">
        <h1
          className={`text-grey-800 ${
            isMobile ? "text-base" : "text-[32px]"
          } mb-2 font-semibold`}
        >
          {t("forgot_pass")}
        </h1>
        <p className={`text-grey-700 ${isMobile ? "text-sm" : "text-lg"} mb-5`}>
          {t("enter_email_forgot")}
        </p>
        <div className="w-full mb-[14px]">
          <FormField
            label={t("email_field")}
            field={
              <Input
                placeholder={t("enter_email")}
                sizes={isMobile ? "Small" : "Large"}
              />
            }
          />
        </div>
      </div>
      <div className="w-full flex flex-col">
        <div className="mb-[30px]">
          <Button disabled={true} addonClasses="w-full">
            {t("reset_pass")}
          </Button>
        </div>
        <div className="flex items-center justify-center">
          <span
            className={`text-grey-700 text-[14px] ${
              i18n.dir() == "rtl" ? "ml-[30px]" : "mr-[30px]"
            }`}
          >
            {t("goto")}
          </span>
          <Link to="/auth/login-otp" className="text-[13px] text-primary-500">
            {t("login_page")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotForm;
