import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AuthApi } from "src/api";
import { Button, CheckBox, Input } from "src/components";
import { useMediaQuery } from "src/hooks";
import { FormField, ToastMsg } from "src/shared";

import * as Yup from "yup";
import { useFormik } from "formik";

import { IFormValues } from "./types";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useAuthStore } from "src/store";

type Props = {};

const initialValues: IFormValues = {
  email: undefined,
  password: undefined,
};

const LoginForm = (props: Props) => {
  const { t, i18n } = useTranslation("auth/ts");
  const { t: validationTranslations } = useTranslation("validation/ts");

  const { loginUser } = useAuthStore();

  const isMobile = useMediaQuery("(max-width: 1023px)");

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(validationTranslations("email"))
      .required(validationTranslations("field_required")),
    password: Yup.string().required(validationTranslations("field_required")),
  });

  const loginMututate = useMutation(AuthApi.loginPassword, {
    onSuccess: (res) => {
      toast(<ToastMsg type="success" message={t("welcome")} />);
      loginUser(res.access_token, res.refresh_token);
    },
    onError: (error: AxiosError) => {
      if (error.response.status == 400) {
        toast(<ToastMsg type="error" message={t("alert_login_pass_error")} />);
      }
    },
    retry: false
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => loginMututate.mutate({ body: values }),
    validateOnChange: true,
    validateOnMount: true,
  });

  return (
    <div className="w-full h-full flex flex-col items-center justify-between">
      <div className="w-full flex flex-col items-center">
        <h1
          className={`text-grey-800 ${
            isMobile ? "text-base" : "text-[32px]"
          } mb-2 font-semibold`}
        >
          {t("welcome_back")}
        </h1>
        <p className={`text-grey-700 ${isMobile ? "text-sm" : "text-lg"} mb-5`}>
          {t("describe_self_login")}
        </p>
        <div className="w-full mb-[14px]">
          <FormField
            label={t("email_field")}
            field={
              <Input
                name="email"
                type={"email"}
                placeholder={t("enter_email")}
                sizes={isMobile ? "Small" : "Large"}
                onChange={(e) => formik.setFieldValue("email", e.target.value)}
                onFocus={() => formik.setFieldTouched("email", true)}
                value={formik.values.email}
                autoComplete={"on"}
                onClear={() => formik.setFieldValue("email", "")}
              />
            }
            message={
              formik.errors.email &&
              formik.touched.email && {
                type: "ERROR",
                content: formik.errors.email,
              }
            }
          />
        </div>
        <div className="w-full mb-[10px]">
          <FormField
            label={t("pass_field")}
            field={
              <Input
                name="password"
                type={"password"}
                placeholder={t("enter_pass")}
                sizes={isMobile ? "Small" : "Large"}
                onChange={(e) =>
                  formik.setFieldValue("password", e.target.value)
                }
                value={formik.values.password}
                onFocus={() => formik.setFieldTouched("password", true)}
                autoComplete={"on"}
                onClear={() => formik.setFieldValue("password", "")}
              />
            }
            message={
              formik.errors.password &&
              formik.touched.password && {
                type: "ERROR",
                content: formik.errors.password,
              }
            }
          />
        </div>
      </div>
      <div className="w-full flex flex-col">
        <div className="mb-[30px]">
          <Button
            disabled={!formik.isValid}
            addonClasses="w-full"
            onClick={() => formik.submitForm()}
            loading={loginMututate.isLoading}
          >
            {t("signIn")}
          </Button>
        </div>
        <div className="flex items-center justify-center gap-[30px]">
          <span className="text-sm text-grey-700">{t("receive_otp")}</span>
          <Link to="/auth/login-otp" className="text-[13px] text-primary-500">
            {t("login")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
