import React from "react";
import { useSearchParams } from "react-router-dom";
import { useMediaQuery } from "src/hooks";

import { EmptyCart } from "./partials";

import Desktop from "./Desktop";
import Mobile from "./Mobile";

type Props = {};

const ShoppingCart = (props: Props) => {
  const isMobile = useMediaQuery("(max-width: 1023px)");

  const [searchParams] = useSearchParams();

  const renderPage = () => {
    switch (searchParams.get("empty")) {
      case "true":
        return <EmptyCart />;

      default:
        if (isMobile) return <Mobile />;
        else return <Desktop />;
    }
  };

  return <div className="w-full">{renderPage()}</div>;
};

export default ShoppingCart;
