import { ArrowDown2, ArrowUp2, IconProps as IconSaxProps } from "iconsax-react";
import React, { FC, useState } from "react";
import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";
import { IconProps } from "react-iconly";
import { Link, useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { ColorPalette, Helpers } from "src/utils";

type Props = {
  title: string;
  icon?: FC<IconProps> | FC<IconSaxProps>;
  path: string;
  subMenu?: Helpers.keyable[];
  type?: "danger" | "disabled";
  onClick?: () => void;
  loading?: boolean;
  customIcon?: {
    simple: string;
    fill: string;
  };
};

const MenuItem = ({
  icon: Icon,
  title,
  path,
  subMenu,
  type,
  onClick,
  loading,
  customIcon,
}: Props) => {
  const { pathname } = useLocation();
  const { i18n, t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const isActive = (cpath?: string) => {
    return pathname.includes(cpath ? cpath : path);
  };

  const isParentActive = () => {
    if (subMenu) {
      const urls = subMenu.map((item) => item.path);
      const path_exist = urls.find((url) => pathname.includes(url));
      return Boolean(path_exist);
    } else {
      return isActive();
    }
  };

  const baseColor = isParentActive()
    ? "text-white"
    : type == "danger"
    ? "text-error-400"
    : type == "disabled"
    ? "text-grey-500"
    : "text-grey-700";

  const getClasses = () => {
    const defaults = `${
      type == "disabled" ? "cursor-not-allowed" : ""
    } w-full px-3 py-[10px] flex justify-between text-sm ${baseColor} rounded-xl`;

    switch (isParentActive()) {
      case true:
        return defaults + " bg-primary-500";

      default:
        return defaults + " bg-white";
    }
  };

  return (
    <div className="mb-5">
      <Link
        to={onClick || type == "disabled" || subMenu ? "#" : path}
        onClick={subMenu ? () => setIsOpen(!isOpen) : onClick}
      >
        <li
          className={`w-full relative ${
            i18n.dir() == "rtl" ? "pr-5 pl-3" : "pl-5 pr-3"
          }`}
        >
          {isParentActive() && (
            <div
              className={`absolute ${
                i18n.dir() == "rtl" ? "right-[-10px]" : "left-[-10px]"
              } top-3  w-[20px] h-[20px] rounded-[10px] bg-primary-500`}
            ></div>
          )}
          <div className={getClasses()}>
            <div
              className="flex items-center gap-2"
              style={{ fontSize: "inherit" }}
            >
              {loading ? (
                <ClipLoader
                  size={20}
                  color={
                    type == "danger"
                      ? ColorPalette.error["400"]
                      : type == "disabled"
                      ? ColorPalette.grey["500"]
                      : ColorPalette.grey["700"]
                  }
                  loading={true}
                />
              ) : customIcon ? (
                <img
                  src={isParentActive() ? customIcon.fill : customIcon.simple}
                />
              ) : (
                <Icon
                  variant={isParentActive() ? "Bulk" : "Bold"}
                  primaryColor={
                    isParentActive()
                      ? "white"
                      : type == "danger"
                      ? ColorPalette.error["400"]
                      : type == "disabled"
                      ? ColorPalette.grey["500"]
                      : ColorPalette.grey["700"]
                  }
                  filled={isParentActive()}
                />
              )}
              {title}
            </div>
            {subMenu &&
              (isOpen ? (
                <ArrowUp2
                  size={20}
                  color={
                    isParentActive()
                      ? "white"
                      : type == "danger"
                      ? ColorPalette.error["400"]
                      : type == "disabled"
                      ? ColorPalette.grey["500"]
                      : ColorPalette.grey["700"]
                  }
                />
              ) : (
                <ArrowDown2
                  size={20}
                  color={
                    isParentActive()
                      ? "white"
                      : type == "danger"
                      ? ColorPalette.error["400"]
                      : type == "disabled"
                      ? ColorPalette.grey["500"]
                      : ColorPalette.grey["700"]
                  }
                />
              ))}
          </div>
        </li>
      </Link>
      {subMenu && (
        <Collapse isOpened={isOpen}>
          <div className="w-full px-6 mt-[10px]">
            {subMenu.map((item) => (
              <Link
                className={`w-full block py-3 px-2 text-sm text-grey-600 rounded-xl
                ${isActive(item.path) ? "bg-primary-50 text-primary-500" : ""}`}
                to={item.path}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </Collapse>
      )}
    </div>
  );
};

export default MenuItem;
