import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ImagePlaceHolderSvg, SampleAvatar2 } from "src/assets";
import { ColorPalette } from "src/utils";
import { Edit as EditIcon } from "react-iconly";
import { AddSquare, Trash } from "iconsax-react";
import { FormField, ToastMsg } from "src/shared";
import { Button, Input } from "src/components";
import { useMediaQuery } from "src/hooks";

import * as Yup from "yup";
import { useFormik } from "formik";

import { IFormValues } from "./types";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "src/api";
import { useAuthStore } from "src/store";
import { serialize } from "object-to-formdata";
import { ChangePhoneModal } from "./partials";

const initialValues: IFormValues = {
  avatar: undefined,
  first_name: undefined,
  last_name: undefined,
  email: undefined,
  phone_number: undefined,
};

type Props = {};

const Edit = (props: Props) => {
  const { i18n, t } = useTranslation("profile/ts");
  const { t: validationTranslations } = useTranslation("validation/ts");

  const isMobile = useMediaQuery("(max-width: 1023px)");

  const { user, setUser } = useAuthStore();

  const navigate = useNavigate();

  const [phoneModal, setPhoneModal] = useState(false);

  const validationSchema = Yup.object({
    avatar: Yup.mixed().notRequired(),
    first_name: Yup.string().required(validationTranslations("field_required")),
    last_name: Yup.string().required(validationTranslations("field_required")),
  });

  const updateProfile = useMutation(AuthApi.patchProfile, {
    onSuccess: (res) => {
      toast(
        <ToastMsg
          type="success"
          message={validationTranslations("data_store_success")}
        />
      );
      setUser(res);
    },
    onError: (error: AxiosError) => {
      if (error.response.status == 400) {
        toast(<ToastMsg type="error" message={t("data_store_error")} />);
      }
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      updateProfile.mutate({
        params: { id: user.id },
        body: serialize({
          ...values,
          avatar: typeof values.avatar != "string" ? values.avatar : undefined,
        }),
      });
    },
    validateOnChange: true,
  });

  useEffect(() => {
    formik.setValues({
      email: user.email,
      phone_number: user.phone_number,
      avatar: user.avatar,
      first_name: user.first_name,
      last_name: user.last_name,
    });
  }, [user]);

  return (
    <div className="w-full">
      <ChangePhoneModal isOpen={phoneModal} setIsOpen={setPhoneModal} />
      <div className="flex items-center mb-5">
        <input
          type={"file"}
          className="hidden"
          id="avatar-inp"
          multiple={false}
          onChange={(e) => formik.setFieldValue("avatar", e.target.files[0])}
        />
        {formik.values.avatar ? (
          <img
            src={
              typeof formik.values.avatar == "string"
                ? formik.values?.avatar
                : URL.createObjectURL(formik.values.avatar)
            }
            className="w-16 h-16 rounded-full"
            style={{
              margin: i18n.dir() == "rtl" ? "0 0 0 20px" : "0 20px 0 0",
              objectFit: "cover",
            }}
          />
        ) : (
          <div className="flex items-center gap-5">
            <div className="p-3 bg-grey-50 flex items-center justify-center rounded-full">
              <img src={ImagePlaceHolderSvg} />
            </div>
            <Button
              type="Teritiary"
              icon={<AddSquare size={20} color={ColorPalette.primary["500"]} />}
              onClick={() => {
                let inp: HTMLElement = document.querySelector(
                  "#avatar-inp"
                ) as HTMLElement;
                inp.click();
              }}
            >
              {t("upload_photo")}
            </Button>
          </div>
        )}
        {formik.values.avatar && (
          <div className="flex gap-2">
            <a
              href="#"
              className="p-3 bg-secondary-500 rounded-lg flex itemce-center justify-center"
              onClick={() => {
                let inp: HTMLElement = document.querySelector(
                  "#avatar-inp"
                ) as HTMLElement;
                inp.click();
              }}
            >
              <EditIcon size={20} primaryColor={ColorPalette.grey["50"]} />
            </a>
            <Link
              to="#"
              className="p-3 bg-error-500 rounded-lg flex itemce-center justify-center"
              onClick={() => formik.setFieldValue("avatar", null)}
            >
              <Trash size={20} color={ColorPalette.grey["50"]} />
            </Link>
          </div>
        )}
      </div>
      <div className="w-full mb-[14px]">
        <FormField
          label={t("name")}
          field={
            <Input
              placeholder={t("name")}
              sizes={isMobile ? "Small" : "Large"}
              onChange={(e) =>
                formik.setFieldValue("first_name", e.target.value)
              }
              onFocus={() => formik.setFieldTouched("first_name", true)}
              value={formik.values.first_name}
              autoComplete={"on"}
              onClear={() => formik.setFieldValue("first_name", "")}
            />
          }
          message={
            formik.errors.first_name &&
            formik.touched.first_name && {
              type: "ERROR",
              content: formik.errors.first_name,
            }
          }
        />
      </div>
      <div className="w-full mb-[14px]">
        <FormField
          label={t("last_name")}
          field={
            <Input
              placeholder={t("last_name")}
              sizes={isMobile ? "Small" : "Large"}
              onChange={(e) =>
                formik.setFieldValue("last_name", e.target.value)
              }
              onFocus={() => formik.setFieldTouched("last_name", true)}
              value={formik.values.last_name}
              autoComplete={"on"}
              onClear={() => formik.setFieldValue("last_name", "")}
            />
          }
          message={
            formik.errors.last_name &&
            formik.touched.last_name && {
              type: "ERROR",
              content: formik.errors.last_name,
            }
          }
        />
      </div>
      <div className="w-full mb-[14px] flex items-center">
        <div className="flex-1">
          <FormField
            label={t("email")}
            field={
              <Input
                placeholder={t("email")}
                sizes={isMobile ? "Small" : "Large"}
                value={formik.values.email}
                readOnly={true}
              />
            }
          />
        </div>
        <div className="w-12 h-12 flex justify-center items-center">
          <EditIcon
            style={{ marginTop: 16 }}
            size={20}
            primaryColor={ColorPalette.primary["500"]}
          />
        </div>
      </div>
      <div className="w-full mb-[14px] flex items-center">
        <div className="flex-1">
          <FormField
            label={t("mobile")}
            field={
              <Input
                placeholder={t("mobile")}
                sizes={isMobile ? "Small" : "Large"}
                value={formik.values.phone_number}
                readOnly={true}
              />
            }
          />
        </div>
        <div
          className="w-12 h-12 flex justify-center items-center"
          onClick={() => setPhoneModal(true)}
        >
          <EditIcon
            style={{ marginTop: 16 }}
            size={20}
            primaryColor={ColorPalette.primary["500"]}
          />
        </div>
      </div>
      <div
        className={`w-full max-w-sm grid grid-cols-12 mt-6 ${
          i18n.dir() == "rtl" ? "float-left" : "float-right"
        } gap-3`}
      >
        <div className="col-span-6">
          <Button
            addonClasses="w-full"
            type="Secondary"
            onClick={() => navigate(-1)}
          >
            {t("cancel")}
          </Button>
        </div>
        <div className="col-span-6">
          <Button
            addonClasses="w-full"
            onClick={() => formik.submitForm()}
            disabled={!formik.isValid}
            loading={updateProfile.isLoading}
          >
            {t("save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Edit;
