import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Paper } from "react-iconly";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { IOrdersDetailInterface, IPaymentIdsInterface } from "./types";
import { createColumnHelper } from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";
import { OrderApi, WalletApi } from "src/api";
import { EmptyOrder, FilterSec, Footer } from "./partials";
import Skeleton from "react-loading-skeleton";
import { Table } from "src/components";
import { MoreSvg } from "src/assets";
type Props = {};

enum PAPYMENTIDSTATUS {
  VERIFIED = "VERIFIED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

const TableList = (props: Props) => {
  const { t, i18n } = useTranslation("paymentids/ts");

  const [pageCount, setPageCount] = useState<number>(1);
  const [listDetail, setListDetail] = useState<IOrdersDetailInterface>();

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const getOrderStatusT = (status: string) => {
    switch (status) {
      case PAPYMENTIDSTATUS.VERIFIED:
        return t("accepted");
      case PAPYMENTIDSTATUS.PENDING:
        return t("pending");
      case PAPYMENTIDSTATUS.REJECTED:
        return t("processing");

      default:
        break;
    }
  };

  const getOrderStatusColor = (status: string) => {
    switch (status) {
      case PAPYMENTIDSTATUS.PENDING:
        return "bg-primary-50 text-primary-500";
      case PAPYMENTIDSTATUS.VERIFIED:
        return "bg-success-50 text-success-500";
      case PAPYMENTIDSTATUS.REJECTED:
        return "bg-error-50 text-error-500";

      default:
        break;
    }
  };

  const columnHelper = createColumnHelper<IPaymentIdsInterface>();

  const columns = [
    columnHelper.accessor("index", {
      header: () => <span>#</span>,
      cell: ({ row }) => row.index + 1,
      enableSorting: false,
      size: 60,
    }),
    columnHelper.accessor("payment_id", {
      header: () => <span>{t("payment_id")}</span>,
      cell: (row) => row.getValue(),
      enableSorting: false,
      meta: {
        mobile_header: t("payment_id"),
      },
    }),
    columnHelper.accessor("destination_owner_name", {
      header: () => <span>{t("account_owner")}</span>,
      cell: (row) => row.getValue(),
      enableSorting: false,
      meta: {
        mobile_header: t("destination_owner_name"),
      },
    }),
    columnHelper.accessor("destination_iban", {
      header: () => <span>{t("sheba_number")}</span>,
      cell: (row) => row.getValue(),
      enableSorting: false,
      meta: {
        mobile_header: t("destination_iban"),
      },
    }),
    columnHelper.accessor("status", {
      header: () => (
        <div className="w-full flex items-center justify-start">
          <span>{t("status")}</span>
        </div>
      ),
      meta: {
        mobile_header: t("status"),
      },
      cell: (row) => (
        <div className="flex items-center justify-start whitespace-nowrap">
          <div
            className={`px-3 py-[5px] ${getOrderStatusColor(
              row.getValue()
            )} rounded-lg text-xs text-center`}
          >
            {getOrderStatusT(row.getValue())}
          </div>
        </div>
      ),
      enableSorting: false,
      size: 100,
    }),
    columnHelper.accessor("action", {
      header: () => <span></span>,
      cell: (row) => (
        <div className="flex items-center justify-end gap-2">
          <Link to={`/payment-ids/single/${row.row.original.id}`}>
            <img src={MoreSvg} className="cursor-pointer" onClick={() => {}} />
          </Link>
        </div>
      ),
      enableSorting: false,
      size: 200,
    }),
  ];

  const {
    data,
    isFetching: isQueryLoading,
    status,
    refetch,
  } = useQuery({
    queryKey: ["[List] PaymentIds"],
    queryFn: () =>
      WalletApi.getPaymentIds({}).then((result) => {
        return result;
      }),
    cacheTime: 0,
  });

  const result = React.useMemo(() => {
    if (!isQueryLoading && data && status === "success") {
      const { results, ...rest } = data;

      const page_size = parseInt(searchParams.get("page_size")) || 10;
      setPageCount(Math.ceil(rest.count / page_size));

      setListDetail(rest);
      return results.map((item: IPaymentIdsInterface, index: number) => ({
        ...item,
        index,
      }));
    } else {
      return [];
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [searchParams]);

  if (
    result.length == 0 &&
    !isQueryLoading &&
    Array.from(searchParams).length == 0
  ) {
    return <EmptyOrder />;
  } else
    return (
      <div className="w-full">
        <FilterSec refetch={refetch} />
        <div className="mb-[34px]">
          <Table
            data={result}
            columns={columns}
            title={t("page_title")}
            Icon={Paper}
            headerColor={"grey-100"}
            bordered={false}
            loading={isQueryLoading}
            onSort={(key) => {
              const sorted = searchParams.get("ordering") || "-";
              if (sorted.includes("-")) {
                searchParams.set("ordering", key);
              } else {
                searchParams.set("ordering", "-" + key);
              }
              searchParams.set("page", "1");
              setSearchParams(searchParams);
            }}
          />
        </div>
        {listDetail ? (
          pageCount > 1 && (
            <Footer
              pageCount={pageCount || 1}
              count={listDetail.count}
              t={t}
              i18n={i18n}
            />
          )
        ) : (
          <Skeleton height={54} />
        )}
      </div>
    );
};

export default TableList;
