import React from "react";
import { useTranslation } from "react-i18next";

interface ICheckBoxInterface
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  type?: "INFO" | "ERROR" | "SUCCESS";
}

const CheckBox = (props: ICheckBoxInterface) => {
  const { i18n, t } = useTranslation();

  const getColorStyles = () => {
    switch (props.type) {
      case "INFO":
        return "checked:bg-primary-500 checked:border-primary-500";

      case "SUCCESS":
        return "checked:bg-success-500 checked:border-success-500";

      case "ERROR":
        return "error checked:bg-error-400 checked:border-error-400";

      default:
        return "checked:bg-primary-500 checked:border-primary-500";
    }
  };

  return (
    <div
      className={`flex items-center gap-3 ${
        i18n.dir() == "rtl" ? "flex-row-reverse" : "flex-row"
      }`}
    >
      <input
        {...props}
        type="checkbox"
        className={`p-0.5 w-5 h-5  border rounded-md border-grey-200 appearance-none ${getColorStyles()}`}
      />
      <label
        className={`${
          props.disabled ? "text-grey-500" : "text-grey-700"
        } text-[14px]`}
      >
        {props.label}
      </label>
    </div>
  );
};

export default CheckBox;
