import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { useTranslation } from "react-i18next";
import { Paper, Show } from "react-iconly";
import { UsaFlag } from "src/assets";
import { Table } from "src/components";

import { IOrdersInterface } from "./types";
import { useQuery } from "@tanstack/react-query";
import { OrderApi } from "src/api";
import { Link } from "react-router-dom";
import { ColorPalette } from "src/utils";

type Props = {};

enum ORDER_STATUS {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
  REFUNDED = "REFUNDED",
}

const OrdersTable = (props: Props) => {
  const { t, i18n } = useTranslation("orders/ts");

  const columnHelper = createColumnHelper<IOrdersInterface>();
  const getOrderStatusT = (status: string) => {
    switch (status) {
      case ORDER_STATUS.DRAFT:
        return t("draft");
      case ORDER_STATUS.PENDING:
        return t("pending");
      case ORDER_STATUS.PROCESSING:
        return t("processing");
      case ORDER_STATUS.COMPLETED:
        return t("completed");
      case ORDER_STATUS.CANCELED:
        return t("canceled");
      case ORDER_STATUS.REFUNDED:
        return t("refunded");

      default:
        break;
    }
  };

  const getOrderStatusColor = (status: string) => {
    switch (status) {
      case ORDER_STATUS.DRAFT:
        return "bg-grey-50 text-grey-500";
      case ORDER_STATUS.PENDING:
        return "bg-primary-50 text-primary-500";
      case ORDER_STATUS.PROCESSING:
        return "bg-warning-50 text-warning-500";
      case ORDER_STATUS.COMPLETED:
        return "bg-success-50 text-success-500";
      case ORDER_STATUS.CANCELED:
        return "bg-error-50 text-error-500";
      case ORDER_STATUS.REFUNDED:
        return "bg-secondary-50 text-secondary-500";

      default:
        break;
    }
  };

  const columns = [
    columnHelper.accessor("index", {
      header: () => <span>#</span>,
      cell: ({ row }) => row.index + 1,
      enableSorting: false,
      size: 60,
    }),
    columnHelper.accessor("id", {
      header: () => <span>{t("payment_number")}</span>,
      cell: (row) => row.getValue(),
      enableSorting: false,
      meta: {
        mobile_header: t("payment_number"),
      },
    }),
    columnHelper.accessor("account_number", {
      header: () => <span>{t("account_number")}</span>,
      cell: (row) =>
        row.row.original.adwords_order_detail.length > 0
          ? row.row.original.adwords_order_detail[0]?.account
            ? row.row.original.adwords_order_detail[0]?.account.toLocaleString()
            : ""
          : "",
      enableSorting: false,
      meta: {
        mobile_header: t("account_number"),
      },
    }),
    columnHelper.accessor("created_date", {
      header: () => <span>{t("date")}</span>,
      cell: (row) => row.getValue(),
      meta: {
        mobile_header: t("date"),
      },
      enableSorting: false,
    }),
    columnHelper.accessor("currency", {
      header: () => <span>{t("currency")}</span>,
      cell: (row) => {
        const cur =
          row.row.original.adwords_order_detail.length > 0
            ? row.row.original.adwords_order_detail[0].currency
            : undefined;

        if (cur) {
          return (
            <div className="flex items-center">
              <img
                src={cur?.icon}
                className="w-6 h-6"
                style={{
                  margin: i18n.dir() == "rtl" ? "0 0 0 8px" : "0 8px 0 0",
                }}
              />
              <span className="text-sm text-grey-700">{cur?.name}</span>
            </div>
          );
        } else {
          return <></>;
        }
      },
      meta: {
        mobile_header: t("currency"),
      },
      enableSorting: false,
    }),
    columnHelper.accessor("amount", {
      header: () => <span>{t("amount")}</span>,
      cell: (row) =>
        row.row.original.adwords_order_detail.length > 0
          ? row.row.original.adwords_order_detail[0].amount.toLocaleString()
          : "",
      meta: {
        mobile_header: t("amount"),
      },
      enableSorting: false,
    }),
    columnHelper.accessor("status", {
      header: () => (
        <div className="w-full flex items-center justify-end">
          <span>{t("status")}</span>
        </div>
      ),
      meta: {
        mobile_header: t("status"),
      },
      cell: (row) => (
        <div className="whitespace-nowrap flex items-center justify-end">
          <div
            className={`px-3 py-[5px] ${getOrderStatusColor(
              row.getValue()
            )} rounded-lg text-xs text-center`}
          >
            {getOrderStatusT(row.getValue())}
          </div>
        </div>
      ),
      enableSorting: false,
      size: 100,
    }),
    columnHelper.accessor("action", {
      header: () => <span></span>,
      cell: (row) => (
        <Link
          to={`/orders/single/${row.row.original.id}`}
          className="flex items-center justify-end"
        >
          <Show primaryColor={ColorPalette.grey["700"]} size={24} />
        </Link>
      ),
      enableSorting: false,
      size: 200,
    }),
  ];

  const {
    data,
    isFetching: isQueryLoading,
    status,
    refetch,
  } = useQuery({
    queryKey: ["[List] Order"],
    queryFn: () =>
      OrderApi.getOrderList({
        params: {
          page: 1,
          page_size: 5,
        },
      }).then((result) => {
        return result.results;
      }),
    cacheTime: 0,
    initialData: [],
  });

  if (data.length > 0) {
    return (
      <Table
        data={data || []}
        columns={columns}
        title={t("order_list")}
        Icon={Paper}
        headerColor={"primary-50"}
        bordered={true}
        loading={isQueryLoading}
      />
    );
  } else {
    return <></>;
  }
};

export default OrdersTable;
