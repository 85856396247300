import { AddSquare } from "iconsax-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Download, Plus } from "react-iconly";
import { Link } from "react-router-dom";
import { SampleAvatar, TicketsBgSvg } from "src/assets";
import { Button, Input, Select, TextArea } from "src/components";
import { useMediaQuery } from "src/hooks";
import { FormField } from "src/shared";
import { ColorPalette } from "src/utils";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import { MessageCard } from "./partials";

type Props = {};

const Single = (props: Props) => {
  const { t } = useTranslation("tickets/ts");

  const isMobile = useMediaQuery("(max-width: 1023px)");

  return isMobile ? <Mobile /> : <Desktop />;
};

export default Single;
