import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { WalletApi } from "src/api";
import { PaymentIdCard, TableList } from "./partials";
import Skeleton from "react-loading-skeleton";

type Props = {};

const PaymentIds = (props: Props) => {
  const { t } = useTranslation("paymentids/ts");

  const {
    data: PaymentIds,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["[List_Parent] Payment Ids"],
    queryFn: () =>
      WalletApi.getPaymentIds({}).then((result) => {
        return result.results;
      }),
    initialData: [],
  });

  return (
    <div className="w-full">
      {PaymentIds.length > 1 ? (
        <TableList />
      ) : (
        <>
          <h1 className="text-lg text-black mb-5">{t("page_title")}</h1>
          {isFetching ? (
            <Skeleton height={300} />
          ) : (
            PaymentIds.map((item: any, index: number) => (
              <PaymentIdCard index={index} item={item} />
            ))
          )}
        </>
      )}
    </div>
  );
};

export default PaymentIds;
