import { CloseCircle } from "iconsax-react";
import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";
import { InfoSquare } from "react-iconly";
import { CloseSvg } from "src/assets";
import { ColorPalette } from "src/utils";

type Props = {};

const AggreementAlert = (props: Props) => {
  const { i18n, t } = useTranslation("cart/ts");

  const [open, setOpen] = useState(true);

  return (
    <Collapse isOpened={open}>
      <div className="w-full px-4 pt-3 pb-4 bg-info-50 rounded-lg border border-info-500 flex items-start mb-5">
        <InfoSquare
          size={20}
          primaryColor={ColorPalette.info["500"]}
          style={{
            margin: i18n.dir() == "rtl" ? "0 0 0 12px" : "0 12px 0 0",
          }}
        />
        <div className="flex-1">
          <div className="w-full flex-col">
            <a className="text-sm text-primary-500 font-semibold">
              {t("info")}
            </a>
            {i18n.dir() == "rtl" ? (
              <p className="text-sm text-grey-700">
                {t("payment_agreement")}
                <strong className="font-bold text-info-500 underline">
                  {" "}{t("adsition_rules")}{" "}
                </strong>
                {t("payment_verb")}
              </p>
            ) : (
              <p className="text-sm text-grey-700">
                {t("payment_agreement")}{" "}
                <a
                  href="https://adsition.com/rules"
                  target={"_blank"}
                  className="font-bold text-info-500 underline"
                >
                  {t("adsition_rules")}
                </a>
              </p>
            )}
          </div>
        </div>
        <img src={CloseSvg} onClick={() => setOpen(false)} />
      </div>
    </Collapse>
  );
};

export default AggreementAlert;
