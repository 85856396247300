import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, OtpInput } from "src/components";

import ProgressBar from "@ramonak/react-progress-bar";

import { ColorPalette } from "src/utils";
import { TimeCircle } from "react-iconly";
import { CloseSvgRed, UsaFlag } from "src/assets";
import { Edit } from "iconsax-react";
import { useCountdown, useMediaQuery } from "src/hooks";
import moment from "moment";
import { ClipLoader } from "react-spinners";

type Props = {
  formik: any;
  step: number;
  backStep: () => void;
  resendOtp: () => void;
  resendLoading: boolean;
};

const Step2 = ({ formik, step, resendLoading, resendOtp, backStep }: Props) => {
  const { t, i18n } = useTranslation("dashboard/ts");
  const isMobile = useMediaQuery("(max-width: 1023px)");

  const [resend, setResend] = useState(false);
  const [targetDate, setTargetDate] = useState<number | null>();

  const countDown = useCountdown(targetDate, () => setResend(true), step == 2);

  useEffect(() => {
    if (step == 2) {
      let now = new Date();
      let Min2LaterDate = moment(now).add(2, "m").toDate().getTime();
      setTargetDate(Min2LaterDate);
    }
  }, [step]);

  useEffect(() => {
    if (formik.values.otp.length == 5) {
      formik.submitForm();
    }
  }, [formik.values]);

  return (
    <div className="w-full h-full flex flex-col items-center justify-between">
      <div className="w-full flex flex-col items-start">
        <h1
          className={`text-grey-800 ${
            isMobile ? "text-base" : "text-[32px]"
          } mb-2 font-semibold`}
        >
          {t("enter_otp")}
        </h1>
        <p className={`text-grey-700 ${isMobile ? "text-sm" : "text-lg"} mb-5`}>
          {t("5_digit_code")}
        </p>
        <div className="w-full flex justify-between">
          <div className="flex items-center">
            <span className={"text-lg text-grey-800 font-semibold"}>
              {formik.values.email}
            </span>
          </div>
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              backStep();
              formik.setFieldValue("otp", "");
            }}
          >
            <Edit size={16} color={ColorPalette.primary["500"]} />
            <span
              className={`${
                i18n.dir() == "rtl" ? "mr-2" : "ml-2"
              } text-[13px] text-primary-500`}
            >
              {t("edit_email")}
            </span>
          </div>
        </div>
        <div className="w-full mb-[30px]">
          <OtpInput
            value={formik.values.otp}
            error={formik.values.otp_error}
            numInputs={5}
            onChange={(otp) => {
              formik.setFieldValue("otp", otp);
              formik.setFieldValue("otp_error", false);
            }}
          />
        </div>
        <div className="w-full">
          <div className="w-full flex justify-between mb-[14px]">
            <span
              className={`text-base flex items-center gap-2 ${
                formik.values.otp_error ? "text-error-700" : "text-grey-700"
              }`}
            >
              {formik.values.otp_error && <img src={CloseSvgRed} />}
              {countDown &&
              parseInt(countDown.total_seconds) > 0 &&
              formik.values.otp_error
                ? t("code_wrong_alert_error")
                : t("in_process")}
            </span>
            <div className="flex items-center gap-1">
              <TimeCircle size={16} primaryColor={ColorPalette.grey["700"]} />
              <span className="text-base text-grey-800 font-bold">
                {countDown.minutes}:{countDown.seconds}
              </span>
            </div>
          </div>
          <ProgressBar
            completed={(parseInt(countDown.total_seconds) / 120) * 100}
            className="w-full"
            height={"8px"}
            borderRadius={"100px"}
            bgColor={ColorPalette.primary["500"]}
            isLabelVisible={false}
          />
        </div>
        <div className="w-full flex justify-end">
          <a
            href="#"
            className="my-[30px] text-xs text-primary-500"
            onClick={async () => {
              await resendOtp();
              let now = new Date();
              let Min2LaterDate = moment(now).add(2, "m").toDate().getTime();
              setTargetDate(Min2LaterDate);
            }}
          >
            {t("resend_code")}
            {resendLoading && (
              <ClipLoader size={20} color={ColorPalette.primary["500"]} />
            )}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Step2;
