import { useTranslation } from "react-i18next";
import { ChevronDown, ChevronUp } from "react-iconly";
import { useNavigate } from "react-router-dom";
import { Button, Input, Select } from "src/components";
import { useMediaQuery } from "src/hooks";
import { FormField } from "src/shared";
import { Helpers } from "src/utils";

type Props = {
  formik: any;
  step: number;
};

const Step1 = ({ formik, step }: Props) => {
  const { t, i18n } = useTranslation("wallet/ts");

  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width: 1023px)");

  return (
    <div className="w-full flex-1 flex flex-col justify-between">
      <div>
        <p className="mb-5 text-md text-grey-600">{t("enter_amount_desc")}</p>
        <div className="grid grid-cols-12 gap-3 mb-5">
          <div className="col-span-12">
            <FormField
              label={t("amount")}
              field={
                <div className="flex w-full">
                  <Input
                    addonClassName={`flex-1 ${
                      i18n.dir() == "rtl" ? "rounded-l-none" : "rounded-r-none"
                    }`}
                    placeholder={t("amount")}
                    sizes={isMobile ? "Small" : "Large"}
                    onChange={(e) => {
                      const amountToShow = Helpers.convertPerToEn(
                        e.target.value
                      )
                        .replace(/[^\d]/g, "")
                        .replace(/^0+/, "");
                      formik.setFieldValue("amount", Number(amountToShow));
                      formik.setFieldValue(
                        "amount_to_show",
                        amountToShow.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      );
                    }}
                    onFocus={() => formik.setFieldTouched("amount", true)}
                    value={formik.values.amount_to_show}
                    autoComplete={"on"}
                    onClear={() => formik.setFieldValue("amount", "")}
                  />
                  <div
                    className={`h-full bg-grey-100 p-[14px] text-sm text-grey-600 ${
                      i18n.dir() == "rtl" ? "rounded-l-lg" : "rounded-r-lg"
                    }`}
                  >
                    {t("toman")}
                  </div>
                </div>
              }
              message={
                formik.errors.amount &&
                formik.touched.amount && {
                  type: "ERROR",
                  content: formik.errors.amount,
                }
              }
            />
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex-1 gap-3 flex items-center">
          <a
            href="#"
            className={`w-[42px] h-[42px] flex justify-center items-center p-[8px] border border-primary-300 border-dashed rounded-[100px]`}
          >
            <span className="text-[10px] text-grey-600">
              <strong className="text-sm text-grey-800">{step}</strong>/2
            </span>
          </a>
          <Button addonClasses="w-full" onClick={() => formik.submitForm()}>
            {step == 1 ? t("next_step") : t("pay")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Step1;
