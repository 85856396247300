import { Minus } from "iconsax-react";
import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";
import { ChevronDown, ChevronUp } from "react-iconly";
import { ColorPalette } from "src/utils";

type Props = {
  description?: string;
  payment_id?: string;
  status?: string;
  destination_iban?: string;
  destination_owner_name?: string;
};

enum PAPYMENTIDSTATUS {
  VERIFIED = "VERIFIED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

const PaymentIdInfo = ({
  description,
  payment_id,
  status,
  destination_iban,
  destination_owner_name,
}: Props) => {
  const { t } = useTranslation("paymentids/ts");

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const getOrderStatusT = (status: string) => {
    switch (status) {
      case PAPYMENTIDSTATUS.VERIFIED:
        return t("accepted");
      case PAPYMENTIDSTATUS.PENDING:
        return t("pending");
      case PAPYMENTIDSTATUS.REJECTED:
        return t("processing");

      default:
        break;
    }
  };

  const getOrderStatusColor = (status: string) => {
    switch (status) {
      case PAPYMENTIDSTATUS.PENDING:
        return "bg-primary-50 text-primary-500";
      case PAPYMENTIDSTATUS.VERIFIED:
        return "bg-success-50 text-success-500";
      case PAPYMENTIDSTATUS.REJECTED:
        return "bg-error-50 text-error-500";

      default:
        break;
    }
  };

  return (
    <div className="w-full">
      <div className="w-full pt-10 px-[14px] pb-5 border border-grey-200 rounded-[20px] relative mt-[48px] mb-5">
        <div className="absolute top-[-24px] p-3 gap-2 border border-grey-200 rounded-lg bg-white flex items-center">
          <Minus size={24} color={ColorPalette.grey["800"]} />
          <span className="text-grey-800 text-sm">{t("payment_id_info")}</span>
        </div>
        <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
          <span className="text-grey-700 text-xs lg:text-sm">
            {t("description")}
          </span>
          <span className="text-grey-800 text-sm font-semibold">
            {description}
          </span>
        </div>
        <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
          <span className="text-grey-700 text-xs lg:text-sm">
            {t("payment_id")}
          </span>
          <span className="text-grey-800 text-sm font-semibold">
            {payment_id}
          </span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-grey-700 text-xs lg:text-sm">
            {t("status")}
          </span>
          <div
            className={`px-3 py-[5px] ${getOrderStatusColor(
              status
            )} rounded-lg text-xs text-center`}
          >
            {getOrderStatusT(status)}
          </div>
        </div>
      </div>
      <div className="px-4 py-5 rounded-[20px] bg-grey-50">
        <div
          className="flex justify-between items-center"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <h4 className="text-base font-semibold text-grey-800">
            {t("payment_detail")}:
          </h4>
          {isOpen ? (
            <ChevronUp size={20} primaryColor={ColorPalette.grey["700"]} />
          ) : (
            <ChevronDown size={20} primaryColor={ColorPalette.grey["700"]} />
          )}
        </div>
        <Collapse isOpened={isOpen}>
          <div className="mt-5 flex flex-col gap-[9px]">
            <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
              <span className="text-md text-grey-700">{t("sheba_number")}</span>
              <div className="flex items-center gap-3 text-md text-grey-800 font-semibold">
                {destination_iban}
              </div>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-md text-grey-700">
                {t("account_owner")}
              </span>
              <span className="flex items-center text-sm- text-grey-600">
                {destination_owner_name}
              </span>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default PaymentIdInfo;
