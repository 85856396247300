import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Paper, Show, Upload } from "react-iconly";
import { Link, useSearchParams } from "react-router-dom";
import { ColorPalette, Helpers } from "src/utils";
import { IOrdersDetailInterface, IWalletTransactionInterface } from "./types";
import { createColumnHelper } from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";
import { OrderApi, WalletApi } from "src/api";
import {
  CreditSec,
  DetailModal,
  EmptyWallet,
  FilterSec,
  Footer,
  IncreaseCredit,
} from "./partials";
import Skeleton from "react-loading-skeleton";
import { Table } from "src/components";
type Props = {};

enum TRANSACTION_STATUS {
  SUCCESS = "SUCCESS",
  PENDING = "PENDING",
  FAILED = "FAILED",
}

enum TRANSACTION_TYPE {
  DEPOSIT = "DEPOSIT",
  WITHDRAW = "WITHDRAW",
}

const Wallet = (props: Props) => {
  const { t, i18n } = useTranslation("wallet/ts");

  const [pageCount, setPageCount] = useState<number>(1);
  const [listDetail, setListDetail] = useState<IOrdersDetailInterface>();
  const [detailModal, setDetailModal] = useState<Helpers.keyable>({
    show: false,
    data: undefined,
    stamp: new Date(),
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const getPaymentStatusT = (status: string) => {
    switch (status.toUpperCase()) {
      case TRANSACTION_STATUS.SUCCESS:
        return t("successful");
      case TRANSACTION_STATUS.PENDING:
        return t("pending");
      case TRANSACTION_STATUS.FAILED:
        return t("failed");

      default:
        break;
    }
  };

  const getPaymentStatusColor = (status: string) => {
    switch (status.toUpperCase()) {
      case TRANSACTION_STATUS.PENDING:
        return "bg-primary-50 text-primary-500";
      case TRANSACTION_STATUS.SUCCESS:
        return "bg-success-50 text-success-500";
      case TRANSACTION_STATUS.FAILED:
        return "bg-error-50 text-error-500";

      default:
        break;
    }
  };

  const getTypeColor = (status: string) => {
    switch (status.toUpperCase()) {
      case TRANSACTION_TYPE.DEPOSIT:
        return "bg-primary-50 text-primary-500";
      case TRANSACTION_TYPE.WITHDRAW:
        return "bg-error-50 text-error-500";

      default:
        break;
    }
  };

  const columnHelper = createColumnHelper<IWalletTransactionInterface>();

  const columns = [
    columnHelper.accessor("index", {
      header: () => <span>#</span>,
      cell: ({ row }) => row.index + 1,
      enableSorting: false,
      size: 60,
    }),
    columnHelper.accessor("id", {
      header: () => <span>{t("payment_number")}</span>,
      cell: (row) => row.getValue(),
      enableSorting: false,
      meta: {
        mobile_header: t("payment_number"),
      },
    }),
    columnHelper.accessor("created_date", {
      header: () => <span>{t("date")}</span>,
      cell: (row) => row.getValue(),
      sortingFn: () => 1,
      meta: {
        mobile_header: t("date"),
      },
    }),
    columnHelper.accessor("currency", {
      header: () => <span>{t("currency")}</span>,
      cell: (row) => {
        return (
          <div className="flex items-center">
            <img
              src={row.getValue()?.icon}
              className="w-6 h-6"
              style={{
                margin: i18n.dir() == "rtl" ? "0 0 0 8px" : "0 8px 0 0",
              }}
            />
            <span className="text-sm text-grey-700">
              {t(row.getValue()?.name)}
            </span>
          </div>
        );
      },
      meta: {
        mobile_header: t("currency"),
      },
    }),
    columnHelper.accessor("amount", {
      header: () => <span>{t("amount")}</span>,
      cell: (row) => row.getValue().toLocaleString(),
      meta: {
        mobile_header: t("amount"),
      },
    }),
    columnHelper.accessor("type", {
      header: () => <span>{t("type")}</span>,
      cell: (row) => (
        <div className="flex items-center justify-start">
          <div
            className={`px-3 py-[5px] ${getTypeColor(
              row.getValue()
            )} rounded-lg text-xs text-center whitespace-nowrap`}
          >
            {t(row.getValue())}
          </div>
        </div>
      ),
      meta: {
        mobile_header: t("type"),
      },
    }),
    columnHelper.accessor("status", {
      header: () => (
        <div className="w-full flex items-center justify-end">
          <span>{t("status")}</span>
        </div>
      ),
      meta: {
        mobile_header: t("status"),
      },
      cell: (row) => (
        <div className="flex items-center justify-end">
          <div
            className={`px-3 py-[5px] ${getPaymentStatusColor(
              row.getValue()
            )} rounded-lg text-xs text-center whitespace-nowrap`}
          >
            {getPaymentStatusT(row.getValue())}
          </div>
        </div>
      ),
      enableSorting: false,
      size: 100,
    }),
    columnHelper.accessor("action", {
      header: () => <span></span>,
      cell: (row) => (
        <div
          className="cursor-pointer flex justify-end"
          onClick={() =>
            setDetailModal({
              show: true,
              stamp: new Date(),
              data: row.row.original,
            })
          }
        >
          <Show primaryColor={ColorPalette.grey["700"]} size={24} />
        </div>
      ),
      enableSorting: false,
      size: 200,
    }),
  ];

  const {
    data,
    isFetching: isQueryLoading,
    status,
    refetch,
  } = useQuery({
    queryKey: ["[List] Wallet Transcation"],
    queryFn: () =>
      WalletApi.getWalletTransactionList({
        params: Object.fromEntries(searchParams),
      }).then((result) => {
        return result;
      }),
    cacheTime: 0,
  });

  const result = React.useMemo(() => {
    if (!isQueryLoading && data && status === "success") {
      const { results, ...rest } = data;

      const page_size = parseInt(searchParams.get("page_size")) || 10;
      setPageCount(Math.ceil(rest.count / page_size));

      setListDetail(rest);
      return results.map(
        (item: IWalletTransactionInterface, index: number) => ({
          ...item,
          index,
        })
      );
    } else {
      return [];
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [searchParams]);

  return (
    <div className="w-full">
      <DetailModal
        isOpen={detailModal.show}
        setIsOpen={(state) =>
          setDetailModal({ ...detailModal, show: state, stamp: new Date() })
        }
        data={detailModal.data}
      />
      <FilterSec refetch={refetch} />
      <CreditSec />
      <div className="mb-[34px]">
        <Table
          data={result}
          columns={columns}
          title={t("wallet_records")}
          Icon={Paper}
          headerColor={"grey-100"}
          bordered={false}
          loading={isQueryLoading}
          onSort={(key) => {
            const sorted = searchParams.get("ordering") || "-";
            if (sorted.includes("-")) {
              searchParams.set("ordering", key);
            } else {
              searchParams.set("ordering", "-" + key);
            }
            searchParams.set("page", "1");
            setSearchParams(searchParams);
          }}
        />
      </div>
      {listDetail ? (
        pageCount > 1 && (
          <Footer
            pageCount={pageCount || 1}
            count={listDetail.count}
            t={t}
            i18n={i18n}
          />
        )
      ) : (
        <Skeleton height={54} />
      )}
    </div>
  );
};

export default Wallet;
