import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Plus } from "react-iconly";
import { CoreApi, OrderApi, ServiceApi } from "src/api";
import {
  AutoComplete,
  Button,
  CheckBox,
  Input,
  Modal,
  RadioButton,
  SearchDropDown,
  Select,
} from "src/components";
import { useMediaQuery, useSearchDebounce } from "src/hooks";
import { FormField } from "src/shared";
import { Helpers } from "src/utils";

import * as Yup from "yup";
import { useFormik } from "formik";

import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useAuthStore, useCommonStore } from "src/store";
import ToastMsg from "../ToastMsg";

type option = {
  label: string;
  value: any;
  image?: string;
  adwords_min_purchase?: number;
};

type accountType = "NEW" | "ADSITION" | "TRANSFERRED";

enum AccountTypes {
  NEW = "NEW",
  ADSITION = "ADSITION",
  TRANSFERRED = "TRANSFERRED",
}

interface IFormValues {
  currency: option;
  amount: number;
  account: any;
  account_type: accountType;
  account_id: option;
  email: string;
  website: string;
  transferred_account: string;
}

type Props = {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
};

const initialValues: IFormValues = {
  currency: undefined,
  amount: undefined,
  account: undefined,
  account_type: undefined,
  account_id: undefined,
  email: undefined,
  website: undefined,
  transferred_account: undefined,
};

const NewOrderModal = (props: Props) => {
  const { t } = useTranslation("orders/ts");
  const { t: validationTranslations } = useTranslation("validation/ts");
  const { isAuthenticated, setUser } = useAuthStore();

  const isMobile = useMediaQuery("(max-width: 1023px)");

  const { newOrderModalData, defaultAmount, defaultCur } = useCommonStore();

  const [adwordsSearch, setAdwordsSearch] = useSearchDebounce({ delay: 350 });
  const [minPurchase, setMinPurchase] = useState(0);

  const queryClient = useQueryClient();

  const newOrderMutuate = useMutation(OrderApi.postNewOrder, {
    onSuccess: (res) => {
      toast(
        <ToastMsg
          type="success"
          message={validationTranslations("data_store_success")}
        />
      );
      queryClient.invalidateQueries({
        queryKey: ["[List] Cart"],
      });
      queryClient.invalidateQueries({
        queryKey: ["[Info] Checkout"],
      });
      props.setIsOpen(false);
    },
    onError: (error: AxiosError) => {
      if (error.response.status == 400) {
        toast(<ToastMsg type="error" message={t("data_store_error")} />);
      }
    },
  });

  const updateCartMutuate = useMutation(OrderApi.updateCartItem, {
    onSuccess: (res) => {
      toast(
        <ToastMsg
          type="success"
          message={validationTranslations("data_store_success")}
        />
      );
      queryClient.invalidateQueries({
        queryKey: ["[List] Cart"],
      });
      queryClient.invalidateQueries({
        queryKey: ["[Info] Checkout"],
      });
      props.setIsOpen(false);
    },
    onError: (error: AxiosError) => {
      if (error.response.status == 400) {
        toast(<ToastMsg type="error" message={t("data_store_error")} />);
      }
    },
  });

  const currencyQuery = useQuery({
    queryKey: ["[Currency List] Orders"],
    queryFn: () =>
      CoreApi.getCurrencyList().then((result) => {
        return result.results;
      }),
    enabled: isAuthenticated,
  });

  const validationSchema = Yup.object({
    currency: Yup.mixed().required(validationTranslations("field_required")),
    amount: Yup.number()
      .min(minPurchase, `${t("min_purchase")} ${minPurchase}`)
      .required(validationTranslations("field_required")),
    account_type: Yup.string().required(
      validationTranslations("field_required")
    ),
    account_id: Yup.mixed().when("account_type", ([account_type], schema) => {
      return account_type == AccountTypes.ADSITION
        ? schema.required(validationTranslations("field_required"))
        : schema.notRequired();
    }),
    email: Yup.string().when("account_type", ([account_type], schema) => {
      return account_type == AccountTypes.NEW
        ? schema
            .email(validationTranslations("email"))
            .required(validationTranslations("field_required"))
        : schema.notRequired();
    }),
    website: Yup.string()
      .url(validationTranslations("website_field"))
      .transform((currentValue) => {
        const doesNotStartWithHttp =
          currentValue &&
          !(
            currentValue.startsWith("http://") ||
            currentValue.startsWith("https://")
          );

        if (doesNotStartWithHttp) {
          return `http://${currentValue}`;
        }
        return currentValue;
      })
      .when("account_type", ([account_type], schema) => {
        return account_type == AccountTypes.NEW ||
          account_type == AccountTypes.TRANSFERRED
          ? schema.required(validationTranslations("field_required"))
          : schema.notRequired();
      }),
    transferred_account: Yup.string().when(
      "account_type",
      ([account_type], schema) => {
        return account_type == AccountTypes.TRANSFERRED
          ? schema
              .min(10, validationTranslations("length_10"))
              .max(10, validationTranslations("length_10"))
              .required(validationTranslations("field_required"))
          : schema.notRequired();
      }
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) =>
      newOrderModalData
        ? updateCartMutuate.mutate({
            body: {
              ...values,
              currency: values.currency.value,
              account:
                values.account_type == AccountTypes.ADSITION
                  ? values.account_id.value
                  : undefined,
            },
            params: { id: newOrderModalData.id },
          })
        : newOrderMutuate.mutate({
            body: {
              ...values,
              currency: values.currency.value,
              account:
                values.account_type == AccountTypes.ADSITION
                  ? values.account_id.value
                  : undefined,
            },
          }),
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
  });

  const accountQuery = useQuery({
    queryKey: ["[Account List] Orders"],
    queryFn: () =>
      ServiceApi.getAdwordsAccount({
        params: {
          search: adwordsSearch,
          currency: formik.values.currency.value,
        },
      }).then((result) => {
        return result.results.map((item: Helpers.keyable) => ({
          label: item.number,
          value: item.id,
          data: item.currency,
        }));
      }),
    enabled: formik.values.account_type == AccountTypes.ADSITION,
  });

  useEffect(() => {
    accountQuery.refetch();
  }, [adwordsSearch]);

  useEffect(() => {
    if (newOrderModalData) {
      const values = newOrderModalData as IFormValues;
      const currency = values.currency as Helpers.keyable;
      formik.setValues({
        ...values,
        currency: {
          image: currency?.icon,
          value: currency.id,
          label: t(currency.name),
          adwords_min_purchase: currency.adwords_min_purchase,
        },
        account_id: values.account
          ? { label: values.account.number, value: values.account.id }
          : undefined,
      });
    }
  }, [newOrderModalData]);

  useEffect(() => {
    if (defaultAmount) {
      formik.setFieldValue("amount", defaultAmount);
    }
    console.log(defaultCur);
    if (defaultCur) {
      formik.setFieldValue("currency", defaultCur);
    }
  }, [defaultAmount, defaultCur]);

  useEffect(() => {
    if (!props.isOpen) {
      formik.resetForm();
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (formik.values.currency) {
      setMinPurchase(
        (formik.values.currency as Helpers.keyable).adwords_min_purchase
      );
      formik.validateForm();
    }
  }, [formik.values.currency]);

  return (
    <Modal
      title={t("new_order")}
      isOpen={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      showClose={false}
    >
      <div className="w-full h-full flex flex-col justify-between">
        <div className="w-full">
          <h4 className="text-base text-grey-800 font-semibold mb-[14px]">
            {t("charging_amount")}
          </h4>
          <div className="grid grid-cols-12 gap-3 mb-5">
            <div className="col-span-12 lg:col-span-6">
              <FormField
                label={t("currency_type")}
                field={
                  <Select
                    placeholder={t("currency_type")}
                    options={
                      currencyQuery.data
                        ? currencyQuery.data.map((item: Helpers.keyable) => ({
                            label: t(item.name),
                            value: item.id,
                            image: item.icon,
                            adwords_min_purchase: item.adwords_min_purchase,
                          }))
                        : []
                    }
                    value={formik.values.currency}
                    onChange={(data) => formik.setFieldValue("currency", data)}
                    disabled={
                      formik.values.account_type == AccountTypes.ADSITION
                    }
                  />
                }
                message={
                  formik.errors.currency &&
                  formik.touched.currency && {
                    type: "ERROR",
                    content: formik.errors.currency,
                  }
                }
              />
            </div>
            <div className="col-span-12 lg:col-span-6">
              <FormField
                label={t("amount")}
                field={
                  <Input
                    id="amount-inp"
                    placeholder={t("amount")}
                    sizes={isMobile ? "Small" : "Large"}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "amount",
                        Helpers.convertPerToEn(e.target.value)
                          .replace(/[^\d]/g, "")
                          .replace(/^0+/, "")
                      );
                    }}
                    onFocus={() => formik.setFieldTouched("amount", true)}
                    value={formik.values.amount}
                    autoComplete={"on"}
                    onClear={() => formik.setFieldValue("amount", "")}
                  />
                }
                message={
                  formik.errors.amount &&
                  formik.touched.amount && {
                    type: "ERROR",
                    content: formik.errors.amount,
                  }
                }
              />
            </div>
          </div>
          <h4 className="text-base text-grey-800 font-semibold mb-[14px]">
            {t("account")}
          </h4>
          <div className="flex itemx-center flex-wrap gap-5 mb-5">
            <RadioButton
              label={t("req_new_account")}
              checked={formik.values.account_type == AccountTypes.NEW}
              onChange={(e) =>
                e.target.checked &&
                formik.setFieldValue("account_type", AccountTypes.NEW)
              }
            />
            <RadioButton
              label={t("use_exist_account")}
              checked={formik.values.account_type == AccountTypes.ADSITION}
              onChange={(e) =>
                e.target.checked &&
                formik.setFieldValue("account_type", AccountTypes.ADSITION)
              }
            />
            <RadioButton
              label={t("transfer_account")}
              checked={formik.values.account_type == AccountTypes.TRANSFERRED}
              onChange={(e) =>
                e.target.checked &&
                formik.setFieldValue("account_type", AccountTypes.TRANSFERRED)
              }
            />
          </div>
          {formik.values.account_type == AccountTypes.ADSITION ? (
            <div className="w-full mb-7">
              <FormField
                label={t("account_number")}
                field={
                  <SearchDropDown
                    placeholder={t("account_number")}
                    onSearch={(e: any) => {
                      const value = Helpers.convertPerToEn(e)
                        .replace(/[^\d]/g, "")
                        .replace(/^0+/, "");
                      setAdwordsSearch(value);
                    }}
                    value={formik.values.account_id}
                    options={accountQuery.data || []}
                    onChange={(e) => {
                      formik.setFieldValue("currency", {
                        label: e.data.name,
                        value: e.data.id,
                        adwords_min_purchase: e.data.adwords_min_purchase,
                        image: e.data.icon,
                      });
                      formik.setFieldValue("account_id", e);
                    }}
                  />
                }
                message={
                  formik.errors.account_id &&
                  formik.touched.account_id && {
                    type: "ERROR",
                    content: formik.errors.account_id,
                  }
                }
              />
            </div>
          ) : formik.values.account_type == AccountTypes.NEW ? (
            <>
              <div className="w-full mb-7">
                <FormField
                  label={t("email_field")}
                  field={
                    <Input
                      placeholder={t("email")}
                      sizes={isMobile ? "Small" : "Large"}
                      onChange={(e) => {
                        formik.setFieldValue("email", e.target.value);
                      }}
                      onFocus={() => formik.setFieldTouched("email", true)}
                      value={formik.values.email}
                      autoComplete={"on"}
                      onClear={() => formik.setFieldValue("email", "")}
                    />
                  }
                  message={
                    formik.errors.email &&
                    formik.touched.email && {
                      type: "ERROR",
                      content: formik.errors.email,
                    }
                  }
                />
              </div>
              <div className="w-full mb-7">
                <FormField
                  label={t("website_field")}
                  field={
                    <Input
                      placeholder={t("website_field")}
                      sizes={isMobile ? "Small" : "Large"}
                      onChange={(e) => {
                        formik.setFieldValue("website", e.target.value);
                      }}
                      onFocus={() => formik.setFieldTouched("website", true)}
                      value={formik.values.website}
                      autoComplete={"on"}
                      onClear={() => formik.setFieldValue("website", "")}
                    />
                  }
                  message={
                    formik.errors.website &&
                    formik.touched.website && {
                      type: "ERROR",
                      content: formik.errors.website,
                    }
                  }
                />
              </div>
            </>
          ) : (
            formik.values.account_type == AccountTypes.TRANSFERRED && (
              <>
                <div className="w-full mb-7">
                  <FormField
                    label={t("website_field")}
                    field={
                      <Input
                        placeholder={t("website_field")}
                        sizes={isMobile ? "Small" : "Large"}
                        onChange={(e) => {
                          formik.setFieldValue("website", e.target.value);
                        }}
                        onFocus={() => formik.setFieldTouched("website", true)}
                        value={formik.values.website}
                        autoComplete={"on"}
                        onClear={() => formik.setFieldValue("website", "")}
                      />
                    }
                    message={
                      formik.errors.website &&
                      formik.touched.website && {
                        type: "ERROR",
                        content: formik.errors.website,
                      }
                    }
                  />
                </div>
                <div className="w-full mb-7">
                  <FormField
                    label={t("account_number")}
                    field={
                      <Input
                        placeholder={t("account_number")}
                        sizes={isMobile ? "Small" : "Large"}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "transferred_account",
                            Helpers.convertPerToEn(e.target.value)
                              .replace(/[^\d]/g, "")
                              .replace(/^0+/, "")
                          );
                        }}
                        onFocus={() =>
                          formik.setFieldTouched("transferred_account", true)
                        }
                        value={formik.values.transferred_account}
                        autoComplete={"on"}
                        onClear={() =>
                          formik.setFieldValue("transferred_account", "")
                        }
                      />
                    }
                    message={
                      formik.errors.transferred_account &&
                      formik.touched.transferred_account && {
                        type: "ERROR",
                        content: formik.errors.transferred_account,
                      }
                    }
                  />
                </div>
              </>
            )
          )}
        </div>
        <div className="flex justify-center lg:justify-end">
          <div
            className={`w-full lg:max-w-sm grid grid-cols-12 
          gap-3`}
          >
            <div className="col-span-6">
              <Button
                addonClasses="w-full"
                type="Secondary"
                onClick={() => props.setIsOpen(false)}
              >
                {t("cancel")}
              </Button>
            </div>
            <div className="col-span-6">
              <Button
                addonClasses="w-full"
                icon={<Plus size={20} />}
                onClick={() => formik.submitForm()}
                disabled={!formik.isValid}
                loading={
                  newOrderMutuate.isLoading || updateCartMutuate.isLoading
                }
              >
                {t("add_to_cart")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NewOrderModal;
