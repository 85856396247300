import { Danger, TickSquare } from "iconsax-react";
import React from "react";

type Props = {
  label: string;
  field: React.ReactNode;
  message?: {
    type: "ERROR" | "SUCCESS";
    content: any;
  };
};

const FormField = (props: Props) => {
  const getColorStyles = () => {
    switch (props.message?.type) {
      case "SUCCESS":
        return "text-xs text-error-500 [&>svg]:text-success-500";

      case "ERROR":
        return "text-xs text-error-500 [&>svg]:text-error-500";

      default:
        return "text-xs text-error-500 [&>svg]:text-error-500";
    }
  };

  const getIcon = () => {
    switch (props.message?.type) {
      case "SUCCESS":
        return <TickSquare size={16} />;

      case "ERROR":
        return <Danger size={16} />;

      default:
        return <Danger size={16} />;
    }
  };

  return (
    <div className="flex flex-col">
      <label className="text-xs text-grey-600 font-semiBold mb-2">
        {props.label}
      </label>
      {props.field}
      {props.message && (
        <div className={`flex items-center gap-[6px] mt-2 px-[14px] ${getColorStyles()}`}>
          <div>{getIcon()}</div>
          {props.message.content}
        </div>
      )}
    </div>
  );
};

export default FormField;
