import { AuthApi } from "src/api";
import { Helpers } from "src/utils";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface keyable {
  [key: string]: any;
}

interface ICommonStore {
  newOrderModal: boolean;
  newOrderModalData: Helpers.keyable;
  defaultCur?: Helpers.keyable;
  defaultAmount?: number;
  setNewOrderModal: (
    state: boolean,
    data?: Helpers.keyable | undefined,
    defaultCur?: Helpers.keyable,
    defaultAmount?: number
  ) => void;
}

const useCommonStore = create<ICommonStore>((set, get) => ({
  newOrderModal: false,
  newOrderModalData: undefined,
  setNewOrderModal: (
    state: boolean,
    data: Helpers.keyable | undefined,
    defaultCur: Helpers.keyable,
    defaultAmount: number
  ) =>
    set({
      newOrderModal: state,
      newOrderModalData: state ? data : undefined,
      defaultCur,
      defaultAmount,
    }),
}));

export default useCommonStore;
