import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Chat, Ticket } from "react-iconly";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { ColorPalette } from "src/utils";
import { MessageTab, TicketInfo } from "../partials";

type Props = {};

const Mobile = (props: Props) => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  const { t } = useTranslation("tickets/ts");

  return (
    <Tabs
      className={"w-full"}
      selectedIndex={tabIndex}
      onSelect={(index) => setTabIndex(index)}
    >
      <TabList
        className="border-0 p-3 rounded-2xl bg-primary-50 flex justify-between items-center outline:none mb-5"
      >
        <Tab
          style={{
            background:
              tabIndex == 0 ? ColorPalette.primary["100"] : "transparent",
          }}
          className="flex-1 after:d-none cursor-pointer py-2 px-5 flex items-center justify-center gap-2 !rounded-lg outline:none"
        >
          <Ticket
            filled={tabIndex == 0}
            size={20}
            primaryColor={
              tabIndex == 0
                ? ColorPalette.primary["500"]
                : ColorPalette.grey["700"]
            }
          />
          <span
            className={`text-sm ${
              tabIndex == 0 ? "text-primary-500 font-bold" : "text-grey-700"
            }`}
          >
            {t("ticket_info")}
          </span>
        </Tab>
        <Tab
          style={{
            background:
              tabIndex == 1 ? ColorPalette.primary["100"] : "transparent",
          }}
          className="flex-1 after:d-none cursor-pointer py-2 px-5 flex items-center justify-center gap-2 !rounded-lg outline:none"
        >
          <Chat
            filled={tabIndex == 0}
            size={20}
            primaryColor={
              tabIndex == 0
                ? ColorPalette.primary["500"]
                : ColorPalette.grey["700"]
            }
          />
          <span
            className={`text-sm ${
              tabIndex == 1 ? "text-primary-500 font-bold" : "text-grey-700"
            }`}
          >
            {t("message")}
          </span>
        </Tab>
      </TabList>

      <TabPanel>
        <TicketInfo />
      </TabPanel>
      <TabPanel>
        <MessageTab />
      </TabPanel>
    </Tabs>
  );
};

export default Mobile;
