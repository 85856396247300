import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import { Plus } from "react-iconly";
import Skeleton from "react-loading-skeleton";
import { CoreApi } from "src/api";
import { UsaFlag } from "src/assets";
import { Button } from "src/components";
import { useMediaQuery } from "src/hooks";
import { useCommonStore } from "src/store";
import { Helpers } from "src/utils";

type Props = {};

const ExchangeRate = (props: Props) => {
  const { t, i18n } = useTranslation("dashboard/ts");

  const { setNewOrderModal } = useCommonStore();

  const isMobile = useMediaQuery("(max-width:1023px");

  const { data: currencies } = useQuery({
    queryKey: ["[Currency List] Orders"],
    queryFn: () =>
      CoreApi.getCurrencyList().then((result) => {
        return result.results;
      }),
  });

  return (
    <div className="h-full py-5 px-[14px] lg:px-4 rounded-[20px] border border-grey-200">
      <div className="w-full flex justify-between items-center mb-[22px]">
        <h4 className="text-sm lg:text-lg font-semibold text-grey-800">
          {t("exchange_rate")}
        </h4>
        <div className="flex items-center">
          <span
            className={`text-[10px] lg:text-xs text-grey-700 ${
              i18n.dir() == "rtl" ? "ml-2" : "mr-2"
            }`}
          >
            {t("last_update")}:
          </span>
          <span className="text-xs text-grey-800 font-semibold lg:text-sm">
            {currencies &&
              currencies.length > 0 &&
              new Date(currencies[0].last_update).toLocaleDateString(
                i18n.dir() == "rtl" ? "fa-IR" : "en-US",
                {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                }
              )}
          </span>
        </div>
      </div>
      <div className="w-full grid grid-cols-12 gap-[14px] lg:gap-5 mb-[30px] lg:mb-[42px]">
        {!currencies
          ? [1, 2, 3, 4].map(() => (
              <div className="col-span-12 lg:col-span-6">
                <Skeleton height={52} width={"100%"} />
              </div>
            ))
          : currencies.map((item: Helpers.keyable) => (
              <div className="col-span-12 lg:col-span-6">
                <div className="w-full p-[14px] bg-grey-100 rounded-xl flex justify-between items-center">
                  <div className="flex items-center">
                    <img
                      src={item?.icon}
                      style={{
                        margin: i18n.dir() == "rtl" ? "0 0 0 8px" : "0 8px 0 0",
                        objectFit: "cover",
                      }}
                      className="w-6 h-6"
                    />
                    <span className="text-xs lg:text-sm text-grey-700">
                      {t(item?.name)}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span
                      style={{
                        margin: i18n.dir() == "rtl" ? "0 0 0 8px" : "0 8px 0 0",
                      }}
                      className="text-base text-grey-800 font-bold"
                    >
                      {item.last_price.toLocaleString()}
                    </span>
                    <span className="text-xs lg:text-sm text-grey-600">
                      {t("toman")}
                    </span>
                  </div>
                </div>
              </div>
            ))}
      </div>
      <Button
        addonClasses="w-full"
        size={isMobile ? "Small" : "Medium"}
        icon={<Plus size={20} />}
        onClick={() => setNewOrderModal(true)}
      >
        {t("new_order")}
      </Button>
    </div>
  );
};

export default ExchangeRate;
