import React from "react";
import { useTranslation } from "react-i18next";
import { Plus } from "react-iconly";
import { Button, CheckBox, Input, Modal } from "src/components";
import { useMediaQuery } from "src/hooks";
import { FormField } from "src/shared";

type Props = {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
};

const OrderModal = (props: Props) => {
  const { t } = useTranslation("orders/ts");

  const isMobile = useMediaQuery("(max-width: 1023px)");

  return (
    <Modal
      title={t("new_order")}
      isOpen={props.isOpen}
      onClose={() => props.setIsOpen(false)}
    >
      <h4 className="text-base text-grey-800 font-semibold mb-[14px]">
        {t("charging_amount")}
      </h4>
      <div className="grid grid-cols-12 gap-3 mb-5">
        <div className="col-span-12 lg:col-span-6">
          <FormField
            label={t("currency_type")}
            field={
              <Input
                placeholder={t("currency_type")}
                sizes={isMobile ? "Small" : "Large"}
              />
            }
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <FormField
            label={t("amount")}
            field={
              <Input
                placeholder={t("amount")}
                sizes={isMobile ? "Small" : "Large"}
              />
            }
          />
        </div>
      </div>
      <h4 className="text-base text-grey-800 font-semibold mb-[14px]">
        {t("account")}
      </h4>
      <div className="flex itemx-center gap-5 mb-32">
        <CheckBox label={t("req_new_account")} />
        <CheckBox label={t("use_exist_account")} />
      </div>
      <div className="w-full grid grid-cols-12 gap-3">
        <div className="col-span-6">
          <Button addonClasses="w-full" icon={<Plus size={24} />}>
            {t("add_to_cart")}
          </Button>
        </div>
        <div className="col-span-6">
          <Button addonClasses="w-full" type="Secondary">
            {t("cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default OrderModal;
