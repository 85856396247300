import { AxiosCustomRequestConfig, http } from "../../services/Api";

interface keyable {
  [key: string]: any;
}

export const postNewOrder = async ({ body }: AxiosCustomRequestConfig) => {
  const response = http.post("/order/adwords-cart-item/", body);
  const data = (await response).data;
  return data;
};

export const getCartItems = async () => {
  const response = http.get("/order/cart/");
  const data = (await response).data;
  return data;
};

export const deleteCartItem = async (id: number) => {
  const response = http.delete(`/order/adwords-cart-item/${id}`);
  const data = (await response).data;
  return data;
};

export const updateCartItem = async ({
  params,
  body,
}: AxiosCustomRequestConfig) => {
  const response = http.put(`/order/adwords-cart-item/${params.id}/`, body);
  const data = (await response).data;
  return data;
};

export const getOrderList = async ({ params }: AxiosCustomRequestConfig) => {
  const response = http.get("/order/order/", { params });
  const data = (await response).data;
  return data;
};

export const getSingleOrder = async ({ params }: AxiosCustomRequestConfig) => {
  const response = http.get(`/order/order/${params.id}/`);
  const data = (await response).data;
  return data;
};

export const getDashboardKpi = async () => {
  const response = http.get(`/order/dashboard-kpi/`);
  const data = (await response).data;
  return data;
};

export const getCheckoutInfo = async () => {
  const response = http.get(`/order/checkout-info/`);
  const data = (await response).data;
  return data;
};
