import { AxiosCustomRequestConfig, http } from "../../services/Api";

interface keyable {
  [key: string]: any;
}

export const getWalletTransactionList = async ({
  params,
}: AxiosCustomRequestConfig) => {
  const response = http.get("/wallet/wallet-transaction/", { params });
  const data = (await response).data;
  return data;
};

export const getUserWallets = async ({ params }: AxiosCustomRequestConfig) => {
  const response = http.get("/wallet/wallet/", { params });
  const data = (await response).data;
  return data;
};

export const createPaymentId = async () => {
  const response = http.get("/payment/create-payment-id/");
  const data = (await response).data;
  return data;
};

export const getPaymentIds = async ({ params }: AxiosCustomRequestConfig) => {
  const response = http.get("/payment/get-payment-id/", { params });
  const data = (await response).data;
  return data;
};

export const SwapToman = async ({ body }: AxiosCustomRequestConfig) => {
  const response = http.post("/wallet/swap-toman/", body);
  const data = (await response).data;
  return data;
};
