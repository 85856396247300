import React from "react";
import { useTranslation } from "react-i18next";
import { Calendar } from "react-iconly";
import { IconButton } from "src/components";
import { ColorPalette } from "src/utils";
// @ts-ignore
import { faker } from "@faker-js/faker";
import { Line } from "react-chartjs-2";
import PaymentList from "../PaymentList";

type Props = {};

const Reports = (props: Props) => {
  const { t } = useTranslation("paymentids/ts");

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        border: { dash: [4, 4] },
      },
    },
    elements: {
      point: {
        radius: 7,
        hoverRadius: 10,
        backgroundColor: ColorPalette.primary["500"],
        borderColor: ColorPalette.primary["500"],
      },
    },
    pointBackgroundColor: ColorPalette.primary["500"],
    pointBorderColor: ColorPalette.primary["500"],
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 2",
        data: labels.map(() =>
          faker.datatype.number({ min: -1000, max: 1000 })
        ),
        borderColor: "#66A3FF",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <div className="mt-5 w-full">
      {/* <div className="p-5 mb-5 border border-grey-200 rounded-[20px]">
        <div className="flex items-center justify-between mb-10">
          <h4 className="text-lg text-grey-800 font-semibold">
            {t("order_chart")}
          </h4>
          <div className="flex gap-3 items-center">
            <div className="rounded-lg bg-grey-100 py-3 px-4 text-[13px] text-grey-700 cursor-pointer">
              {t("yearly")}
            </div>
            <div className="rounded-lg bg-grey-100 py-3 px-4 text-[13px] text-grey-700 cursor-pointer">
              {t("monthly")}
            </div>
            <div className="rounded-lg bg-primary-100 py-3 px-4 text-[13px] text-primary-400 cursor-pointer">
              {t("weekly")}
            </div>
            <IconButton
              bgColor="bg-primary-500"
              bgColorHovered="hover:bg-primary-700"
              ringColor="ring:bg-primary-200"
              icon={
                <Calendar size={20} primaryColor={ColorPalette.grey["50"]} />
              }
              size={"Medium"}
              onClick={() => {}}
            />
          </div>
        </div>
        <Line options={options} data={data} />
      </div> */}
      <PaymentList />
    </div>
  );
};

export default Reports;
