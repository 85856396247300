import { Minus } from "iconsax-react";
import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";
import { ChevronDown, ChevronUp } from "react-iconly";
import { ColorPalette, Helpers } from "src/utils";

type Props = {
  name: string;
  number: string;
  website: string;
  type: string;
  status: string;
  currency: Helpers.keyable;
};

enum ACCOUNT_TYPE {
  CREATED = "NEW",
  TRANSFERRED = "TRANSFERRED",
}

enum ACCOUNT_STATUS {
  ACTIVE = "ACTIVE",
  DEACTIVE = "DEACTIVE",
}

const AccountInfo = ({
  name,
  number,
  website,
  type,
  status,
  currency,
  ...props
}: Props) => {
  const { t } = useTranslation("accounts/ts");

  const getAccountTypeT = (status: string) => {
    switch (status) {
      case ACCOUNT_TYPE.TRANSFERRED:
        return t("transferred");
      case ACCOUNT_TYPE.CREATED:
        return t("created");

      default:
        break;
    }
  };

  const getAccountTypeColor = (status: string) => {
    switch (status) {
      case ACCOUNT_TYPE.TRANSFERRED:
        return "bg-primary-50 text-primary-500";
      case ACCOUNT_TYPE.CREATED:
        return "bg-success-50 text-success-500";

      default:
        break;
    }
  };

  const getAccountStatusT = (status: string) => {
    switch (status) {
      case ACCOUNT_STATUS.ACTIVE:
        return t("active");
      case ACCOUNT_STATUS.DEACTIVE:
        return t("deactive");

      default:
        break;
    }
  };

  const getAccountStatusColor = (status: string) => {
    switch (status) {
      case ACCOUNT_STATUS.ACTIVE:
        return "text-success-700";
      case ACCOUNT_STATUS.DEACTIVE:
        return "text-grey-500";

      default:
        break;
    }
  };

  return (
    <div className="w-full">
      <div className="w-full pt-10 px-[14px] pb-5 border border-grey-200 rounded-[20px] relative mt-[48px] mb-5">
        <div className="absolute top-[-24px] p-3 gap-2 border border-grey-200 rounded-lg bg-white flex items-center">
          <Minus size={24} color={ColorPalette.grey["800"]} />
          <span className="text-grey-800 text-sm">{t("account_info")}</span>
        </div>
        <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
          <span className="text-grey-700 text-xs lg:text-sm">
            {t("account_name")}
          </span>
          <span className="text-grey-800 text-sm font-semibold">{name}</span>
        </div>
        <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
          <span className="text-grey-700 text-xs lg:text-sm">
            {t("account_number")}
          </span>
          <span className="text-grey-800 text-sm font-semibold">{number}</span>
        </div>
        <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
          <span className="text-grey-700 text-xs lg:text-sm">
            {t("website_address")}
          </span>
          <span className="text-grey-800 text-sm font-semibold">{website}</span>
        </div>
        <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
          <span className="text-grey-700 text-xs lg:text-sm">
            {t("account_info")}
          </span>
          <span className="text-grey-800 text-sm font-semibold">
            {
              <div className="flex items-center gap-2">
                <img src={currency?.icon} className="w-6 h-6" />
                <span className="text-sm text-grey-700">
                  {t(currency?.name)}
                </span>
              </div>
            }
          </span>
        </div>
        <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
          <span className="text-grey-700 text-xs lg:text-sm">{t("type")}</span>
          <span className="text-grey-800 text-sm font-semibold">
            <div
              className={`px-3 py-[5px] ${getAccountTypeColor(
                type
              )} rounded-lg text-xs text-center`}
            >
              {getAccountTypeT(type)}
            </div>
          </span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-grey-700 text-xs lg:text-sm">
            {t("status")}
          </span>
          <span className="text-grey-800 text-sm font-semibold">
            <div
              className={`px-3 py-[5px] ${getAccountStatusColor(
                status
              )} rounded-lg text-xs text-center`}
            >
              {getAccountStatusT(status)}
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;
