import React from "react";
import { ClipLoader } from "react-spinners";

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  bgColor: string;
  bgColorHovered: string;
  ringColor: string;
  size: "Small" | "Medium" | "Large";
  icon: React.ReactNode;
  loading?: boolean;
}

const Classes = (list: any) => {
  return list.filter(Boolean).join(" ");
};

const IconButton = ({
  bgColor,
  bgColorHovered,
  ringColor,
  ...props
}: IButtonProps) => {
  const getSizeClasses = () => {
    switch (props.size) {
      case "Small":
        return "w-8 h-8";
      case "Medium":
        return "w-[42px] h-[42px]";
      case "Large":
        return "w-12 h-12";

      default:
        return "w-12 h-12";
    }
  };

  const getBaseStyles = () => {
    return `text-white flex items-center justify-center rounded-lg ${bgColor} ${bgColorHovered} ${ringColor} transition-background duration-300 cursor-pointer`;
  };

  return (
    <button {...props} className={Classes([getBaseStyles(), getSizeClasses()])}>
      {props.loading ? (
        <ClipLoader
          color="white"
          size={props.size == "Small" ? 16 : props.size == "Medium" ? 20 : 24}
          loading={true}
        />
      ) : (
        props.icon
      )}
    </button>
  );
};

export default IconButton;
