import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Calendar,
  Edit,
  Filter2,
  Paper,
  Plus,
  Search,
  Show,
  Upload,
} from "react-iconly";
import { Link, useSearchParams } from "react-router-dom";
import { ColorPalette, Helpers } from "src/utils";
import { IOrdersDetailInterface, IOrdersInterface } from "./types";
import { createColumnHelper } from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";
import { OrderApi } from "src/api";
import { EmptyOrder, FilterSec, Footer } from "./partials";
import Skeleton from "react-loading-skeleton";
import { Table } from "src/components";
type Props = {};

enum ORDER_STATUS {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
  REFUNDED = "REFUNDED",
}

const Orders = (props: Props) => {
  const { t, i18n } = useTranslation("orders/ts");

  const [pageCount, setPageCount] = useState<number>(1);
  const [listDetail, setListDetail] = useState<IOrdersDetailInterface>();

  const [searchParams, setSearchParams] = useSearchParams();

  const getOrderStatusT = (status: string) => {
    switch (status) {
      case ORDER_STATUS.DRAFT:
        return t("draft");
      case ORDER_STATUS.PENDING:
        return t("pending");
      case ORDER_STATUS.PROCESSING:
        return t("processing");
      case ORDER_STATUS.COMPLETED:
        return t("completed");
      case ORDER_STATUS.CANCELED:
        return t("canceled");
      case ORDER_STATUS.REFUNDED:
        return t("refunded");

      default:
        break;
    }
  };

  const getOrderStatusColor = (status: string) => {
    switch (status) {
      case ORDER_STATUS.DRAFT:
        return "bg-grey-50 text-grey-500";
      case ORDER_STATUS.PENDING:
        return "bg-primary-50 text-primary-500";
      case ORDER_STATUS.PROCESSING:
        return "bg-warning-50 text-warning-500";
      case ORDER_STATUS.COMPLETED:
        return "bg-success-50 text-success-500";
      case ORDER_STATUS.CANCELED:
        return "bg-error-50 text-error-500";
      case ORDER_STATUS.REFUNDED:
        return "bg-secondary-50 text-secondary-500";

      default:
        break;
    }
  };

  const columnHelper = createColumnHelper<IOrdersInterface>();

  const columns = [
    columnHelper.accessor("index", {
      header: () => <span>#</span>,
      cell: ({ row }) => row.index + 1,
      enableSorting: false,
      size: 60,
    }),
    columnHelper.accessor("id", {
      header: () => <span>{t("payment_number")}</span>,
      cell: (row) => row.getValue(),
      enableSorting: false,
      meta: {
        mobile_header: t("payment_number"),
      },
    }),
    columnHelper.accessor("account_number", {
      header: () => <span>{t("account_number")}</span>,
      cell: (row) =>
        row.row.original.adwords_order_detail.length > 0
          ? row.row.original.adwords_order_detail[0]?.account
            ? row.row.original.adwords_order_detail[0]?.account.toLocaleString()
            : ""
          : "",
      enableSorting: false,
      meta: {
        mobile_header: t("account_number"),
      },
    }),
    columnHelper.accessor("created_date", {
      header: () => <span>{t("date")}</span>,
      cell: (row) => row.getValue(),
      sortingFn: () => 1,
      meta: {
        mobile_header: t("date"),
      },
    }),
    columnHelper.accessor("currency", {
      header: () => <span>{t("currency")}</span>,
      cell: (row) => {
        const cur =
          row.row.original.adwords_order_detail.length > 0
            ? row.row.original.adwords_order_detail[0].currency
            : undefined;

        if (cur) {
          return (
            <div className="flex items-center">
              <img
                src={cur?.icon}
                className="w-6 h-6"
                style={{
                  margin: i18n.dir() == "rtl" ? "0 0 0 8px" : "0 8px 0 0",
                }}
              />
              <span className="text-sm text-grey-700">{cur?.name}</span>
            </div>
          );
        } else {
          return <></>;
        }
      },
      meta: {
        mobile_header: t("currency"),
      },
    }),
    columnHelper.accessor("amount", {
      header: () => <span>{t("amount")}</span>,
      cell: (row) =>
        row.row.original.adwords_order_detail.length > 0
          ? row.row.original.adwords_order_detail[0].amount.toLocaleString()
          : "",
      meta: {
        mobile_header: t("amount"),
      },
    }),
    columnHelper.accessor("status", {
      header: () => (
        <div className="w-full flex items-center justify-start">
          <span>{t('status')}</span>
        </div>
      ),
      meta: {
        mobile_header: t("status"),
      },
      cell: (row) => (
        <div className="flex items-center justify-start whitespace-nowrap">
          <div
            className={`px-3 py-[5px] ${getOrderStatusColor(
              row.getValue()
            )} rounded-lg text-xs text-center`}
          >
            {getOrderStatusT(row.getValue())}
          </div>
        </div>
      ),
      enableSorting: false,
      size: 100,
    }),
    columnHelper.accessor("action", {
      header: () => <span></span>,
      cell: (row) => (
        <Link
          to={`/orders/single/${row.row.original.id}`}
          className="flex items-center justify-end"
        >
          <Show primaryColor={ColorPalette.grey["700"]} size={24} />
        </Link>
      ),
      enableSorting: false,
      size: 200,
    }),
  ];

  const {
    data,
    isFetching: isQueryLoading,
    status,
    refetch,
  } = useQuery({
    queryKey: ["[List] Order"],
    queryFn: () =>
      OrderApi.getOrderList({
        params: Object.fromEntries(searchParams),
      }).then((result) => {
        return result;
      }),
    cacheTime: 0,
  });

  const result = React.useMemo(() => {
    if (!isQueryLoading && data && status === "success") {
      const { results, ...rest } = data;

      const page_size = parseInt(searchParams.get("page_size")) || 10;
      setPageCount(Math.ceil(rest.count / page_size));

      setListDetail(rest);
      return results.map((item: IOrdersInterface, index: number) => ({
        ...item,
        index,
      }));
    } else {
      return [];
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [searchParams]);

  if (result.length == 0 && !isQueryLoading && Array.from(searchParams).length == 0) {
    return <EmptyOrder />;
  } else
    return (
      <div className="w-full">
        <FilterSec refetch={refetch} />
        <div className="mb-[34px]">
          <Table
            data={result}
            columns={columns}
            title={t("order_list")}
            Icon={Paper}
            headerColor={"grey-100"}
            bordered={false}
            loading={isQueryLoading}
            onSort={(key) => {
              const sorted = searchParams.get("ordering") || "-";
              if (sorted.includes("-")) {
                searchParams.set("ordering", key);
              } else {
                searchParams.set("ordering", "-" + key);
              }
              searchParams.set("page", "1");
              setSearchParams(searchParams);
            }}
          />
        </div>
        {listDetail ? (
          pageCount > 1 && (
            <Footer
              pageCount={pageCount || 1}
              count={listDetail.count}
              t={t}
              i18n={i18n}
            />
          )
        ) : (
          <Skeleton height={54} />
        )}
      </div>
    );
};

export default Orders;
