import { useTranslation } from "react-i18next";
import { Modal } from "src/components";
import { ChevronDown, ChevronUp, Download, Plus } from "react-iconly";
import { ColorPalette, Helpers } from "src/utils";

type Props = {
  data: Helpers.keyable;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
};

enum WALLET_TRANSACTION_STATUS {
  SUCCESSFUL = "SUCCESS",
  FAILED = "FAILED",
  PENDING = "PENDING",
}

const DetailModal = (props: Props) => {
  const { t, i18n } = useTranslation("wallet/ts");

  const getPaymentStatusT = (status: string) => {
    switch (status.toUpperCase()) {
      case WALLET_TRANSACTION_STATUS.SUCCESSFUL:
        return t("successful");
      case WALLET_TRANSACTION_STATUS.FAILED:
        return t("failed");
      case WALLET_TRANSACTION_STATUS.PENDING:
        return t("pending");

      default:
        break;
    }
  };

  const getPaymentStatusColor = (status: string) => {
    switch (status.toUpperCase()) {
      case WALLET_TRANSACTION_STATUS.SUCCESSFUL:
        return "success";
      case WALLET_TRANSACTION_STATUS.FAILED:
        return "error";
      case WALLET_TRANSACTION_STATUS.PENDING:
        return "primary";

      default:
        break;
    }
  };

  return (
    <Modal
      title={t("wallet_record_details")}
      isOpen={props.isOpen}
      onClose={() => props.setIsOpen(false)}
    >
      {props.data ? (
        <>
          <div className="px-[6px]">
            <div className="w-full border-b border-b-grey-200 pb-[14px] mb-[14px] flex items-center justify-between">
              <h6 className="text-sm text-grey-700">{t("payment_number")}</h6>
              <span className="text-sm text-grey-800 font-semibold">
                {props.data.id}
              </span>
            </div>
            <div className="w-full border-b border-b-grey-200 pb-[14px] mb-[14px] flex items-center justify-between">
              <h6 className="text-sm text-grey-700">{t("date")}</h6>
              <span className="text-sm text-grey-800 font-semibold">
                {props.data.created_date}
              </span>
            </div>
            <div className="w-full border-b border-b-grey-200 pb-[14px] mb-[14px] flex items-center justify-between">
              <h6 className="text-sm text-grey-700">{t("total_price")}</h6>
              <span className="text-sm text-grey-800 font-semibold">
                {props.data.amount.toLocaleString()}
              </span>
            </div>
            <div className="w-full border-b border-b-grey-200 pb-[14px] mb-[14px] flex items-center justify-between">
              <h6 className="text-sm text-grey-700">{t("type")}</h6>
              <span className="text-sm text-grey-800 font-semibold">
                {t(props?.data?.type)}
              </span>
            </div>
            <div className="w-full border-b border-b-grey-200 pb-[14px] mb-[14px] flex items-center justify-between">
              <h6 className="text-sm text-grey-700">{t("currency")}</h6>
              <div className="flex items-center gap-2">
                <img
                  src={props.data.currency.icon}
                  className="w-6 h-6 rounded-full"
                />
                <span className="text-grey-700 text-md">
                  {t(props?.data?.currency?.name)}
                </span>
              </div>
            </div>
            <div className="w-full border-b border-b-grey-200 pb-[14px] mb-[14px] flex items-center justify-between">
              <h6 className="text-sm text-grey-700">{t("status")}</h6>
              <div
                className={`px-3 py-[5px] bg-${getPaymentStatusColor(
                  props.data.status
                )}-50 rounded-lg text-${getPaymentStatusColor(
                  props.data.status
                )}-500 text-xs text-center`}
              >
                {getPaymentStatusT(props.data.status)}
              </div>
            </div>
            <div className="w-full border-b border-b-grey-200 pb-[14px] mb-[14px] flex items-center justify-between">
              <h6 className="text-sm text-grey-700">{t("description")}</h6>
              <p className="text-sm text-grey-800 font-semibold gap-3 flex items-center">
                {props?.data?.description}
              </p>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default DetailModal;
