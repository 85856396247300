import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Trash } from "iconsax-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Edit } from "react-iconly";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { OrderApi } from "src/api";
import { ToastMsg } from "src/shared";
import { useCommonStore } from "src/store";
import { ColorPalette } from "src/utils";
import { ICartItemInterface } from "../../Desktop/types";

type Props = {
  item: ICartItemInterface;
  bordered: boolean;
  index: number;
};

const MobileOrderCard = ({ item, bordered, index }: Props) => {
  const { i18n, t } = useTranslation("cart/ts");
  const { t: validationTranslations } = useTranslation("validation/ts");

  const { setNewOrderModal } = useCommonStore();

  const queryClient = useQueryClient();

  const deleteMutuate = useMutation(OrderApi.deleteCartItem, {
    onSuccess: (res) => {
      toast(
        <ToastMsg
          type="success"
          message={validationTranslations("cart_item_del_success")}
        />
      );
      queryClient.invalidateQueries({
        queryKey: ["[List] Cart"],
      });
      queryClient.invalidateQueries({
        queryKey: ["[Info] Checkout"],
      });
    },
    onError: (error: AxiosError) => {
      if (error.response.status == 400) {
        toast(<ToastMsg type="error" message={t("data_store_error")} />);
      }
    },
  });

  return (
    <div
      className={`w-full pb-[14px] ${
        bordered ? "border-b" : ""
      } border-b-grey-200`}
    >
      <div className="flex justify-between items-center mb-5">
        <div className="flex justify-between items-center">
          <div
            className="rounded-lg flex justify-center items-center bg-grey-100 text-grey-700 text-xs"
            style={{
              width: 28,
              height: 28,
              margin: i18n.dir() == "rtl" ? "0 0 0 8px" : "0 8px 0 0",
            }}
          >
            {index + 1}
          </div>
          <div className="flex items-center">
            <img
              src={item.currency?.icon}
              className="w-5 h-5"
              style={{
                margin: i18n.dir() == "rtl" ? "0 0 0 8px" : "0 8px 0 0",
              }}
            />
            <span className="text-xs text-grey-700 font-semibold">
              {t(item.currency?.name)}
            </span>
          </div>
        </div>
        <div className="flex items-center gap-7">
          <div onClick={() => setNewOrderModal(true, item)}>
            <Edit size={16} primaryColor={ColorPalette.grey["800"]} />
          </div>
          {deleteMutuate.isLoading ? (
            <ClipLoader
              color={ColorPalette.error["400"]}
              loading={true}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <Trash
              size={16}
              color={ColorPalette.error["400"]}
              onClick={() => deleteMutuate.mutate(item.id)}
            />
          )}
        </div>
      </div>
      <div className="w-full flex justify-between items-center mb-5">
        <h6 className="text-sm text-grey-800 font-semibold">{t("amount")}</h6>
        <span className="text-sm text-grey-700 font-semibold">
          {String(item.amount.toLocaleString())}
        </span>
      </div>
      <div className="w-full flex justify-between items-center mb-5">
        <h6 className="text-sm text-grey-800 font-semibold">
          {t("account_number")}
        </h6>
        <span className="text-sm text-grey-700 font-semibold">
          {item.account ? item.account.number : ""}
        </span>
      </div>
      <div className="w-full flex justify-between items-center mb-5">
        <h6 className="text-sm text-grey-800 font-semibold">
          {t("account_type")}
        </h6>
        <span className="text-sm text-grey-700 font-semibold">
          {item.account_type}
        </span>
      </div>
      <div className="w-full flex justify-between items-center">
        <h6 className="text-sm text-grey-800 font-semibold">{t("price")}</h6>
        <span className="text-sm text-grey-700 font-semibold">
          {item.price.toLocaleString()}
          <span className="text-xs text-grey-600"></span>
        </span>
      </div>
    </div>
  );
};

export default MobileOrderCard;
