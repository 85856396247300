import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ArrowLeft,
  ArrowRight,
  ChevronLeft,
  ChevronRight,
  Edit,
  Home,
  Lock,
} from "react-iconly";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MoneySvg } from "src/assets";
import { Button, IconButton } from "src/components";
import { useAuthStore } from "src/store";
import { ColorPalette, Helpers } from "src/utils";
import { AccountDetailModal } from "../../../Root/partials";

type Props = {
  number: string;
};

const Header = (props: Props) => {
  const { i18n, t } = useTranslation("accounts/ts");
  const navigate = useNavigate();

  const { id } = useParams();

  const [detailModal, setDetailModal] = useState<Helpers.keyable>({
    show: false,
    data: undefined,
    stamp: new Date(),
  });

  return (
    <div className="w-full flex flex-wrap justify-between items-center mb-[30px] gap-5">
      <AccountDetailModal
        isOpen={detailModal.show}
        setIsOpen={(state) =>
          setDetailModal({ ...detailModal, show: state, stamp: new Date() })
        }
        data={detailModal.data}
      />
      <div className="rounded-lg border border-grey-200 p-3 flex items-center gap-2">
        <Link to="/payments" className="flex gap-2">
          <img src={MoneySvg} />
          <span className="text-sm text-grey-600">{t("accounts")}</span>
        </Link>
        {i18n.dir() == "rtl" ? (
          <ChevronLeft size={24} primaryColor={ColorPalette.grey["600"]} />
        ) : (
          <ChevronRight size={24} primaryColor={ColorPalette.grey["600"]} />
        )}
        <span className="text-sm text-grey-800 font-semibold">
          {props?.number}
        </span>
      </div>
      <div className="flex items-center gap-3">
        <IconButton
          bgColor="bg-primary-500"
          bgColorHovered="hover:bg-primary-700"
          ringColor="ring:bg-primary-200"
          icon={<Edit size={24} primaryColor={ColorPalette.grey["50"]} />}
          size={"Large"}
          onClick={() => {
            setDetailModal({
              show: true,
              stamp: new Date(),
              data: props,
            });
          }}
        />
      </div>
    </div>
  );
};

export default Header;
