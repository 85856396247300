import { AxiosCustomRequestConfig, http } from "../../services/Api";

interface keyable {
  [key: string]: any;
}

export const postAuthRefresh = async ({ body }: AxiosCustomRequestConfig) => {
  const response = http.post("/account/auth/refresh-token/", body);
  const data = (await response).data;
  return data;
};

export const loginPassword = async ({ body }: AxiosCustomRequestConfig) => {
  const response = http.post("/account/auth/login-password/", body);
  const data = (await response).data;
  return data;
};

export const loginOtp = async ({ body }: AxiosCustomRequestConfig) => {
  const response = http.post("/account/auth/login-otp", body);
  const data = (await response).data;
  return data;
};

export const checkOtp = async ({ body }: AxiosCustomRequestConfig) => {
  const response = http.post("/account/auth/check-otp", body);
  const data = (await response).data;
  return data;
};

export const register = async ({ body }: AxiosCustomRequestConfig) => {
  const response = http.post("/account/auth/registration/", body);
  const data = (await response).data;
  return data;
};

export const getMyPorfile = async () => {
  const response = http.get("/account/user/my_profile/");
  const data = (await response).data;
  return data;
};

export const changePass = async ({ body }: AxiosCustomRequestConfig) => {
  const response = http.put("/account/user/change_password/", body);
  const data = (await response).data;
  return data;
};

export const patchProfile = async ({
  body,
  params,
}: {
  body: FormData;
  params: keyable;
}) => {
  const response = http.patch(`/account/user/${params.id}/`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  const data = (await response).data;
  return data;
};

export const updatePhoneNumber = async ({ body }: AxiosCustomRequestConfig) => {
  const response = http.patch(`/account/user/change_phone_number/`, body);
  const data = (await response).data;
  return data;
};

export const postLogout = async ({body}: AxiosCustomRequestConfig) => {
  const response = http.post("/account/auth/logout/", body);
  const data = (await response).data;
  return data;
};

export const changeEmail = async ({body}: AxiosCustomRequestConfig) => {
  const response = http.post("/account/change-email/", body);
  const data = (await response).data;
  return data;
};

export const verifyEmail = async ({body}: AxiosCustomRequestConfig) => {
  const response = http.post("/account/verify-email/", body);
  const data = (await response).data;
  return data;
};

