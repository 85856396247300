import React from "react";
import { useTranslation } from "react-i18next";
import { Input } from "src/components";
import { useMediaQuery } from "src/hooks";
import { FormField } from "src/shared";

type Props = {
  formik: any;
};

const Step1 = ({ formik }: Props) => {
  const { t, i18n } = useTranslation("dashboard/ts");

  const isMobile = useMediaQuery("(max-width: 1023px)");

  return (
    <div className="w-full">
      <div className="w-full mb-[14px]">
        <FormField
          label={t("name")}
          field={
            <Input
              placeholder={t("enter_name")}
              sizes={isMobile ? "Small" : "Large"}
              onChange={(e) =>
                formik.setFieldValue("first_name", e.target.value)
              }
              onFocus={() => formik.setFieldTouched("first_name", true)}
              value={formik.values.first_name}
              autoComplete={"on"}
              onClear={() => formik.setFieldValue("first_name", "")}
            />
          }
          message={
            formik.errors.first_name &&
            formik.touched.first_name && {
              type: "ERROR",
              content: formik.errors.first_name,
            }
          }
        />
      </div>{" "}
      <div className="w-full mb-[14px]">
        <FormField
          label={t("last_name")}
          field={
            <Input
              placeholder={t("enter_last")}
              sizes={isMobile ? "Small" : "Large"}
              onChange={(e) =>
                formik.setFieldValue("last_name", e.target.value)
              }
              onFocus={() => formik.setFieldTouched("last_name", true)}
              value={formik.values.last_name}
              autoComplete={"on"}
              onClear={() => formik.setFieldValue("last_name", "")}
            />
          }
          message={
            formik.errors.last_name &&
            formik.touched.last_name && {
              type: "ERROR",
              content: formik.errors.last_name,
            }
          }
        />
      </div>{" "}
      <div className="w-full mb-[14px]">
        <FormField
          label={t("email_field")}
          field={
            <Input
              placeholder={t("enter_email")}
              sizes={isMobile ? "Small" : "Large"}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
              onFocus={() => formik.setFieldTouched("email", true)}
              value={formik.values.email}
              autoComplete={"on"}
              onClear={() => formik.setFieldValue("email", "")}
            />
          }
          message={
            formik.errors.email &&
            formik.touched.email && {
              type: "ERROR",
              content: formik.errors.email,
            }
          }
        />
      </div>
      <div className="w-full">
        <FormField
          label={t("national_field")}
          field={
            <Input
              placeholder={t("enter_national")}
              sizes={isMobile ? "Small" : "Large"}
              onChange={(e) =>
                formik.setFieldValue("national_code", e.target.value)
              }
              onFocus={() => formik.setFieldTouched("national_code", true)}
              value={formik.values.national_code}
              autoComplete={"on"}
              onClear={() => formik.setFieldValue("national_code", "")}
            />
          }
          message={
            formik.errors.national_code &&
            formik.touched.national_code && {
              type: "ERROR",
              content: formik.errors.national_code,
            }
          }
        />
      </div>
    </div>
  );
};

export default Step1;
