export default {
  primary: {
    "900": "#002966",
    "800": "#003D99",
    "700": "#0047B3",
    "600": "#0052CC",
    "500": "#0066FF",
    "400": "#3385FF",
    "300": "#66A3FF",
    "200": "#99C2FF",
    "100": "#CCE0FF",
    "50": "#E6F0FF",
  },
  secondary: {
    "900": "#662500",
    "800": "#993800",
    "700": "#B34100",
    "600": "#E65400",
    "500": "#FF5E00",
    "400": "#FF7E33",
    "300": "#FF9E66",
    "200": "#FFCFB3",
    "100": "#FFDFCC",
    "50": "#FFEFE6",
  },
  grey: {
    "900": "#171A1C",
    "800": "#2E3338",
    "700": "#505962",
    "600": "#73808C",
    "500": "#9DA6AF",
    "400": "#ABB3BA",
    "300": "#C7CCD1",
    "200": "#E3E6E8",
    "100": "#F1F2F4",
    "50": "#F8F9FA",
  },
  error: {
    "900": "#5D0909",
    "800": "#8B0E0E",
    "700": "#A21010",
    "600": "#BA1212",
    "500": "#E81717",
    "400": "#ED4545",
    "300": "#F17474",
    "200": "#F6A2A2",
    "100": "#FAD1D1",
    "50": "#FDE8E8",
  },
  warning: {
    "900": "#5E3C08",
    "800": "#8D590C",
    "700": "#A4680E",
    "600": "#D38612",
    "500": "#EDA02C",
    "400": "#EFAA43",
    "300": "#F3BF72",
    "200": "#F7D5A1",
    "100": "#FBEAD0",
    "50": "#FDF4E8",
  },
  info: {
    "900": "#044162",
    "800": "#066293",
    "700": "#0772AB",
    "600": "#0882C4",
    "500": "#0AA3F5",
    "400": "#3BB5F7",
    "300": "#6CC8F9",
    "200": "#9DDAFB",
    "100": "#CEEDFD",
    "50": "#E7F6FE",
  },
  success: {
    "900": "#1D4934",
    "800": "#2B6E4E",
    "700": "#399367",
    "600": "#40A574",
    "500": "#47B881",
    "400": "#6CC69A",
    "300": "#91D4B4",
    "200": "#B6E2CD",
    "100": "#DAF1E6",
    "50": "#EDF8F2",
  },
};
