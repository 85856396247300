import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDown, Search } from "react-iconly";
import { ColorPalette, Helpers } from "src/utils";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "src/hooks";
import IconButton from "../IconButton";
import RadioButton from "../RadioButton";

type Props = {
  options: option[];
  multiple?: boolean;
  placeholder: string;
  value?: option | option[];
  onChange?: (data: any) => void;
  disabled?: boolean;
  small?: boolean;
  onSearch: (val: string) => void;
};

type option = {
  label: string;
  value: any;
  image?: string;
};

const SearchDropDown = (props: Props) => {
  const { i18n, t } = useTranslation();

  const isMobile = useMediaQuery("(max-width: 1023px)");

  return (
    <div className="w-100">
      <Listbox
        value={props.value}
        onChange={props.onChange}
        multiple={props.multiple}
        disabled={props.disabled}
      >
        <div className="relative">
          <Listbox.Button
            className={`relative w-full cursor-default rounded-lg border border-grey-200 bg-white focus:outline-none ${
              isMobile ? "py-[10px]" : props.small ? "py-[10px]" : "py-[14px]"
            } px-[14px] flex items-center`}
          >
            <div
              className={`flex ${
                isMobile ? "leading-4 h-4 text-xs" : "leading-5 h-5 text-sm"
              } flex-grow items-center overflow-x-scroll ${
                i18n.dir() == "rtl" ? "pl-8" : "pr-8"
              }`}
            >
              {props.multiple ? (
                (props.value as option[]).length > 0 ? (
                  (props.value as option[]).map((option: Helpers.keyable) => (
                    <div className="pr-4 py-[6px] pl-3 bg-primary-500 text-grey-50 rounded-[10px] ml-2 whitespace-nowrap">
                      {option.label}
                    </div>
                  ))
                ) : (
                  <span className="text-sm text-grey-500">
                    {props.placeholder}
                  </span>
                )
              ) : props.value ? (
                (props.value as option).label
              ) : (
                <span className="text-sm text-grey-500">
                  {props.placeholder}
                </span>
              )}
            </div>
            <span className="">
              <ChevronDown size={20} primaryColor={ColorPalette.grey["700"]} />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className="border border-grey-200 absolute mt-[10px] max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              style={{ zIndex: 1000 }}
            >
              <div className="w-full px-[14px] py-[19px]">
                <div className="flex py-2 px-[14px] rounded-lg border border-grey-200 gap-3 items-center">
                  <input
                    placeholder={t("search")}
                    className="flex-1 outline-none"
                    onChange={(e) => props.onSearch(e.target.value)}
                  />
                  <IconButton
                    bgColor="bg-primary-500"
                    bgColorHovered="hover:bg-primary-700"
                    ringColor="focus:ring focus:ring-primary-200"
                    icon={
                      <Search
                        size={16}
                        primaryColor={ColorPalette.grey["50"]}
                      />
                    }
                    size="Small"
                  />
                </div>
              </div>
              {props.options.map((option, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `relative cursor-default select-none px-[14px] py-[10px] hover:bg-grey-100 hover:text-grey-700 flex items-center ${
                      active
                        ? "text-primary-500 bg-primary-50"
                        : "text-grey-700"
                    }`
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <div className="flex items-center gap-2">
                      {props.multiple ? (
                        <input
                          type="checkbox"
                          checked={selected}
                          className="p-1 w-5 h-5 ml-3 border rounded-md border-grey-200 appearance-none checked:bg-primary-500 checked:border-primary-500"
                        />
                      ) : (
                        <RadioButton checked={selected} label="" />
                      )}
                      <span
                        className={`text-grey-700 text-sm gap-2 flex items-center`}
                      >
                        {option.image && <img width={20} src={option.image} />}
                        {option.label}
                      </span>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default SearchDropDown;
