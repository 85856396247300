import React from "react";
import { useTranslation } from "react-i18next";
import { CheckBox } from "src/components";
import ProgressBar from "@ramonak/react-progress-bar";
import { ColorPalette } from "src/utils";
import { Star } from "react-iconly";
import { useMediaQuery } from "src/hooks";

type Props = {
  type: "Simple" | "Success";
};

const Classes = (list: any) => {
  return list.filter(Boolean).join(" ");
};

const LevelCard = (props: Props) => {
  const { t } = useTranslation("discount/ts");

  const isMobile = useMediaQuery("(max-width: 1023px)");

  const getBaseStyles = () => {
    return "p-5 pt-[25px] rounded-[20px] mb-5";
  };

  const getColoredStyles = () => {
    switch (props.type) {
      case "Simple":
        return "shadow-levelCard bg-white";

      case "Success":
        return "bg-success-50";

      default:
        return "shadow-levelCard bg-white";
    }
  };

  return (
    <div className={Classes([getBaseStyles(), getColoredStyles()])}>
      <div className="flex items-center justify-between mb-6">
        <CheckBox
          type={props.type == "Success" ? "SUCCESS" : "INFO"}
          label="level1"
          checked={true}
        />
        <div
          className={`${
            props.type == "Success" ? "bg-success-200" : "bg-secondary-50"
          } px-3 py-1 lg:px-4 lg:py-[6px] rounded-lg text-sm lg:text-base ${
            props.type == "Success" ? "text-success-700" : "text-secondary-400"
          }`}
        >
          +5%
        </div>
      </div>
      <p className="mb-5 text-xs lg:text-sm text-grey-700">
        {t("get_discount_text")}
      </p>
      <div className="flex justify-between items-center">
        <span className="text-sm text-grey-600">
          <strong className="text-base text-grey-800">3</strong>
          {` / 2 ${t("order")}`}
        </span>
        <Star
          primaryColor={
            props.type == "Success"
              ? ColorPalette.warning["400"]
              : ColorPalette.grey["700"]
          }
          filled={props.type == "Success"}
          size={isMobile ? 20 : 24}
        />
      </div>
      <ProgressBar
        completed={60}
        className="w-full mt-[10px]"
        height={"8px"}
        borderRadius={"100px"}
        bgColor={
          props.type == "Success"
            ? ColorPalette.success["400"]
            : ColorPalette.primary["500"]
        }
        isLabelVisible={false}
      />
    </div>
  );
};

export default LevelCard;
