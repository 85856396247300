import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import { Root } from "./screens";

const Wallet = () => {
  return (
    <Routes>
      <Route path="/" element={<Root />} />
    </Routes>
  );
};

export default Wallet;
