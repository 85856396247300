import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Danger, Document, Plus } from "react-iconly";
import { Button, IconButton, RadioButton, Switch } from "src/components";
import { ColorPalette, Helpers } from "src/utils";
import { IPaymentDetail } from "../../Desktop/types";
import PaymentModal from "../PaymentModal";
import Sheet, { SheetRef } from "react-modal-sheet";
import { PaymentApi } from "src/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import OfflinePayment from "../OfflinePayment";
import { SheetButton } from "src/assets";
import CryptoPayment from "../CryptoPayment";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { ToastMsg } from "src/shared";
import { useNavigate } from "react-router-dom";

type Props = {
  checkoutInfo: Helpers.keyable;
};

const PaymentDetailFloat = ({ checkoutInfo }: Props) => {
  const { i18n, t } = useTranslation("cart/ts");
  const [snapped, setSnapped] = useState(1);

  const ref = useRef<SheetRef>();
  const snapTo = (i: number) => ref.current?.snapTo(i);

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const payMutate = useMutation(PaymentApi.payWithWallet, {
    onSuccess: (res) => {
      toast(<ToastMsg type="success" message={t("paid_success")} />);
      navigate("/orders");
    },
    onError: (error: AxiosError) => {
      toast(<ToastMsg type="error" message={t("paid_error")} />);
    },
  });

  return (
    <div className="bg-white shadow-md w-full left-0 fixed flex items-center bottom-0 px-4 py-[14px]">
      <Sheet
        isOpen={isOpen}
        snapPoints={[650, 70]}
        initialSnap={1}
        ref={ref}
        onClose={() => {
          snapTo(1);
        }}
        onSnap={(index) => setSnapped(index)}
      >
        <Sheet.Container
          style={{ background: ColorPalette.grey["50"] }}
          className="!rounded-[20px] !shadow-none"
        >
          {/* <Sheet.Header style={{ height: 20 }} /> */}
          <Sheet.Content>
            <div
              className="flex justify-center
            absolute left-0 right-0 mx-auto w-16 h-7 bg-white rounded-br-[40px] rounded-bl-[40px]"
              onClick={() => (snapped == 1 ? snapTo(0) : snapTo(1))}
            >
              <img src={SheetButton} width={40} />
            </div>
            {snapped == 1 ? (
              <div className="px-4 py-2 grid grid-cols-12 justify-between items-center">
                <div className="col-span-5">
                  <div className="flex flex-col">
                    {/* <h6 className="text-sm text-grey-800 mb-2">{t("total")}</h6>
                    <span className="text-xs text-grey-600">
                      <strong className="text-base font-bold text-secondary-500">
                        {props.detail.payable_price?.toLocaleString()}
                      </strong>{" "}
                      {t("toman")}
                    </span> */}
                  </div>
                </div>
                <div className="col-span-3"></div>
                <div className="col-span-4">
                  <Button addonClasses="w-full" onClick={() => snapTo(0)}>
                    Pay
                  </Button>
                </div>
              </div>
            ) : (
              <div className="p-4">
                <div className="w-full rounded-[20px] bg-grey-50 p-5">
                  <h4 className="text-base text-grey-800 mb-5">
                    {t("payment_method")}:
                  </h4>
                  <div className="flex items-center justify-between mb-[18px]">
                    <h6 className="text-md text-grey-600">{t("wallet")}</h6>
                    <Switch checked={true} onChange={() => {}} />
                  </div>
                  <div className="bg-white rounded-xl px-4 py-5 mb-[14px]">
                    <h4 className="mb-[21px] text-base text-grey-800">{t('my_wallet_credit')}</h4>
                    {checkoutInfo.wallet.map((item: any, index: Number) => (
                      <div
                        className={`flex items-center justify-between ${
                          checkoutInfo.wallet.length - 1 != index
                            ? "mb-[22px]"
                            : ""
                        }`}
                      >
                        <span className="text-md text-grey-700">
                          {item.currency.name}
                        </span>
                        <span
                          className={`text-sm flex items-center gap-2 ${
                            item.balance == 0
                              ? "text-error-300"
                              : "text-grey-600"
                          }`}
                        >
                          <strong
                            className={`text-base ${
                              item.balance == 0
                                ? "text-error-400"
                                : "text-grey-800"
                            }`}
                          >
                            {item.balance.toLocaleString()}
                          </strong>
                          {item.currency.name}
                        </span>
                      </div>
                    ))}
                  </div>
                  {checkoutInfo && checkoutInfo.lack_of_credit.length > 0 && (
                    <div className="mb-[30px] py-[26px] px-4 rounded-xl bg-error-50">
                      <div className="flex items-center justify-between mb-[14px]">
                        <div className="gap-[10px] flex">
                          <Danger
                            size={20}
                            primaryColor={ColorPalette.error["400"]}
                          />
                          <h4 className="text-md font-semibold text-error-400">
                            {t("insufficient_credit")}
                          </h4>
                        </div>
                        <IconButton
                          bgColor="bg-success-400"
                          bgColorHovered="hover:bg-success-700"
                          ringColor="focus:ring focus:ring-success-200"
                          icon={<Plus size={20} />}
                          size="Medium"
                          onClick={() => {
                            navigate('/wallet?increase=true')
                          }}
                        />
                      </div>
                      <p className="mb-5 text-grey-700 text-sm">
                        {t("if_you_want_pay")}
                      </p>
                      {checkoutInfo.lack_of_credit.map(
                        (item: any, index: number) => (
                          <div
                            className={`flex items-center justify-between ${
                              checkoutInfo.lack_of_credit.length - 1 != index
                                ? "mb-[22px]"
                                : ""
                            }`}
                          >
                            <span className="text-md text-grey-700">
                              {item.currency}
                            </span>
                            <span
                              className={`text-sm flex items-center gap-2 text-grey-600`}
                            >
                              <strong className={`text-base text-grey-800`}>
                                {item.amount.toLocaleString()}
                              </strong>
                              {item.currency}
                            </span>
                          </div>
                        )
                      )}
                    </div>
                  )}
                  <Button
                    addonClasses="w-full mt-[30px]"
                    disabled={!checkoutInfo.can_pay}
                    loading={payMutate.isLoading}
                    onClick={() => payMutate.mutate()}
                  >
                    {t("pay")}
                  </Button>
                </div>
              </div>
            )}
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </div>
  );
};

export default PaymentDetailFloat;
