import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IranFlag, UsaFlag } from "src/assets";
import { Select } from "src/components";
import { FormField } from "src/shared";
import { Helpers } from "src/utils";

type Props = {};

const Settings = (props: Props) => {
  const { t } = useTranslation("setting/ts");

  const languages = [
    { value: "en", image: UsaFlag },
    { value: "fa", image: IranFlag },
  ];

  const [lang, setLang] = useState<any>();

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")) {
      const found = languages.find(
        (item) => item.value == localStorage.getItem("i18nextLng")
      );
      setLang({ ...found, label: t(`${found.value}_lng_select`) });
    } else {
      setLang({ label: t("en_lng_select"), value: "en" });
    }
  }, [props, t]);

  return (
    <div className="w-full">
      <h4 className="text-base font-semibold tex-grey-800 mb-[14px]">
        {t("lang_setting")}
      </h4>
      <p className="text-md text-grey-700 mb-5">{t("lang_setting_desc")}</p>
      <FormField
        label={t("language")}
        field={
          <Select
            placeholder={t("choose_your_lang")}
            value={lang}
            onChange={({ value }) => {
              Helpers.ChangeLanguage(value);
            }}
            options={languages.map((item) => ({
              ...item,
              label: t(`${item.value}_lng_select`),
            }))}
          />
        }
      />
    </div>
  );
};

export default Settings;
