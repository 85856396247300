import React, { useState } from "react";
import { ToastMsg } from "src/shared";
import { ColorPalette } from "src/utils";
import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";
import { ChevronDown, ChevronUp } from "react-iconly";
import { IconButton } from "src/components";
import { CopySvgWhite } from "src/assets";
import { toast } from "react-toastify";

type Props = {
  index: number;
  item: any;
};

const PaymentIdCard = ({ index, item }: Props) => {
  const { t } = useTranslation("paymentids/ts");

  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <div className="py-5 px-4 mb-4 rounded-[20px] border border-grey-200">
      <div className="flex items-center justify-between mb-5">
        <div className="flex items-center gap-[10px]">
          <div className="w-7 h-7 rounded-lg bg-grey-200 text-xs text-grey-700 flex justify-center items-center">
            {index + 1}
          </div>
          <h4 className="text-sm text-grey-700">{t("payment_id")}</h4>
        </div>
        <div className="flex items-center gap-3">
          <span className="text-sm text-grey-800">{item.payment_id}</span>
          <IconButton
            bgColor="bg-primary-500"
            bgColorHovered="hover:bg-primary-700"
            ringColor="focus:ring focus:ring-primary-200"
            icon={<img src={CopySvgWhite} />}
            size="Medium"
            onClick={() => {
              navigator.clipboard.writeText(item.payment_id);
              toast(<ToastMsg type="success" message={t("copied")} />);
            }}
          />
        </div>
      </div>
      <div className="px-4 py-5 rounded-[20px] bg-grey-50">
        <div
          className="flex justify-between items-center"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <h4 className="text-base font-semibold text-grey-800">
            {t("payment_detail")}:
          </h4>
          {isOpen ? (
            <ChevronUp size={20} primaryColor={ColorPalette.grey["700"]} />
          ) : (
            <ChevronDown size={20} primaryColor={ColorPalette.grey["700"]} />
          )}
        </div>
        <Collapse isOpened={isOpen}>
          <div className="mt-5 flex flex-col gap-[9px]">
            <div className="flex justify-between items-center pb-[14px] mb-[14px] border-b border-b-grey-200">
              <span className="text-md text-grey-700">{t("sheba_number")}</span>
              <div className="flex items-center gap-3 text-md text-grey-800 font-semibold">
                {item.destination_iban}
              </div>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-md text-grey-700">
                {t("account_owner")}
              </span>
              <span className="flex items-center text-sm- text-grey-600">
                {item.destination_owner_name}
              </span>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default PaymentIdCard;
