import * as React from "react";
import { SVGProps } from "react";
const CreditBgSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={344}
    height={178}
    fill="none"
    {...props}
  >
    <mask
      id="a"
      width={344}
      height={178}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <rect width={344} height={178} fill="#fff" rx={20} />
    </mask>
    <g mask="url(#a)">
      <g filter="url(#b)" opacity={0.1}>
        <ellipse cx={34} cy={167} fill={props.color} rx={140} ry={65} />
      </g>
      <g filter="url(#c)" opacity={0.1}>
        <ellipse cx={320} cy={5} fill={props.color} rx={140} ry={65} />
      </g>
    </g>
    <defs>
      <filter
        id="b"
        width={440}
        height={290}
        x={-186}
        y={22}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          result="effect1_foregroundBlur_432_13779"
          stdDeviation={40}
        />
      </filter>
      <filter
        id="c"
        width={440}
        height={290}
        x={100}
        y={-140}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          result="effect1_foregroundBlur_432_13779"
          stdDeviation={40}
        />
      </filter>
    </defs>
  </svg>
);
export default CreditBgSvg;
